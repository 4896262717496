<template lang="pug">
div#app-wrapper
  SecondaryNavbar(:wide="true")
    Button.navbar-section(
      type="dropdown"
      label="New Folder"
      :disabled="not_found || folder_depth >= 5"
      :tooltip="folder_depth >= 5 ? { value: 'You can only create up to 5 levels of folders', position: 'bottom' } : null"
      @click="createFolder"
    )
      template(v-slot:prefix)
        IconFolder
    div.navbar-section(v-if="selection.is_some_selected")
      div.bulk-action-icons
        i.nexd-icon-32-delete(
          :class="{'disabled': delete_bulk_disabled}"
          v-tooltip="delete_bulk_tooltip"
          aria-hidden="true"
          @click="deleteSelected"
        )
        i.nexd-icon-32-remove-from-folder(
          v-if="$route?.params?.folder_id != null"
          aria-hidden="true"
          v-tooltip="{value: 'Remove selected items from folder'}"
          @click="removeFromFolder"
        )
    div.flex-grow
    Button(type="primary" label="Choose Files" :disabled="not_found || processing_assets" :animate="true" @click="chooseFiles")
      template(v-slot:prefix)
        IconAdd

  div.relative(v-content :key="items?.length")
    CampaignCreativeNotFound(v-if="not_found" :type="'folder'")
    Loading(v-else-if="items == null")
    template(v-else)

      StickyHeader(:wide="true")
        Breadcrumbs(:items="breadcrumbs")

        template(v-if="!empty_library" v-slot:table_header)
          ListHeader.mt-16(:service="GroupAssetsLibraryService" :selection="selection" :style="`--columns: ${columns}`")

      span
        div.asset-library-container(v-sticky-content)
          div.asset-library
            Dropzone(
              ref="dropzone"
              file="assets"
              :mime="['image/*', 'video/*', 'text/csv', 'application/zip']"
              :asset_library="true"
              :loading="false"
              :active="false"
              @change="handleFileChange"
            )
            div.dropbox(v-if="show_dropbox")
              Loading(v-if="library?.loading_percentage != null" :fill="true" :text="library?.loading_label" :percentage="library?.loading_percentage")

      template(v-if="empty_library")
        NoListItem(v-if="$route.params?.folder_id != null" type="assets")
        EmptyGroupAssetsLibrary(v-else :disabled="processing_assets" @choose="chooseFiles")

      div.flex.flex-direction-column.row-gap-4(v-else)
        GroupAsset(
          v-for="item of items"
          :key="item.id"
          :item="item"
          :selection="selection"
          :dragging="dragging"
          :dropbox="!dragging && show_dropbox"
          :style="`--columns: ${columns}`"
          @setDragging="setDragging"
          @addAssets="onDrop"
        )

      Loading.mt-32(v-if="loading_more_items && can_load_more")
      UpsellingFooter.text-center(v-if="!empty_library" type="group_assets" v-observe-visibility="loadMoreItems")

  EditorSidebar
</template>

<script>
import { FIXED_ELEMENT, FOLDER, LISTITEM } from '@master/constants';

import FolderService from '@master/Services/FolderService';
import GroupAssetsLibraryService from '@master/Services/GroupAssetsLibraryService';

import { getFoldersDepth } from '@helpers/Global';

import Selection from '@libs/Selection';
import Library from '@libs/AssetLibrary';

import Breadcrumbs from '@master/UI/Breadcrumbs/Breadcrumbs.vue';
import Dropzone from '@cm/Views/Creatives/Cards/Components/Dropzone.vue';
import EditorSidebar from '@root/src/global/sidebar/edit/EditorSidebar.vue';
import EmptyGroupAssetsLibrary from '@cm/Views/GroupAssets/Components/EmptyGroupAssetsLibrary.vue';
import GroupAsset from '@cm/Views/GroupAssets/Components/GroupAsset.vue';
import ListHeader from '@cm/UI/Global/ListHeader.vue';
import NoListItem from '@cm/UI/Global/NoListItem.vue';

import Button from '@master/UI/Buttons/Button.vue';
import CampaignCreativeNotFound from '@master/UI/CampaignCreativeNotFound.vue';
import IconAdd from '@master/UI/Buttons/Icons/IconAdd.vue';
import IconFolder from '@master/UI/Buttons/Icons/IconFolder.vue';
import Loading from '@master/UI/Loading.vue';
import SecondaryNavbar from '@master/UI/SecondaryNavbar/SecondaryNavbar.vue';
import StickyHeader from '@master/UI/StickyHeader/StickyHeader.vue';
import UpsellingFooter from '@master/UI/UpsellingFooter.vue';

export default {
  name: 'GroupAssetsLibrary',

  components: {
    Breadcrumbs,
    Button,
    CampaignCreativeNotFound,
    Dropzone,
    EditorSidebar,
    EmptyGroupAssetsLibrary,
    GroupAsset,
    IconAdd,
    IconFolder,
    ListHeader,
    Loading,
    NoListItem,
    SecondaryNavbar,
    StickyHeader,
    UpsellingFooter,
  },

  computed: {
    empty_library() {
      return !this.items?.length;
    },

    folders() {
      if (!this.items) {
        return this.items;
      }

      return this.items.filter(item => item.item_type === LISTITEM.FOLDER);
    },

    can_delete_folders() {
      if (this.folders == null || !this.selection.has_folders_selection) {
        return true;
      }

      return this.folders.filter(folder => this.selection?.selected_folders?.includes(folder.folder_id)).every(folder => folder?.meta?.total === 0);
    },

    delete_bulk_disabled() {
      return !this.can_delete_folders;
    },

    delete_bulk_tooltip() {
      if (!this.selection.is_some_selected) return { value: null };

      let warning_tooltip = null;

      if (!this.can_delete_folders) {
        warning_tooltip = `
          Uncheck selected folders with content to delete; only empty folders can be removed.
        `;
      }

      if (warning_tooltip != null) {
        return { value: warning_tooltip, class: 'advanced warning', position: 'bottom' };
      }

      let message = 'Delete';

      if (this.selection.has_items_selection) {
        message += ` selected asset${this.selection?.selected_items?.length > 1 ? 's' : ''}`;
      }

      if (this.selection.has_items_selection && this.selection.has_folders_selection) {
        message += ' and';
      }

      if (this.selection.has_folders_selection) {
        message += ` selected folder${this.selection?.selected_folders?.length > 1 ? 's' : ''}`;
      }

      return { value: message, position: 'bottom' };
    },

    folder_depth() {
      return getFoldersDepth(this.breadcrumbs, FOLDER.ASSET_ORIGINAL);
    },

    processing_assets() {
      return this.library?.loading_percentage != null;
    },
  },

  data() {
    return {
      FIXED_ELEMENT,
      GroupAssetsLibraryService,
      columns: '2rem 2rem 3fr repeat(3, 1fr) 3rem',

      selection: new Selection(),
      library: new Library(),

      files: null,
      dragging: false,
      show_dropbox: false,

      breadcrumbs: undefined,
      items: null,
      can_load_more: true,
      loading_more_items: false,
      not_found: false,
    };
  },

  created() {
    GroupAssetsLibraryService.load();
    GroupAssetsLibraryService.subscribe(state => {
      this.breadcrumbs = state?.breadcrumbs;
      this.items = state?.items;
      this.can_load_more = state?.can_load_more;
      this.not_found = state?.not_found === true;
      this.loading_more_items = false;

      this.selection.data = this.items;

      this.library.assets = this.items;
      this.library.service = GroupAssetsLibraryService;
      this.library.folder_id = this.$route.params.folder_id;

      if (state != null) {
        if (!FolderService.current.is(state.folder)) {
          FolderService.current.set(state.folder);
        }

        this.resetSidebar();
      }
    }, this);
  },

  mounted() {
    document.addEventListener('dragover', e => {
      e.preventDefault();

      if (!this.dragging) {
        this.showDropbox();
      }
    });

    document.addEventListener('dragleave', e => {
      e.preventDefault();

      if (!e.currentTarget.contains(e.relatedTarget)) {
        this.hideDropbox();
      }
    });

    document.addEventListener('drop', e => {
      e.preventDefault();
      this.onDrop(e);
    });

    window.onbeforeunload = () => {
      if (this.processing_assets) {
        return 'You have assets that are still uploading. Are you sure you want to leave this page?';
      }
    };
  },

  methods: {
    loadMoreItems(is_visible) {
      if (!is_visible || this.loading_more_items || !this.can_load_more) {
        return;
      }

      this.loading_more_items = true;
      GroupAssetsLibraryService.loadMore();
    },

    resetSidebar() {
      if (!this.$sidebar) {
        return;
      }

      this.$sidebar.reset();

      if (this.breadcrumbs?.length > 0) {
        const index = this.breadcrumbs.length > 1 ? this.breadcrumbs.length - 2 : this.breadcrumbs.length - 1;
        const breadcrumb = this.breadcrumbs[index];
        this.$sidebar.setBack(breadcrumb.label, breadcrumb.url);
      }

      const folder = FolderService.current.get();

      if (this.$route?.params?.folder_id != null && folder?.actions?.can_update) {
        this.$sidebar.setEdit('Folder Settings', () => {
          FolderService.active.set(folder);
        });
      }
    },

    showDropbox() {
      this.show_dropbox = true;
    },

    hideDropbox() {
      this.show_dropbox = false;
    },

    async onDrop(e) {
      this.hideDropbox();

      if (this.$refs?.dropzone != null) {
        this.library.fileChangeHandler(await this.$refs.dropzone.dropHandler(e));
      }
    },

    createFolder() {
      const folder_id = `new-${Date.now()}`;
      const user = this.$user.get();

      const obj = {
        folder_id,
        name: 'New Folder',
        parent_id: this.$route?.params?.folder_id ?? this.$route?.params?.group_id,
        status: 0,
        type: FOLDER.ASSET_ORIGINAL,
        owner: {
          user_id: user.user_id,
          name: user.name,
        },
      };

      GroupAssetsLibraryService.addItem(obj);
      FolderService.active.set(obj);
    },

    chooseFiles() {
      if (this.$refs.dropzone?.$refs?.input) {
        this.$refs.dropzone.$refs.input.click();
      }
    },

    handleFileChange(event) {
      this.hideDropbox();
      this.library.fileChangeHandler(event);
    },

    setDragging(value) {
      this.dragging = value ?? false;
    },

    async deleteSelected() {
      // folders can be deleted only if they are empty
      if (this.delete_bulk_disabled) {
        return;
      }

      if (await this.$confirm('Are you sure you want to remove selected items(s) from Team Library?')) {
        GroupAssetsLibraryService.deleteSelected(this.selection.selected_items, this.selection.selected_folders);
      }
    },

    async removeFromFolder() {
      if (await this.$confirm('Are you sure you want to remove selected items(s) from folder.')) {
        GroupAssetsLibraryService.removeFromFolder(this.selection.selected_items, this.selection.selected_folders);
      }
    },
  },
};
</script>
