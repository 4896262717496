<template lang="pug">
Modal(size="modal-sm" :title="title")
  template(v-slot:body)
    div.label-group
      h5 {{item}} name:
    TextareaInput(
      ref="title"
      :placeholder="item + ' name'"
      v-model="name"
    )

  template(v-slot:footer)
    Buttons
      Button(type="link-primary" label="Cancel" @click="$emit('close')")
      Button(type="primary" label="Save" :loading="saving" :submit="true" @click="$emit('save', name)")
</template>

<script>
import Modal from '@master/Modals/Modal.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput.vue';

export default {
  name: 'CreateNewModal',

  components: {
    Button,
    Buttons,
    Modal,
    TextareaInput,
  },

  props: {
    title: String,

    saving: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: this.title.includes('campaign') ? 'Campaign' : 'Folder',
      name: `New ${this.title.includes('campaign') ? 'Campaign' : 'Folder'}`,
    };
  },

  mounted() {
    if (this.$refs.title) {
      this.$refs.title.focus();
    }
  },
};
</script>
