<template lang="pug">
TestLayout(title="Breadcrumb")

  TestSection(subtitle="Default")
    Breadcrumb(label="My campaigns" :type="0")

  TestSection(subtitle="Router Link")
    Breadcrumb(label="Open Alerts" :type="0" to="/components/alert")

  TestSection(subtitle="Router Link Disabled")
    Breadcrumb(label="Open Alerts" :type="0" to="/components/alert" disabled)

  TestSection(subtitle="Active")
    Breadcrumb(label="My campaigns" :type="0" active)

  TestSection(subtitle="Disabled")
    Breadcrumb(label="My campaigns" :type="0" disabled)

  TestSection(subtitle="Type: Campaign")
    Breadcrumb(label="New Campaign" :type="1")

  TestSection(subtitle="Type: Creative")
    Breadcrumb(label="New Creative")

  TestSection(subtitle="Type: Folder")
    Breadcrumb(label="New Folder" :type="2")

  TestSection(subtitle="Type: Flight")
    Breadcrumb(label="New Flight" :type="4")

  TestSection(subtitle="Type: DCO")
    Breadcrumb(label="DCO" :type="6")

  TestSection(subtitle="Type: DCO Tools")
    Breadcrumb(label="Open DCO Tools" :type="7")

  TestSection(subtitle="Type: Team Library")
    Breadcrumb(label="Team Library" :type="5")

  TestSection(subtitle="Group")
    BreadcrumbGroup(label="Team Library" :type="5")

  TestLayout(title="Breadcrumbs" style="grid-column: 1 / -1;")
    Breadcrumbs(:items="breadcrumbs")
</template>

<script>
import { BREADCRUMB } from '@master/constants';

import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Breadcrumb from '@master/UI/Breadcrumbs/Breadcrumb.vue';
import BreadcrumbGroup from '@master/UI/Breadcrumbs/BreadcrumbGroup.vue';
import Breadcrumbs from '@master/UI/Breadcrumbs/Breadcrumbs.vue';

export default {
  name: 'BreadcrumbTest',

  components: {
    TestLayout,
    TestSection,

    Breadcrumb,
    BreadcrumbGroup,
    Breadcrumbs,
  },

  data() {
    return {
      BREADCRUMB,

      breadcrumbs: [
        {
          url: '/',
          label: 'My Campaigns',
          type: 0,
          active: false,
          meta: {},
        },
        {
          type: 3,
          label: '...',
          breadcrumbs: [
            {
              url: '/f/:folder_id',
              label: '1',
              type: 2,
              active: false,
              meta: {
                folder_type: 'campaign',
              },
              item_id: 'foo',
            },
            {
              url: '/f/:folder_id',
              label: '2',
              type: 2,
              active: false,
              meta: {
                folder_type: 'campaign',
              },
              item_id: 'baz',
            },
            {
              url: '/f/:folder_id',
              label: '3',
              type: 2,
              active: false,
              meta: {
                folder_type: 'campaign',
              },
              item_id: 'bar',
            },
            {
              url: '/f/:folder_id',
              label: '4',
              type: 2,
              active: false,
              meta: {
                folder_type: 'campaign',
              },
              item_id: 'qux',
            },
          ],
        },
        {
          url: '/f/:folder_id',
          label: '5',
          type: 2,
          active: false,
          meta: {
            folder_type: 'campaign',
          },
          item_id: 'quux',
          disabled: true,
        },
        {
          label: 'New Campaign',
          type: 1,
          active: false,
          meta: {},
          item_id: 'quz',
          onClick: () => {
            // eslint-disable-next-line
            console.log('New Campaign');
          },
        },
        {
          url: '/c/:campaign_id/:creative_id',
          label: 'New Creative',
          active: true,
          meta: {},
          item_id: 'corge',
        },
      ],
    };
  },
};
</script>
