<template lang="pug">
div.flex.pt-32.pb-32.pl-24.pr-24(
  :class="active_tab !== 'tracking' && active_tab !== 'cta' ? 'flex-justify-between' : 'flex-justify-end'"
)
  CreativeRow(v-if="active_tab !== 'tracking' && active_tab !== 'cta'" label="Creative preview:")
  div(data-intercom-target="creative-settings-navigation-tab")
    Tabs.px-16(:value="active_tab" :tabs="enabled_tabs" :disabled="!can_change_tab" @input="changeTab")
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

import CreativeRow from '@cm/Views/Creatives/Creative/CreativeRow.vue';
import Tabs from '@cm/UI/Global/Tabs/Tabs.vue';

export default {
  name: 'SettingsTab',

  mixins: [CreativeTraits, SDKMixin],

  components: {
    CreativeRow,
    Tabs,
  },

  props: {
    creative: Object,

    // used when isExpanded is true
    parent: Object,

    isExpanded: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    responsive_settings_closed: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    active_tab() {
      return this.$store.get('active_tab');
    },

    can_change_tab() {
      return !this.isFeed() && !this.loading;
    },

    enabled_tabs() {
      let tabs = {
        assets: 'Assets',
        extras: 'Extras',
        options: 'Options',
        cta: 'Click Actions',
        tracking: 'Tracking',
      };

      if (this.is_sdk_user) {
        delete tabs.cta;
        delete tabs.tracking;
      }

      // responsive has no extras assets tab
      if (this.isQuantum()) {
        delete tabs.extras;
        return tabs;
      }

      // HTML5 has preview, click actions and tracking tab
      if (this.isHTML5()) {
        // show asset tabs as preview tab
        tabs.assets = 'Preview';
        delete tabs.extras;
        delete tabs.options;

        // lightbox second state should not have cta
        if (this.isExpanded) {
          delete tabs.cta;
        }

        return tabs;
      }

      // expandable and dynamic endcard 1st has fixed CTA from the backend
      if (this.isBinded() && !this.isExpanded) {
        delete tabs.cta;
        return tabs;
      }

      if (this.isDevtoolCreative()) {
        delete tabs.extras;
        delete tabs.options;
        delete tabs.tracking;
        return tabs;
      }

      // any other case, all possible tabs
      return tabs;
    },
  },

  methods: {
    changeTab(tab) {
      const current_tab = this.active_tab;
      this.$store.set('active_tab', tab);

      if (this.isQuantum() && this.responsive_settings_closed) {
        this.$emit('openResponsiveSettings');
      } else if (this.isQuantum() && !this.responsive_settings_closed && tab === current_tab) {
        this.$emit('closeResponsiveSettings');
      }
    },
  },
};
</script>
