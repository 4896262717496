<script>
import { BREADCRUMB } from '@master/constants';

export default {
  computed: {
    icon() {
      return this.getIcon();
    },
  },

  methods: {
    getIcon(type = this.type) {
      switch (type) {
        case BREADCRUMB.ROOT:
        case null:
          return null;
        case BREADCRUMB.CAMPAIGN:
          return 'nexd-icon-32-campaign';
        case BREADCRUMB.FOLDER:
          return 'nexd-icon-32-folder';
        case BREADCRUMB.FLIGHT:
          return 'nexd-icon-32-flight-timeline fs-24 mx-4';
        case BREADCRUMB.DCO:
          return 'nexd-icon-32-dco fs-24 mx-4';
        case BREADCRUMB.DCO_TOOLS:
          return 'nexd-icon-32-flights';
        case BREADCRUMB.TEAM_LIBRARY:
          return 'nexd-icon-32-library';
        default:
          return 'nexd-icon-32-creative';
      }
    },
  },
};
</script>
