<template lang="pug">
div.relative
  div.asset-upload-wrapper.custom-upload-wrapper(ref="gesture")
    Thumbnail(:asset="{image_uri: thumbnail}" :variant="thumbnail != null ? 'nobgimage' : null" :small="true" bg="light")
      template(v-if="active_original_file && original_files.length > 1")
        div.dropdown-indicator(@click="openFileList")
          i.nexd-icon-16-arrow-down
        div.dropdown(v-if="file_list_opened" v-on-clickaway="closeFileList")
          Thumbnail(
            v-for="(file, index) of original_files"
            :key="index"
            :asset="{image_uri: file.blob}"
            :variant="file.blob != null ? 'nobgimage' : null"
            :small="true"
            bg="light"
            :dropdown="true"
            @click="selectFileFromList(file)"
          )
    div.asset-controls.flex-grow.text-right.ml-2rem
      i.nexd-icon-16-upload( @click="selectFileHandler" v-tooltip="{value: 'Upload new'}")
      i.nexd-icon-16-edit(v-if="show_edit" @click="editCustomAsset" v-tooltip="{value: 'Edit'}")
      i.nexd-icon-16-counter-clockwise(v-if="can_reset" @click="resetCustomAsset")
    input(ref="custom-file-input" type="file" :accept="accepted_input_extensions" @change="inputFileChangeHandler")
  div.dropbox(v-if="dropbox_visible" ref="dropbox")
    div.dropbox-content
      div Drop your file here
  Loading.small(v-if="loading != null" :fill="true" :percentage="loading_percentage")
</template>

<script>
import Loading from '@master/UI/Loading.vue';
import AssetHelper from '@helpers/Asset';
import { b64toBlob } from '@helpers/Global';
import Upload from '@helpers/Upload';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import { mixin as clickaway } from 'vue-clickaway';
import { SIZE, CUSTOM_ASSET } from '@master/constants';
import Thumbnail from '@master/UI/Thumbnail/Thumbnail.vue';

export default {
  name: 'EffectAssetSlot',
  mixins: [CreativeTraits, clickaway],
  components: {
    Loading,
    Thumbnail,
  },
  props: {
    creative: Object,
    item: Object,
  },
  computed: {
    // custom asset exist if its uploaded
    custom_asset() {
      if (this.creative?.assets?.[this.asset_id] != null) {
        return this.creative.assets[this.asset_id];
      }
      return null;
    },

    // can reset only if theres any change
    can_reset() {
      return this.custom_asset != null;
    },

    // thumbnail can be the original custom file or uploaded file
    thumbnail() {
      const uploaded_asset = this.custom_asset;
      if (uploaded_asset != null) {
        return uploaded_asset.image_uri;
      } else if (this.active_original_file != null) {
        return this.active_original_file.blob;
      }
      return null;
    },

    effects_block() {
      return AssetHelper.isCustomEffect(this.asset_id);
    },

    show_edit() {
      return false; // Dno why there's actually edit button, since in PRD user is not able to edit effect asset
    },
  },

  data() {
    const accepted_mimes = AssetHelper.getImageMimes().join(', ');
    const accepted_input_extensions = AssetHelper.mimeToExtension(accepted_mimes);
    return {
      Cropper: this.$cropper(),

      accepted_mimes,
      accepted_input_extensions,
      original_files: null,
      active_original_file: null,
      file_list_opened: false,

      // on custom file select, cropper UI will be skipped once
      skip_cropping: false,

      dropbox_visible: false,
      library_upload_data: null,

      loading: null,
      loading_percentage: null,

      initial_edit_on_default: false,
      asset_id: this.$vnode.key,
    };
  },

  created() {
    // @debug: This tiny homo is reloading itself whenever effect is enabled/disabled - dno why
    const path = `v2/layouts/${this.creative.layout_id}/files/${this.item.module_id}`;
    this.$http.get(path).then(response => {
      let files = [];
      for (const item of response) {
        let mime = item.type;
        if (mime === 'image/svg') {
          mime += '+xml';
        }

        const blob = b64toBlob(item.b64, mime);
        const file = new File([blob], item.filename, { type: mime });

        files.push({
          file,
          blob: window.URL.createObjectURL(blob),
        });
      }

      this.original_files = files;

      // initially active
      this.active_original_file = this.original_files[0];
    });
  },

  mounted() {
    this.$el.addEventListener('dragover', e => {
      e.preventDefault();
      e.stopPropagation();
      this.dropbox_visible = true;
    });

    this.$el.addEventListener('drop', async e => {
      e.preventDefault();
      e.stopPropagation();

      const original_data = e.dataTransfer.getData('application/original-asset');
      if (original_data != null && original_data !== '') {
        this.library_upload_data = JSON.parse(original_data);
        if (AssetHelper.isAcceptedMime(this.accepted_mimes, this.library_upload_data.mime)) {
          this.fromAssetLibrary();
        } else {
          this.showAssetError();
        }
      } else if (this.$refs['custom-file-input'] != null) {
        // asset dropped
        const file = e.dataTransfer.files[0];
        const file_list = new DataTransfer();
        for (let file_item of e.dataTransfer.files) {
          file_list.items.add(file_item);
        }

        if (file != null) {
          if (AssetHelper.isAcceptedMime(this.accepted_mimes, file.type, file.name)) {
            this.$refs['custom-file-input'].files = file_list.files;
            this.$refs['custom-file-input'].dispatchEvent(new Event('change'));
          } else {
            this.showAssetError();
          }
        } else {
          this.showAssetError();
        }
      }
      this.dropbox_visible = false;
    });

    this.$el.addEventListener('dragleave', e => {
      this.dropbox_visible = false;
      e.preventDefault();
      e.stopPropagation();
    });
  },

  methods: {
    selectFileHandler() {
      this.$refs['custom-file-input'].click();
    },

    fromAssetLibrary() {
      this.initCroppingTool();
      this.Cropper.newFromURL(this.library_upload_data.uri);

      this.Cropper.onsave = settings => {
        const obj = {
          original_asset_id: this.library_upload_data.original_asset_id,
          crop: true,
          settings,
        };
        this.upload(obj, { type: this.library_upload_data.mime, width: this.library_upload_data.width, height: this.library_upload_data.height });
      };
    },

    editCustomAsset(options = null) {
      if (!options) {
        options = {};
      }

      if (options.overwrite == null) {
        options.overwrite = false;
      }

      if (this.custom_asset != null && !options.overwrite) {
        this.initCroppingTool(true);
        this.Cropper.onsave = settings => {
          const path = `v2/creative/${this.creative.creative_id}/assets/${this.asset_id}`;
          this.$http.put(path, { settings }).then(response => {
            this.$set(this.creative.assets, this.asset_id, response);
            this.$emit('change');
          });
        };
        this.Cropper.editAsset(this.custom_asset.original_url, this.custom_asset.settings);
      } else if (this.active_original_file?.file != null) {
        this.initial_edit_on_default = true;
        this.inputFileChangeHandler({
          target: {
            files: [this.active_original_file.file],
          },
        });
      }
    },

    resetCustomAsset() {
      this.loading = 'Resetting';
      const path = `v2/creative/${this.creative.creative_id}/assets/${this.asset_id}/reset`;
      this.$http
        .put(path, {})
        .then(response => {
          // set active as the 1st after reset
          this.$delete(this.creative.assets, this.asset_id);
          this.active_original_file = this.original_files[0];
          this.$emit('reset', response);
        })
        .finally(() => {
          this.loading = null;
        });
    },

    inputFileChangeHandler(e) {
      this.initCroppingTool();

      this.Cropper.onsave = settings => {
        const file = this.Cropper.getFile();
        this.saveFile(file, settings);
      };
      this.Cropper.inputChangeHandler(e);
    },

    saveFile(file, settings = null) {
      this.loading = 'Uploading';
      this.loading_percentage = 0;
      const handler = new Upload(file);
      handler.upload(({ percentage, done, upload_id, error, msg }) => {
        if (msg != null) {
          this.loading = msg;
        }
        this.loading_percentage = percentage;
        if (done) {
          const obj = {
            upload_id: upload_id,
            crop: true,
            hidden: this.initial_edit_on_default,
            settings,
          };
          this.upload(obj);
        }

        if (error?.message != null) {
          this.loading = null;
          this.loading_percentage = null;
          this.$alert(error.message);
        }
      });
    },

    upload(obj) {
      let options = {
        notification: true,
      };

      // disable upload notification when uploading library asset
      if (this.library_upload_data != null) {
        options.notification = false;
      }

      this.loading = 'Optimizing';
      const path = `v2/creative/${this.creative.creative_id}/assets/${this.asset_id}`;
      this.$http
        .post(path, obj, options)
        .then(response => {
          this.$set(this.creative.assets, this.asset_id, response);
          // send info if we should reload assets library or not
          // if we drag from library its not needed, any other upload the file should appear in lib
          this.$emit('change', this.library_upload_data == null);
        })
        .finally(_ => {
          this.loading = null;
          this.loading_percentage = null;
          this.library_upload_data = null;
          this.initial_edit_on_default = false;
        });
    },

    initCroppingTool(user_editing = false) {
      const sizes = AssetHelper.getSize(this.creative);

      const cropper_options = {
        cropSize: sizes.dimensions,
        achor: 'center',
        position: 'absolute',
        grid: 16,
        scale: 2, // force scale 2
        clip: {
          horizontal: true,
          vertical: true,
        },
        placement: {
          size: sizes.total,
          adsize: sizes.total,
        },
      };

      if (this.isMobile()) {
        cropper_options.scale *= SIZE.FW.INTERSTITIAL.WIDTH / this.creative.width;
      }

      // MVP hardcode reset positions for assets
      if (this.asset_id === CUSTOM_ASSET.CLOSE) {
        cropper_options.reset_behaviour = ['top', 'right'];
      } else if (this.asset_id === CUSTOM_ASSET.ARROW_LEFT) {
        cropper_options.reset_behaviour = ['left'];
      } else if (this.asset_id === CUSTOM_ASSET.ARROW_RIGHT) {
        cropper_options.reset_behaviour = ['right'];
      }

      this.Cropper.init(cropper_options);
      this.Cropper.beforeShow = null;

      // if its dragged in from library or file input, its a new asset and not an original edit
      const new_asset = !this.initial_edit_on_default;

      // if new asset is being opened, check asp  ect ratio of it and scale it to match custom gesture size
      if (new_asset || this.skip_cropping) {
        this.Cropper.beforeShow = (done, cropper, crop) => {
          if (user_editing === true) {
            return done();
          }
          // skip cropping with default position and size
          if (this.skip_cropping) {
            this.skip_cropping = false;

            if (!this.custom_asset) {
              // reset custom position and size, if no current assets has been uploade
              cropper.editor.reset();
            } else {
              // import current asset settings to reposition asset
              cropper.import(this.custom_asset.settings);
            }
            return crop();
          }

          // if cropper is not enabled, auto crop with original sizes
          // if custom file is selected from list, cropping will be skipped
          if (!this.cropper_enabled || this.skip_cropping) {
            const source_layer = cropper.editor.SourceLayer.size;
            const crop_layer = cropper.editor.CropLayer.size;

            if (this.effects_block || source_layer.width > crop_layer.width || source_layer.height > crop_layer.height) {
              // if added asset is bigger than the placement, fit the asset to the croparea, keeping the asset form being cut into half
              cropper.editor.fit();
            } else {
              // note backend crops overlays as x2, so we use half size of the original
              cropper.editor.InteractionLayer.resize(source_layer.width * 0.5, source_layer.height * 0.5);
              // beauty standards, keep it center 0,0 after resize
              cropper.editor.centerLayers();
            }
            return crop();
          }

          return done();
        };
      }
    },

    showAssetError() {
      this.$alert('Please check the list of permitted file types and try again.', 'Sorry, that file type is not accepted');
    },

    openFileList() {
      this.file_list_opened = true;
    },

    closeFileList() {
      this.file_list_opened = false;
    },

    selectFileFromList(file) {
      this.active_original_file = file;
      this.closeFileList();
      this.skip_cropping = true;

      // call edit as it would be new asset to be uploaded
      this.editCustomAsset({ overwrite: true });
    },
  },
};
</script>
