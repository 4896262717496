import ToggleTest from '@components/Items/ToggleTest.vue';
import SearchSelectTest from '@components/Items/SearchSelectTest.vue';
import DropdownTest from '@components/Items/DropdownTest.vue';
import TooltipTest from '@components/Items/TooltipTest.vue';
import ButtonTest from '@components/Items/ButtonTest.vue';
import InputTest from '@components/Items/InputTest.vue';
import AlertTest from '@components/Items/AlertTest.vue';
import SliderTest from '@components/Items/SliderTest.vue';
import SlotTest from '@components/Items/SlotTest.vue';
import SidebarTest from '@components/Items/SidebarTest.vue';
import ThumbnailTest from '@components/Items/ThumbnailTest.vue';
import LoadingTest from '@components/Items/LoadingTest.vue';
import DividerTest from '@components/Items/DividerTest.vue';
import PillTest from '@components/Items/PillTest.vue';
import CalendarTest from '@components/Items/CalendarTest.vue';
import ClickOutPageTest from '@components/Items/ClickOutPageTest.vue';
import NotificationBoxTest from '@components/Items/NotificationBoxTest.vue';
import CampaignCreativeNotFoundTest from '@components/Items/CampaignCreativeNotFoundTest.vue';
import UpsellingFooterTest from '@components/Items/UpsellingFooterTest.vue';
import UIGroupTest from '@components/Items/UIGroupTest.vue';
import AnglePickerTest from '@components/Items/AnglePickerTest.vue';
import CreativeSizesTest from '@components/Items/CreativeSizesTest.vue';
import NotificationsTest from '@components/Items/NotificationsTest.vue';
import CreativeSizeTest from '@components/Items/CreativeSizeTest.vue';
import UserImageTest from '@components/Items/UserImageTest.vue';
import TabsTest from '@components/Items/TabsTest.vue';
import BreadcrumbTest from '@components/Items/BreadcrumbTest.vue';

const views = [
  { label: 'search-select', component: SearchSelectTest },
  { label: 'toggle', component: ToggleTest },
  { label: 'dropdown', component: DropdownTest },
  { label: 'tooltip', component: TooltipTest },
  { label: 'button', component: ButtonTest },
  { label: 'input', component: InputTest },
  { label: 'alert', component: AlertTest },
  { label: 'slider', component: SliderTest },
  { label: 'slot', component: SlotTest },
  { label: 'sidebar', component: SidebarTest },
  { label: 'thumbnail', component: ThumbnailTest },
  { label: 'loading', component: LoadingTest },
  { label: 'divider', component: DividerTest },
  { label: 'pill', component: PillTest },
  { label: 'calendar', component: CalendarTest },
  { label: 'clickout-page', component: ClickOutPageTest },
  { label: 'notification-box', component: NotificationBoxTest },
  { label: 'campaign-creative-not-found', component: CampaignCreativeNotFoundTest },
  { label: 'upselling-footer', component: UpsellingFooterTest },
  { label: 'ui-group', component: UIGroupTest },
  { label: 'angle-picker', component: AnglePickerTest },
  { label: 'creative-pack-sizes', component: CreativeSizesTest },
  { label: 'notifications', component: NotificationsTest },
  { label: 'creative-size', component: CreativeSizeTest },
  { label: 'user-image', component: UserImageTest },
  { label: 'tabs', component: TabsTest },
  { label: 'breadcrumb', component: BreadcrumbTest },
]
  .map(child => ({ ...child, path: `${child.label}-test` }))
  .sort((a, b) => (a.path > b.path ? 1 : -1));

export default views;
