import { CUSTOM_ASSET } from '@master/constants';
import AssetHelper from '@helpers/Asset';
import Slot from './Slot';
import Drawable from './Drawable';
import CustomAssetService from '@master/Services/Cache/CustomAssetService';

const DEFAULT_PAGINATION_ARROW_SIZE = 40;

export default class ArrowsHelper {
  #background;
  #handler;
  #creative;
  #first_slot;
  constructor(background, handler) {
    this.#background = background;
    this.#handler = handler;
    this.#creative = this.#background.getCreative();
    this.#first_slot = this.#handler.getFirstFeedAsset();
  }

  #findArrows() {
    let left_arrow = null;
    let right_arrow = null;

    // arrows are found in additional overlays
    // or in assets when hotspot is active
    for (const slot of this.#handler.additional.slots.Overlay.concat(this.#handler.additional.slots.Assets)) {
      const asset_id = slot.asset_id;
      if (AssetHelper.isCustomArrowLeft(asset_id)) {
        left_arrow = slot;
      } else if (AssetHelper.isCustomArrowRight(asset_id)) {
        right_arrow = slot;
      }
    }

    return { left_arrow, right_arrow };
  }

  #getDefaultPosition() {
    const position = {
      y: 0,
    };
    // default position for arno is by the 1st carousel slot
    if (this.#creative.$arno && this.#first_slot) {
      position.y = this.#first_slot.y;
    }
    return position;
  }

  #modifyPositionWithArno(arrow) {
    if (!this.#creative.$arno) return;
    const { h, y } = this.#creative.$arno.getSize('1');
    const fake_layer = { width: this.#creative.width, height: h };
    arrow.adjustForOverlay(fake_layer);
    arrow.setY(arrow.getY() + y);
  }

  modify(drawables) {
    let { left_arrow, right_arrow } = this.#findArrows();
    const position = this.#getDefaultPosition();

    if (!left_arrow) {
      left_arrow = new Slot(CUSTOM_ASSET.ARROW_LEFT, CustomAssetService.get(CUSTOM_ASSET.ARROW_LEFT).first());
      left_arrow.customSettings({ x: -this.#creative.width * 0.5 + DEFAULT_PAGINATION_ARROW_SIZE * 0.5, y: position.y, width: DEFAULT_PAGINATION_ARROW_SIZE, height: DEFAULT_PAGINATION_ARROW_SIZE });
    } else {
      this.#modifyPositionWithArno(left_arrow);
    }

    if (!right_arrow) {
      right_arrow = new Slot(CUSTOM_ASSET.ARROW_RIGHT, CustomAssetService.get(CUSTOM_ASSET.ARROW_RIGHT).first());
      right_arrow.customSettings({ x: this.#creative.width * 0.5 - DEFAULT_PAGINATION_ARROW_SIZE * 0.5, y: position.y, width: DEFAULT_PAGINATION_ARROW_SIZE, height: DEFAULT_PAGINATION_ARROW_SIZE });
    } else {
      this.#modifyPositionWithArno(right_arrow);
    }

    // draw regards active asset index being below or above
    let { active_overlay_index } = this.#background.getOverlayIndexes();
    const active_is_overlay = active_overlay_index !== -1;

    let default_pagination_layer = this.#background.layers.Overlay;
    if (active_is_overlay) {
      default_pagination_layer = this.#background.layers.Assets;
    }

    if (this.#background.isActiveHotspot() || this.#background.isActiveGesture()) {
      default_pagination_layer = this.#background.layers.Assets;
    }

    if (this.#background.getActiveAssetId() !== CUSTOM_ASSET.ARROW_LEFT) {
      // draw arrows on bg asset layer because FW also draws arrows below overlays
      const left_arrow_drawable = default_pagination_layer.addAsset(new Drawable(left_arrow));
      if (left_arrow_drawable) {
        drawables.push(left_arrow_drawable);
      }
    }

    if (this.#background.getActiveAssetId() !== CUSTOM_ASSET.ARROW_RIGHT) {
      // draw arrows on bg asset layer because FW also draws
      const right_arrow_drawable = default_pagination_layer.addAsset(new Drawable(right_arrow));
      if (right_arrow_drawable) {
        drawables.push(right_arrow_drawable);
      }
    }
  }
}
