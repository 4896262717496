class Navigator {
  constructor(start) {
    if (Array.isArray(start)) {
      const directory = start[start.length - 1];
      if (directory) {
        directory.active = true;
      }
      this.current = directory;
      this.history = start;
    } else {
      if (start) {
        start.active = true;
      }
      this.current = start;
      this.history = [start];
    }
  }

  goToRoot() {
    if (this.history.length > 1) {
      const directory = this.history[0];
      if (directory) {
        directory.active = true;
      }
      this.current = directory;
      this.history = [directory];
    }
  }

  goToDir(directory) {
    if (directory == null || directory === this.current) {
      return;
    }

    const index = this.history.findIndex(breadcrumb => breadcrumb?.item_id === directory?.item_id);

    if (index === -1) {
      directory.active = true;
      this.history.push(directory);
    } else {
      this.history = this.history.slice(0, index + 1);
      this.history[index].active = true;
    }

    this.current = directory;
  }

  goBack() {
    if (this.history.length > 1) {
      this.history.pop();
      const directory = this.history[this.history.length - 1];
      // some cases root can be null as well
      if (directory) {
        directory.active = true;
      }
      this.current = directory;
    }
  }
}

export default Navigator;
