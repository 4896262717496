import CampaignService from '@master/Services/CampaignService';
import { VIEW } from '@master/constants';
import Service from '@libs/Service';

class SidebarService extends Service {
  vnode = null;
  state = {}; // setup in init::reset

  _init(vnode) {
    this.reset();
    this.vnode = vnode;
  }

  setBack(label, url = null) {
    this.state.back = {
      label,
      url,
    };
    this.update();
  }

  setCreatives(creatives, url) {
    const creatives_label = 'Creatives';

    // campaign view do not show creatives if no campaign is expanded(active)
    // use breadcrumb url for creatives in single creative view to get its parent
    if (this.isCampaignView()) {
      if (CampaignService.active.has() && url != null) {
        this.state.creatives = { label: creatives_label, url };
      } else {
        this.state.creatives = null;
      }
    } else {
      this.state.creatives = {
        label: creatives_label,
        url: url ?? this.creativesPath(),
      };
    }

    if (!creatives?.length) {
      this.state.creatives_subitems = null;
      return;
    }

    this.state.creatives_subitems = creatives.reduce((result, creative) => {
      result.push(this.makeCreativeSubitem(creative));
      return result;
    }, []);
  }

  setFolders(folders) {
    if (!folders?.length) {
      this.state.folders = null;
      return;
    }

    this.state.folders = folders.reduce((result, folder) => {
      result.push(this.makeFolderSubitem(folder));
      return result;
    }, []);
  }

  addCreative(creative) {
    if (this.state.creatives_subitems == null) {
      this.state.creatives_subitems = [];
    }

    this.state.creatives_subitems.push(this.makeCreativeSubitem(creative));
  }

  addFolder(folder) {
    if (this.state.folders == null) {
      this.state.folders = [];
    }

    this.state.folders.push(this.makeFolderSubitem(folder));
  }

  updateCreative(creative_id, label) {
    if (!this.state.creatives_subitems) return;

    const index = this.state.creatives_subitems.findIndex(creative => creative.creative_id === creative_id);

    if (index > -1) {
      this.state.creatives_subitems[index].label = label;
      this.update();
    }
  }

  updateFolder(folder_id, label) {
    if (!this.state.folders) return;

    const index = this.state.folders.findIndex(folder => folder.folder_id === folder_id);

    if (index > -1) {
      this.state.folders[index].label = label;
      this.update();
    }
  }

  setAnalytics(url) {
    this.state.analytics = url;
    this.update();
  }

  /**
   * @param {string | Promise<void>} url
   */
  setPreview(url) {
    this.state.preview = url;
    this.update();
  }

  setFlight(object) {
    this.state.flight = object;
    this.update();
  }

  setEdit(label, callback = null) {
    if (!label) {
      this.state.edit = null;
    } else {
      this.state.edit = {
        label,
        callback,
      };
    }
    this.update();
  }

  setCampaignAnalytics(creatives, url, name = 'Campaign') {
    const label = `${name} Analytics`;

    // campaign view do not show creatives if no campaign is expanded(active)
    // use breadcrumb url for creatives in single creative view to get its parent
    this.state.campaign_analytics = {
      label,
      url,
    };

    if (!creatives?.length) {
      this.state.campaign_analytics_subitems = null;
      return;
    }

    this.state.campaign_analytics_subitems = creatives.reduce((result, creative) => {
      result.push({
        label: creative.live_name ?? creative.name ?? '',
        url: creative.analytics_url,
        live_id: creative.live_id,
        quantums: creative.quantums,
      });
      return result;
    }, []);

    this.update();
  }

  setTests(tests) {
    if (!tests?.length) {
      this.state.tests = null;
      return;
    }

    this.state.tests = tests.reduce((acc, test) => {
      acc.push({
        label: test.label ?? '',
        url: test.path,
      });
      return acc;
    }, []);

    this.update();
  }

  reset() {
    this.state.creatives = null;
    this.state.creatives_subitems = null;
    this.state.folders = null;

    this.state.analytics = null;
    this.state.preview = null;
    this.state.flight = null;

    this.state.back = {
      label: null,
      url: null,
      callback: null,
    };

    this.state.edit = null;

    this.state.campaign_analytics = null;
    this.state.campaign_analytics_subitems = null;

    this.state.tests = null;

    this.update();
  }

  getState() {
    return this.state;
  }

  update() {
    if (this.vnode) {
      this.vnode.stateUpdate(this.state);
    }
  }

  creativesPath() {
    const campaign_id = this.#getRouteParam('campaign_id');
    const folder_id = this.#getRouteParam('folder_id');

    switch (true) {
      case campaign_id != null && folder_id != null:
        return `/c/${campaign_id}/f/${folder_id}`;
      case folder_id != null:
        return `/f/${folder_id}`;
      case campaign_id != null:
        return `/c/${campaign_id}`;
      default:
        return '';
    }
  }

  makeCreativeSubitem(creative) {
    return {
      label: creative.name ?? '',
      url: `${this.#creativePath()}/${creative.creative_id}`,
      creative_id: creative.creative_id,
    };
  }

  makeFolderSubitem(folder) {
    const campaign_id = this.#getRouteParam('campaign_id');

    return {
      label: folder.name ?? '',
      url: folder.url ?? `/c/${campaign_id}/f/${folder.folder_id}`,
      folder_id: folder.folder_id,
    };
  }

  isCampaignView() {
    const route_name = this.#getRoute().name;
    return route_name === VIEW.CAMPAIGNS || route_name === VIEW.CAMPAIGNS_FOLDERS;
  }

  #creativePath() {
    const campaign_id = this.#getRouteParam('campaign_id');
    const folder_id = this.#getRouteParam('folder_id');

    switch (true) {
      case folder_id != null:
        return `/f/${folder_id}`;
      case campaign_id != null:
        return `/c/${campaign_id}`;
      default:
        return '';
    }
  }

  #getRoute() {
    return this.router.history.current;
  }

  #getRouteParam(name) {
    return this.#getRoute().params[name];
  }
}

export default new SidebarService();
