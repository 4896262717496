import $http from '@master/Services/HttpService';
import RequestCache from '@libs/RequestCache';

export function getTimezones() {
  return RequestCache.get(`${process.env.VUE_APP_CDN}dist/static/timezones.json`, {
    withCredentials: false,
  })
    .then(response => {
      return response;
    })
    .catch(_ => {
      return null;
    });
}

export function getLanguages() {
  return RequestCache.get(`${process.env.VUE_APP_CDN}dist/static/languages.json`, {
    withCredentials: false,
  })
    .then(response => {
      return response;
    })
    .catch(_ => {
      return null;
    });
}

export function getCountries() {
  return RequestCache.get('countries', { auth: false })
    .then(countries => {
      return { '': 'Select country', ...countries };
    })
    .catch(_ => {
      return null;
    });
}

export function poll(args) {
  const path = args?.path;

  if (path == null || path === '') {
    return null;
  }

  const data = args?.data ?? {};
  const options = args?.options ?? { notification: false };
  const ms = args?.ms ?? 5000;

  return $http
    .post(path, data, options)
    .then(async response => {
      if (response === 'processing') {
        if (typeof args.callback === 'function') {
          args.callback(response);
        }
        await sleep(ms);
        return poll(args);
      }

      return response;
    })
    .catch(error => {
      return Object.assign(error?.response?.result ?? {}, {
        error: true,
      });
    });
}
