import AssetHelper from '@helpers/Asset';

import Slot from './Slot';
import MainHandler from './MainHandler';
import LayoutHelper from '@helpers/Layouts';

export default class LayoutHandler extends MainHandler {
  #layout;
  #layout_slots;

  constructor(background) {
    super(background);
    this.#layout = this.getCreative().layout ?? {};
    this.#layout_slots = this.#getLayoutSlots(this.#layout.layout_id);
  }

  handleAssets() {
    const assets = this.#getAssets();

    for (const layout_slot of this.#layout_slots) {
      if (AssetHelper.isHiddenInCropper(layout_slot.id)) {
        continue;
      }

      const is_background = AssetHelper.isBackground(layout_slot);
      const is_overlay = AssetHelper.isOverlay(layout_slot);

      const uploaded_asset = assets[layout_slot.id] ?? null;
      const has_uploaded_asset = uploaded_asset != null;

      const slot = new Slot(layout_slot.id, uploaded_asset);

      if (!is_overlay && !is_background) {
        this.setFirstFeedAsset(layout_slot);
      }

      if (has_uploaded_asset) {
        this.#handleUploadedAsset(slot, layout_slot);
      } else if (!is_background && !is_overlay) {
        this.#handleEmptyMainAsset(slot, layout_slot);
      }
    }
  }

  #getAssets() {
    const creative = this.getCreative();
    return creative.assets ?? {};
  }

  /**
   *
   * @param {Slot} slot
   * @param {object} layout_slot
   */
  #handleUploadedAsset(slot, layout_slot) {
    const is_background = AssetHelper.isBackground(layout_slot);
    const is_overlay = AssetHelper.isOverlay(layout_slot);

    if (is_background) {
      slot.adjustForFeed(layout_slot);
      this.slots.Background.push(slot);
    } else if (is_overlay) {
      slot.adjustForOverlay(this.getBackground().layers.Overlay);
      this.slots.Overlay.push(slot);
    } else {
      slot.adjustForFeed(layout_slot);
      this.slots.Assets.push(slot);
    }
  }

  /**
   * @param {Slot} slot
   * @param {object} layout_slot
   */
  #handleEmptyMainAsset(slot, layout_slot) {
    // no gray boxes for optionals or for floating obj
    if (layout_slot.required === 0 || AssetHelper.isFloatingObject(layout_slot)) {
      return;
    }

    // only feed assets can be frawn as fallback gray boxes
    const sizes = AssetHelper.getSize(this.getCreative(), layout_slot);
    // mock asset to create gray box
    slot.adjustForFallback(layout_slot, sizes);
    this.slots.Assets.push(slot);
  }

  #getLayoutSlots = layout_id => {
    const _modules = LayoutHelper.getUiGroups(layout_id);

    const get = input => {
      const excludes = ['extra.effects_widgets'];
      let result = [];

      for (const item of Object.values(input)) {
        if (excludes.includes(item.ui_group_key)) continue;

        // if theres modules, find assets from there
        let modules = null;
        if (item.modules && Object.keys(item.modules).length > 0) {
          modules = get(item.modules);
        }

        // if theres slots, find assets from there
        let slots = null;
        if (item.slots && Object.keys(item.slots).length > 0) {
          slots = get(item.slots);
        }

        // if this item is asset type, push it to the array
        if (item.slot_type === 'asset' && !AssetHelper.isJson(item.extensions.join(',')) && !AssetHelper.isCsv(item.extensions.join(','))) {
          result.push({
            id: item._fullpath,
            type: item.type,
            required: item.required,
            position: item.position,
            x: item.x,
            y: item.y,
            width: item.width,
            height: item.height,
            extensions: item.extensions,
            order: item.order,
          });
        }

        // if this item has modules, push them to the output
        if (modules != null) {
          modules.forEach(module => {
            // assign parent props if they exist
            module.type = item.type ?? module.type;
            module.required = item.required ?? module.required;
            module.position = item.position ?? module.position;
            module.x = item.x ?? module.x;
            module.y = item.y ?? module.y;
            module.width = item.width ?? module.width;
            module.height = item.height ?? module.height;
            module.extensions = item.extensions ?? module.extensions;
            module.order = item.order > module.order ? item.order : module.order;
            module._slot_id = item.slot_id ?? null;
            if (!AssetHelper.isJson(module.extensions.join(',')) && !AssetHelper.isCsv(module.extensions.join(','))) {
              result.push(module);
            }
          });
        }

        // if this item has slots, push them to the output
        if (slots != null) {
          slots.forEach(slot => {
            // assign parent props if they exist
            slot.type = item.type ?? slot.type;
            slot.required = item.required ?? slot.required;
            slot.position = item.position ?? slot.position;
            slot.x = item.x ?? slot.x;
            slot.y = item.y ?? slot.y;
            slot.width = item.width ?? slot.width;
            slot.height = item.height ?? slot.height;
            slot.extensions = item.extensions ?? slot.extensions;
            slot.order = item.order > slot.order ? item.order : slot.order;
            slot._slot_id = slot.id;
            if (!AssetHelper.isJson(slot.extensions.join(',')) && !AssetHelper.isCsv(slot.extensions.join(','))) {
              result.push(slot);
            }
          });
        }
      }

      return result;
    };

    return this.#filterAndOrderSlots(get(_modules));
  };

  #filterAndOrderSlots(slots) {
    const groupped_slots = this.#groupLayoutSlots(slots);

    const assets = this.#getAssets();
    const slots_to_handle = [];

    for (const parent_id in groupped_slots) {
      const slots = groupped_slots[parent_id];
      let found = false;
      for (const slot of slots) {
        // only handle 1st match of each slot group
        if (assets[slot.id]) {
          slots_to_handle.push(slot);
          found = true;
          break;
        }
      }
      // if no assets in the slot modules, use 1st as empty placeholder
      if (!found) {
        slots_to_handle.push(slots[0]);
      }
    }

    return slots_to_handle.sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      }
      return 0;
    });
  }

  #groupLayoutSlots(slots) {
    const groupped_list = {};

    // group same slots content
    for (const slot of slots) {
      if (slot.id.includes('button')) {
        continue;
      }

      let split_key = null;
      if (slot.id.includes('video')) {
        split_key = '.video';
      } else if (slot.id.includes('image')) {
        split_key = '.image';
      }
      if (split_key) {
        const [parent_id] = slot.id.split(split_key);
        if (!groupped_list[parent_id]) {
          groupped_list[parent_id] = [];
        }
        groupped_list[parent_id].push(slot);
      } else {
        groupped_list[slot.id] = [slot];
      }
    }
    return groupped_list;
  }
}
