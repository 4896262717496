<template lang="pug">
div
  SettingsContainer(v-if="show_video_endcard_settings" :columns="1" title="Video end card settings")
    SettingsRow(
      v-if="settings?.duration != null"
      label="Duration"
      tooltip="Specify how long the end card image is shown at the end of the video"
    )
      Slider(v-model="settings.duration" :options="{min: 1, max: 15, step: 1, unit: 'sec'}" @change="changeHandler()")

    SettingsRow(
      v-if="settings?.fade_in_time != null"
      label="Fade duration"
      tooltip="Specify the time it takes for the end card to smoothly fade over the previous content in milliseconds"
    )
      Slider(v-model="settings.fade_in_time" :options="{min: 0, max: 1500, step: 100, unit: 'ms'}" @change="changeHandler()")

    SettingsRow(
      v-if="settings?.below_overlays != null"
      label="Keep overlays visible"
      tooltip="Keeps the added overlays also visible during the endcard"
  )
      Toggle(v-model="settings.below_overlays" @input="changeHandler()")

  SettingsContainer(v-if="show_hotspot_settings" :columns="1" title="Hotspot settings" :collapsed="false")
    SettingsRow(
      v-if="settings?.gesture_id != null"
      label="Pin gesture"
      align="top"
      tooltip="This is the element that, when clicked, opens the hot spot media. You can upload a custom asset or choose from predefined assets in the dropdown menu."
    )
      CustomAsset(
        :creative="creative"
        :asset_id="settings.gesture_id"
        :disabled="false"
      )
      em.mt-4.fs-12.color-gray-800 Accepted files:&nbsp;
        span.color-gray-500 ({{ file_extensions }})
      em.fs-12.color-gray-800 Size:&nbsp;
        span.color-gray-500 Max: placement size {{creative.width * 2}}x{{creative.height * 2}}

    SettingsRow(
      v-if="settings?.gesture_animation?.type != null"
      label="Pin animation"
      tooltip="Select an animation for the pin asset. If you prefer it to be static, choose \"None.\""
    )
      SearchSelect(
        :options="{fade: 'Fade', pulse: 'Pulse', '': 'None'}"
        :search="false"
        v-model="settings.gesture_animation.type"
        width="8rem"
        @input="changeHandler()"
      )

    SettingsRow(
      v-if="settings?.close_id != null"
      label="Close icon"
      align="top"
      tooltip="This is the element that, when clicked, closes the hot spot media. You can upload a custom asset or choose from predefined assets in the dropdown menu."
    )
      CustomAsset(
        :creative="creative"
        :asset_id="settings.close_id"
        :disabled="false"
      )
      em.mt-4.fs-12.color-gray-800 Accepted files:&nbsp;
        span.color-gray-500 ({{ file_extensions }})
      em.fs-12.color-gray-800 Size:&nbsp;
        span.color-gray-500 Max: placement size {{creative.width * 2}}x{{creative.height * 2}}

    SettingsRow(
      v-if="settings?.settings?.background_color != null"
      label="Background color"
      tooltip="Choose the background color that will appear beneath the hot spot media."
    )
      ColorOption.col-10(v-model="settings.settings.background_color" @input="changeHandler()")

    SettingsRow(
      v-if="settings?.settings?.background_alpha != null"
      label="Background opacity"
      tooltip="Select the opacity level for the background color. 0% represents complete transparency, while 100% is fully opaque."
    )
      Slider(v-model="settings.settings.background_alpha" :options="{min: 0, max: 1, step: .1, max_text: '100%', min_text: '0%', unit: '%', scale: 100}" @change="changeHandler()")

  SettingsContainer(v-if="show_splash_settings" title="Polite load image settings")
    SettingsRow(
      v-if="settings?.duration != null"
      label="Display Time"
    )
      Input(v-model="settings.duration" v-number-input suffix="ms" @blur="changeHandler()" style="width: 100px;")

  div(v-if="show_video_settings")
    SettingsContainer(:columns="2" title="Playback and controls" :collapsed="false")
      SettingsRow(
        v-if="settings?.autoplay != null"
        label="Autoplay"
      )
        Toggle(v-model="settings.autoplay" :disabled="!settings.video_controls" @input="changeHandler()")

      div(v-if="isDynamicEndcard()")
      SettingsRow(
        v-else-if="settings?.loop != null"
        label="Loop"
      )
        div.flex.flex-align-center.column-gap-8
          CustomVideoControlAsset(v-if="!settings.loop && !isQuantum()" type="replay" :asset_id="asset_id" :creative="creative" :crop_settings="crop_settings" @change="change")
          Toggle(v-model="settings.loop" @input="changeHandler()")

      SettingsRow(
        v-if="settings?.audio != null"
        label="Audio button"
      )
        div.flex.flex-align-center.column-gap-8
          CustomVideoControlAsset(v-if="settings.audio && !isQuantum()" type="audio" :asset_id="asset_id" :creative="creative" :crop_settings="crop_settings" @change="change")
          Toggle(v-model="settings.audio" @input="changeHandler()")

      SettingsRow(
        v-if="settings?.video_controls != null"
        label="Controls"
      )
        div.flex.flex-align-center.column-gap-8
          CustomVideoControlAsset(v-if="settings.video_controls && !isQuantum()" type="control" :asset_id="asset_id" :creative="creative" :crop_settings="crop_settings" @change="change")
          Toggle(v-model="settings.video_controls" :disabled="!settings.autoplay" @input="changeHandler()")

    SettingsContainer(
      v-if="settings.transparency?.enabled != null"
      :columns="2"
      style="grid-column: 1 / -1;"
      title="Video transparency"
    )
      SettingsRow(label="Transparency")
        Toggle(v-model="settings.transparency.enabled" @input="changeHandler()")

      SettingsRow(v-if="settings.transparency?.chroma != null" label="Chroma key")
        ColorOption(v-model="settings.transparency.chroma" :disabled="!settings.transparency.enabled" style="width: 6rem;" @input="changeHandler({chroma: true})")

      div
        SettingsRow(v-if="settings.transparency?.similarity != null" label="Similarity" :tooltip="similarity_tooltip")
        Slider(
          v-model="settings.transparency.similarity"
          :options="{min: 0, max: 1, step: .01, scale: 100}"
          :input="true"
          :input_suffix="'%'"
          :disabled="!settings.transparency.enabled"
          @change="changeHandler()"
        )
      div
        SettingsRow(
          v-if="settings.transparency?.smoothness != null"
          label="Smoothness"
          :tooltip="smoothness_tooltip"
        )
        Slider(
          v-model="settings.transparency.smoothness"
          :options="{min: 0, max: 1, step: .01, scale: 100}"
          :input="true"
          :input_suffix="'%'"
          :disabled="!settings.transparency.enabled"
          @change="changeHandler()"
        )
      div
        SettingsRow(
          v-if="settings.transparency?.spill != null"
          label="Spill"
          :tooltip="spill_tooltip"
        )
        Slider(
          v-model="settings.transparency.spill"
          :options="{min: 0, max: 1, step: .01, scale: 100}"
          :input="true"
          :input_suffix="'%'"
          :disabled="!settings.transparency.enabled"
          @change="changeHandler()"
        )

    SettingsContainer(
      v-if="settings.progress_bar != null"
      :columns="2"
      title="Video progress bar"
    )
      div
        SettingsRow(label="Progress Bar")
          Toggle(v-model="settings.progress_bar.enabled" @input="changeHandler()")
      div
        SettingsRow(label="Interactive" :disabled="!settings.progress_bar.enabled")
          Toggle(v-model="settings.progress_bar.allow_seek" :disabled="!settings.progress_bar.enabled" @input="changeHandler()")
      div
        SettingsRow(label="Background")
          ColorOption(v-model="settings.progress_bar.bg_color" :disabled="!settings.progress_bar.enabled" style="width: 6rem;" @input="changeHandler()")
      div
        SettingsRow(label="Opacity")
          StyleInput.col-14.align-right(
            v-model="settings.progress_bar.bg_alpha"
            :default="1"
            :min="0"
            :max="1"
            :scale="100"
            :disabled="!settings.progress_bar.enabled"
            style="width: 8rem;"
            label="%"
            @input="changeHandler()"
          )
      div
        SettingsRow(label="Foreground")
          ColorOption(v-model="settings.progress_bar.color" :disabled="!settings.progress_bar.enabled" style="width: 6rem;" @input="changeHandler()")
      div
        SettingsRow(label="Opacity")
          StyleInput.col-14.align-right(
            v-model="settings.progress_bar.fg_alpha"
            :default="1"
            :min="0"
            :max="1"
            :scale="100"
            :disabled=" !settings.progress_bar.enabled"
            style="width: 8rem;"
            label="%"
            @input="changeHandler()"
          )
      div
        SettingsRow(label="Vertical position" :tooltip="progress_bar_position_tooltip")
        Slider(
          v-model="settings.progress_bar.vertical_position"
          :options="{min: 0, max: 1, step: .01, scale: 100}"
          :input="true"
          :input_suffix="'%'"
          :disabled=" !settings.progress_bar.enabled"
          @change="changeHandler()"
        )
    SettingsContainer(v-if="admin_toggle === true" :columns="2" title="Video settings for Admin")
      SettingsRow(
        v-if="settings?.fullscreen != null"
        label="Fullscreen btn"
      )
        div.flex.flex-align-center.column-gap-8
          CustomVideoControlAsset(v-if="settings.fullscreen && !isQuantum()" type="fullscreen" :asset_id="asset_id" :creative="creative" :crop_settings="crop_settings" @change="change")
          Toggle( v-model="settings.fullscreen" @input="changeHandler()")

      SettingsRow(
        v-if="settings?.internal_audio != null"
        label="Int. audio"
        tooltip="Select when you want that audio track is not extracted from video (results in bigger video file size)"
      )
        Toggle( v-model="settings.internal_audio" @input="changeHandler({crop: true})")

      SettingsRow(
        v-if="settings?.loop_limit != null"
        label="Loop count"
      )
        input.form-control(
          type="number"
          v-model="settings.loop_limit"
          :reference="'loop_limit'"
          min="0"
          max="30"
          v-positive-number-input
          @input="changeHandler()"
        )

      SettingsRow(
        v-if="settings?.click_action != null"
        label="Click action"
      )
        SearchSelect(
          :options="{'': 'None', audio: 'Audio', video: 'Play/Pause', fullscreen: 'Fullscreen'}"
          :search="false"
          v-model="settings.click_action"
          @input="changeHandler()"
        )
</template>

<script>
import AssetHelper from '@helpers/Asset';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';

import CustomAsset from '@cm/Views/Creatives/Cards/Components/TemplateSettings/CustomAsset.vue';
import CustomVideoControlAsset from '@cm/Views/Creatives/Cards/Components/TemplateSettings/CustomVideoControlAsset.vue';
import SettingsContainer from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsContainer.vue';
import SettingsRow from '@cm/Views/Creatives/Cards/Components/Asset/Settings/SettingsRow.vue';
import StyleInput from '@cm/UI/Inputs/StyleInput.vue';

import ColorOption from '@master/UI/ColorOption.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import Slider from '@master/UI/Slider.vue';
import Toggle from '@master/UI/Toggle.vue';
import Input from '@master/UI/Input/Input.vue';

export default {
  name: 'SettingsAsset',
  mixins: [CreativeTraits],

  components: {
    ColorOption,
    CustomAsset,
    CustomVideoControlAsset,
    Input,
    SearchSelect,
    SettingsContainer,
    SettingsRow,
    Slider,
    StyleInput,
    Toggle,
  },

  props: {
    creative: {
      type: Object,
      required: true,
    },

    asset_id: {
      type: String,
      required: true,
    },

    crop_settings: {
      type: Object,
      default: null,
    },
  },

  computed: {
    is_video_endcard() {
      return this.creative?.assets?.[this.asset_id]?.filename?.includes('endcard');
    },

    is_hotspot() {
      return AssetHelper.isCustomHotspotMain(this.asset_id);
    },

    settings() {
      return this.creative.settings?.assets?.[this.asset_id] ?? null;
    },

    show_video_endcard_settings() {
      return this.settings != null && this.is_video_endcard && Object.keys(this.settings ?? {}).some(key => this.settings[key] != null);
    },

    show_hotspot_settings() {
      return (
        this.settings != null &&
        this.is_hotspot &&
        Object.keys(this.settings ?? {}).some(key => {
          if (typeof this.settings[key] === 'object') {
            return Object.keys(this.settings[key]).some(sub_key => this.settings[key][sub_key] != null);
          }

          return this.settings[key] != null;
        })
      );
    },

    asset() {
      return this.creative.assets?.[this.asset_id] ?? this.creative?.additional_assets?.[this.asset_id] ?? null;
    },

    show_video_settings() {
      // no settings shown for instream creative types
      return !this.isVideo() && AssetHelper.isVideo(this.asset?.uri) && this.settings != null;
    },

    is_custom_splash() {
      return AssetHelper.isCustomSplash(this.asset_id);
    },

    show_splash_settings() {
      return this.settings != null && this.is_custom_splash && Object.keys(this.settings ?? {}).some(key => this.settings[key] != null);
    },

    similarity_tooltip() {
      return `
        Sets the threshold level for pixel similarity to the key color.
        Pixels with a color distance less than this value become fully transparent.
        Adjust to fine-tune the transparency of pixels closely matching the key color.
      `;
    },

    smoothness_tooltip() {
      return `
        Controls the rate at which transparency increases for pixels as their color
        distance from the key color exceeds the similarity threshold.
        A higher value results in a smoother transition from opaque to transparent,
        useful for blending edges and maintaining detail in semi-transparent areas.
      `;
    },

    spill_tooltip() {
      return `
        Determines the rate of chrominance desaturation for pixels near the key color,
        reducing color spill from the background onto the subject.
        A higher spill value causes a quicker drop-off in desaturation,
        ideal for managing the extent of color reflection from the key onto the subject.
      `;
    },

    progress_bar_position_tooltip() {
      return 'Determines where vertically the progress bar is placed, 0% being the bottom and 100% being top.';
    },
  },

  data() {
    return {
      admin_toggle: false,
      file_extensions: AssetHelper.mimeToExtension(AssetHelper.getImageMimes().join(', ')),
    };
  },

  created() {
    this.$user.subscribe(() => {
      this.admin_toggle = this.$user.adminMode();
    }, this);
  },

  methods: {
    changeHandler(option = null) {
      let obj = {
        value: this.creative.settings.assets,
        options: {},
      };

      if (option?.crop) {
        obj.options.crop = [this.asset_id];
      }

      if (option?.chroma) {
        obj.options.chroma = [this.asset_id];
      }

      this.$http.put(`creative/${this.creative.creative_id}/settings/assets`, obj).then(response => {
        if (response?.assets?.[this.asset_id]) {
          for (const setting_key in response.assets[this.asset_id]) {
            const value = response.assets[this.asset_id][setting_key];
            this.$set(this.settings, setting_key, value);

            // only sync bg color updates when transparency setting is enabled
            if (setting_key === 'transparency' && value.enabled === true) {
              this.handleTransparencyChange();
            }
          }
        }

        this.change();
      });
    },

    handleTransparencyChange() {
      // update only main assets and their previews and settings
      // because other asset types have no bg options
      const main_asset = this.creative.assets?.[this.asset_id];
      if (!main_asset) return;

      // sanity check for chroma key
      const chroma = this.settings?.transparency?.chroma;
      if (!chroma) return;

      // only update bg values when single bg color is selected (SD-7009)
      const settings = main_asset?.settings;
      const bg_color_setting = settings?.CropLayer?.backgroundSettings?.values?.color ?? null;
      if (bg_color_setting?.length !== 1) return;

      // set UI value for the setting
      bg_color_setting[0] = chroma;
    },

    change() {
      this.$emit('change', true);
    },
  },
};
</script>
