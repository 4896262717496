import { FLIGHT } from '@master/constants';
import FlightService from '@master/Services/FlightService';

export function getCreativeConditions() {
  const flight = FlightService.get();
  const result = [];

  if (flight == null) {
    return result;
  }

  loopStatements(flight.statements ?? [], [], result);
  return result;
}

function loopStatements(statements, conditions = [], result = {}) {
  for (const statement of statements) {
    const statement_conditions = conditions.concat(statement.conditions ?? []);
    const grouped_conditions = groupConditions(statement_conditions);

    loopCreatives(statement.creatives, grouped_conditions, result);

    if (statement?.statements?.length > 0) {
      loopStatements(statement.statements, statement_conditions, result);
    }
  }
}

function loopCreatives(creatives, conditions, result) {
  for (const creative of creatives) {
    result.push({
      creative_id: creative.creative_id,
      name: creative.name,
      priority: creative.priority ?? 0,
      conditions,
    });
  }
}

function groupConditions(conditions) {
  const grouped_conditions = [[]];

  for (const condition of conditions) {
    // condition type is OR, so clone the current groups
    if (condition.relation_type === Object.keys(FLIGHT.SEPARATORS)[1]) {
      // clone the current groups
      const duplicated_groups = grouped_conditions.map(group => [...group]);

      // replace the last condition in each group with the new condition
      for (const group of duplicated_groups) {
        group.pop();
        group.push(condition);
      }

      // add the new groups to the current groups
      grouped_conditions.push(...duplicated_groups);
      continue;
    }

    for (const group of grouped_conditions) {
      group.push(condition);
    }
  }

  return grouped_conditions;
}
