<template lang="pug">
div.options-block(v-if="is_visible")
  template(v-if="item.type === 'toggle' || item.type === 'custom'")
    OptionsBlockRow(:label="item.title" :disabled="disabled" :tooltip="item.tooltip")
      template(v-if="['gesture', 'mobile', 'desktop'].includes(name)")
        Toggle(:on="gesture_default_on[name]" v-model="settings[name]" :disabled="disabled" @input="change")
      Toggle(v-else v-model="settings[name]" :disabled="disabled" @input="change")

    template(v-if="['gesture', 'mobile', 'desktop'].includes(name)")
      OptionsBlockRow(label="Gesture icon" :disabled="!settings[name]" tooltip="Scale and reposition the existing gesture icon or upload a custom gesture icon")
        CustomAsset(
          :currently_saved_asset_id="gesture_default_on[name] === 'custom-gesture' ? 'custom_gesture' : gesture_default_on[name]"
          :asset_id="`custom_gesture_${name}`"
          :creative="creative"
          :cropper_enabled="false"
          :editable="item.editable"
          :disabled="!settings[name]"
          @change="customAssetChangeHandler"
          @reset="gestureResetHandler")
    template(v-if="name === 'has_arrows'")
      OptionsBlockRow(label="Left control icon" :disabled="!settings[name]" tooltip="Upload or drag a custom control icon from Asset library. You can also scale and reposition the icon or reset to default.")
        CustomAsset(asset_id="custom_arrow_left" :creative="creative" :disabled="!settings[name]" @change="customAssetChangeHandler")
      OptionsBlockRow(label="Right control icon" :disabled="!settings[name]" tooltip="Upload or drag a custom control icon from Asset library. You can also scale and reposition the icon or reset to default.")
        CustomAsset(asset_id="custom_arrow_right" :creative="creative" :disabled="!settings[name]" @change="customAssetChangeHandler")

  template(v-else-if="item.type === 'input'")
    OptionsBlockRow(:key="name" :label="item.title" :tooltip="item.tooltip")
      input.form-control.bars-input(type="text" :placeholder="item.options.placeholder" v-model="settings[name]" @blur="change")

  template(v-else-if="item.type === 'slider'")
    OptionsBlockRow(:key="name" :label="item.title" :disabled="disabled" :tooltip="item.tooltip")
      Slider(v-model="settings[name]" :options="item.options" :disabled="disabled" @change="change")

    template(v-if="name === 'maxdrawtime'")
      OptionsBlockRow(label="Brush shape" tooltip="Upload or drag a custom brush shape from Asset library")
        CustomAsset(asset_id="custom_brush" :creative="creative" :cropper_enabled="false" :disabled="!settings[name]" @change="customAssetChangeHandler" @reset="gestureResetHandler")

  template(v-else-if="item.type === 'color'")
    OptionsBlockRow(:key="name" :label="item.title")
      ColorOption.py-8(v-model="settings[name]" @input="change")

  template(v-else-if="item.type === 'select'")
    OptionsBlockRow(:key="name" :label="item.title" :tooltip="item.tooltip")
      div.flex
        SearchSelect.flex-grow(:options="item.options" :disabled="disabled" :search="false" :url="item.url" v-model="settings[name]" @input="change")
        img(v-if="item.warning" :src="$cdn + 'dist/assets/cm/warning.svg'" alt="warning" v-tooltip="{value: item.warning}")

  template(v-else-if="item.type === 'radio-dropdown'")
    OptionsBlockRow(:key="name" :label="item.title" :tooltip="item.tooltip" align="top")
      template(v-for="(option, index) in item.options")
        RadioButtonDropdown(v-model="settings[name]" :key="index" :option="option" string_value="never" @input="change")

  template(v-else-if="item.type === 'radio'")
    OptionsBlockRow(:key="name" :label="item.title" :tooltip="item.tooltip")
      div.row
        label.col(v-for="(option, index) in item.options" :key="index")
          input(type="radio" :name="name" :value="option.value")
          span {{ option.label }}

  template(v-else-if="item.type === 'custom_attributes'")
    OptionsBlockRow(
      :key="name"
      :label="item.title"
      :wide="creative?.settings?.custom_attributes?.length > 0"
      :tooltip="item.tooltip"
    )
      div.flex.row-gap-8(
        :class="{'mt-8 ml-32 flex-align-end flex-direction-column': creative?.settings?.custom_attributes?.length > 0}"
      )
        div.flex.flex-align-center.relative(
          v-if="creative?.settings?.custom_attributes?.length > 0"
          v-for="(attribute, index) in creative.settings.custom_attributes"
          :key="index"
        )
          span.fs-14 data-
          input.form-control(v-model="attribute.key" @change="validateCustomAttributeKey(index)")
          span =
          input.form-control(v-model="attribute.value" @change="change")
          RemoveIcon(@click="removeCustomAttribute(index)")
        Button(
          type="link"
          :disabled="creative?.settings?.custom_attributes?.length >= 10"
          label="ADD"
          @click="addCustomDataAttribute"
        )
</template>

<script>
import RadioButtonDropdown from './RadioButtonDropdown.vue';
import CustomAsset from './CustomAsset.vue';
import OptionsBlockRow from './OptionsBlockRow.vue';

import Button from '@master/UI/Buttons/Button.vue';
import ColorOption from '@master/UI/ColorOption.vue';
import Slider from '@master/UI/Slider.vue';
import Toggle from '@master/UI/Toggle.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import RemoveIcon from '@master/UI/RemoveIcon.vue';

import { clone } from '@helpers/Global';

export default {
  name: 'OptionsBlock',
  components: {
    RadioButtonDropdown,
    CustomAsset,
    ColorOption,
    Slider,
    Toggle,
    SearchSelect,
    OptionsBlockRow,
    RemoveIcon,
    Button,
  },
  props: {
    creative: Object,
    settings: Object,
    type: {
      type: String,
      default: null,
    },
    item: Object,
    name: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    is_visible() {
      return this.item != null && (this.admin_toggle || this.item.visibility === 'all' || (this.item.visibility === 'feature' && this.$user.hasGroupFeature(this.name)));
    },

    gesture_default_on() {
      if (this.creative?.additional_assets?.custom_gesture != null && this.creative?.settings.gesture != null) {
        // backend given value for custom gesture
        // legacy gesture support
        let gesture_defaults = clone(this.creative.settings.gesture);
        if (this.creative.settings.gesture.desktop != null) {
          gesture_defaults.desktop = this.creative.settings.gesture.desktop ? this.creative.settings.gesture.desktop : 'custom-gesture';
        }
        if (this.creative.settings.gesture.mobile != null) {
          gesture_defaults.mobile = this.creative.settings.gesture.mobile ? this.creative.settings.gesture.mobile : 'custom-gesture';
        }
        return gesture_defaults;
      }

      if (this.type != null && this.creative?.layout?.settings) {
        let gesture_defaults = clone(this.creative.layout.settings.gesture);

        // overwrite gesture defaults if customs are uploaded
        if (this.creative?.assets?.custom_gesture_mobile != null) {
          // backend given value for custom gesture for mobile
          gesture_defaults.mobile = 'custom_gesture_mobile';
        }
        if (this.creative?.assets?.custom_gesture_desktop != null) {
          // backend given value for custom gesture for desktop
          gesture_defaults.desktop = 'custom_gesture_desktop';
        }

        return gesture_defaults;
      }

      if (this.type != null && this.creative?.template?.settings) {
        let gesture_defaults = clone(this.creative.template.settings.gesture);

        // overwrite gesture defaults if customs are uploaded
        if (this.creative?.additional_assets?.custom_gesture_mobile != null) {
          // backend given value for custom gesture for mobile
          gesture_defaults.mobile = 'custom_gesture_mobile';
        }
        if (this.creative?.additional_assets?.custom_gesture_desktop != null) {
          // backend given value for custom gesture for desktop
          gesture_defaults.desktop = 'custom_gesture_desktop';
        }

        return gesture_defaults;
      }

      return true;
    },
  },

  data() {
    return {
      admin_toggle: false,
      library_upload_data: null,
      gesture_name: '',
    };
  },

  created() {
    this.$user.subscribe(() => {
      this.$set(this, 'admin_toggle', this.$user.adminMode());
    }, this);
    this.saveGestureString();
  },

  methods: {
    change() {
      // handle layout related changes
      let update = {};
      let setting = this.name;
      if (this.type != null && ['desktop', 'mobile'].includes(this.name)) {
        setting = this.type;
        update = this.creative.settings[this.type];
        update[this.name] = this.settings[this.name];
      } else {
        update = this.settings[this.name];
      }

      this.saveGestureString();

      const path = `creative/${this.creative.creative_id}/settings/${setting}`;
      this.$http.put(path, { value: update }).then(response => {
        if (this.type != null) {
          if (response?.[this.type]?.[this.name] != null) {
            this.$set(this.creative.settings[this.type], this.name, response[this.type][this.name]);
          }
        } else if (response[this.name] != null) {
          this.$set(this.creative.settings, this.name, response[this.name]);
        }
        this.$emit('change');
        this.$forceUpdate();

        if (['gesture', 'auto_rotate'].includes(this.name)) {
          this.autoToggleChange(this.name);
        }
      });
    },

    saveGestureString() {
      if (typeof this.settings['gesture'] === 'string') {
        this.gesture_name = this.settings['gesture'];
      }
    },

    customAssetChangeHandler(reload_assets_library = false) {
      this.$emit('change', reload_assets_library);
      if (['desktop', 'mobile'].includes(this.name) && this.creative?.additional_assets?.custom_gesture != null) {
        const path = `creative/${this.creative.creative_id}/settings/gesture`;
        this.$http
          .get(path)
          .then(response => {
            if (response.gesture.desktop != null) {
              this.settings.desktop = response.gesture.desktop;
            }
            if (response.gesture.mobile != null) {
              this.settings.mobile = response.gesture.mobile;
            }
            this.$set(this.creative.settings, 'gesture', this.settings);
            const prev_custom_gesture = this.creative?.additional_assets?.custom_gesture;
            if (this.name === 'desktop' && this.settings.mobile != null && this.settings.mobile === 'custom_gesture_mobile') {
              prev_custom_gesture.asset_id = 'custom_gesture_mobile';
              this.$set(this.creative.additional_assets, 'custom_gesture_mobile', prev_custom_gesture);
              this.$delete(this.creative.additional_assets, 'custom_gesture');
            } else if (this.name === 'mobile' && this.settings.desktop != null && this.settings.desktop === 'custom_gesture_desktop') {
              prev_custom_gesture.asset_id = 'custom_gesture_desktop';
              this.$set(this.creative.additional_assets, 'custom_gesture_desktop', prev_custom_gesture);
              this.$delete(this.creative.additional_assets, 'custom_gesture');
            }
          })
          .catch(_ => {
            /** supress errors */
          });
      }
    },

    gestureResetHandler(response) {
      if (response.gesture != null) {
        this.$set(this.creative.settings, 'gesture', response.gesture);
      }
    },

    autoToggleChange(name) {
      // train layouts are exceptions of not allowing to enable gesture and auto rotate at the same time
      const templates_that_has_gestures_linked_width_auto_rotate = ['WpczFW', 'fPR885', 's3aQiJ'];

      if (!templates_that_has_gestures_linked_width_auto_rotate.includes(this.creative.template_id)) return;

      if (name === 'gesture') {
        // gesture true -> animation false
        if (this.settings.gesture !== false && this.settings.auto_rotate) {
          this.$set(this.creative.settings, 'auto_rotate', false);
        }
      }

      if (name === 'auto_rotate') {
        // animation true -> gesture false
        if (this.settings.auto_rotate && this.settings.gesture !== false) {
          if (this.type != null) {
            this.$set(this.creative.settings, 'gesture', false);
          } else {
            this.$set(this.creative.settings, 'gesture', { mobile: false, desktop: false });
          }
        }

        // animation off -> gesture true
        if (!this.settings.auto_rotate) {
          if (this.type != null) {
            this.$set(this.creative.settings, 'gesture', this.gesture_default_on);
          } else {
            this.$set(this.creative.settings, 'gesture', { mobile: this.gesture_default_on.mobile, desktop: this.gesture_default_on.desktop });
          }
        }
      }

      const update = name === 'gesture' ? 'auto_rotate' : 'gesture';
      const auto_value = this.settings[update];
      const path = `creative/${this.creative.creative_id}/settings/${update}`;

      this.$http.put(path, { value: auto_value }).then(() => {
        this.$emit('change');
        this.$forceUpdate();
      });
    },

    addCustomDataAttribute() {
      if (this.creative?.settings?.custom_attributes == null) {
        this.$set(this.creative.settings, 'custom_attributes', []);
      }

      this.creative.settings.custom_attributes.push({
        key: '',
        value: '',
      });

      this.change();
    },

    removeCustomAttribute(index) {
      this.creative.settings.custom_attributes.splice(index, 1);
      this.change();
    },

    validateCustomAttributeKey(index) {
      const item = this.creative.settings.custom_attributes[index];

      this.creative.settings.custom_attributes.splice(index, 1, {
        ...item,
        key: item.key.replace(/\s/g, '-'),
      });

      this.change();
    },
  },
};
</script>
