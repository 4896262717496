export default class Layer {
  #reserved = [];
  constructor(width, height) {
    this.width = width;
    this.height = height;

    /** @type {import('./Drawable')[]} */
    this.assets = [];
  }

  /**
   * @param {import('./Drawable')} drawable
   * @param {boolean} check_reserved_spot
   */
  addAsset(drawable, check_reserved_spot = true) {
    if (check_reserved_spot && this.#isReservedPosition(drawable)) {
      return null;
    }
    this.assets.push(drawable);
    this.reserveAssetPosition(drawable.x, drawable.y);
    return drawable;
  }

  reserveAssetPosition(x, y) {
    this.#reserved.push({ x, y });
  }

  /**
   * @param {import('./Drawable')} drawable
   */
  #isReservedPosition(drawable) {
    // never reserve overlays
    if (this.name === 'Overlay') {
      return false;
    }
    return this.#reserved.find(pos => drawable.x === pos.x && drawable.y === pos.y) != null;
  }
}
