<template lang="pug">
div.form-group.mb-0.flex.flex-direction-column.row-gap-4
  TextareaInput(v-if="['url', 'lightbox'].includes(cta.action)" v-model="cta.uri" :placeholder="cta_output_placeholder" url @change="change")
  Input(v-if="['call', 'sms'].includes(cta.action)" :key="cta.action" v-model="cta.uri" :placeholder="cta_output_placeholder" variant="phone" @change="change")
  Input(v-if="cta.action === 'mailto'" :key="cta.action" v-model="cta.uri" :placeholder="cta_output_placeholder" @change="change")
  TextareaWithCounter(v-if="cta.action === 'sms'" v-model="cta.sms_body" :max_char="140" isInput :placeholder="sms_body_placeholder" @change="update")

  template(v-if="cta.action === 'calendar'")
    TextareaInput(:placeholder="cta_output_placeholder" v-model="cta.title" :url="false" @change="change")
    div
      span.color-gray-800 Description:
      TextareaInput.mt-4(placeholder="Enter description" v-model="cta.description" :url="false" @change="change")
    div
      span.color-gray-800 Location:
      TextareaInput.mt-4(placeholder="Enter address or link" v-model="cta.location" :url="false" @change="change")
    div
      span.color-gray-800 Date and time:
      SelectDateRange.mt-4(
        :start="cta.start"
        :end="cta.end"
        :timezone="cta.timezone"
        :placeholder="cta.start | DateRangeFilter(cta.end, {utc: true})"
        :options="{ time: true }"
        style="width: max-content;"
        @change="calendarChangeHandler"
      )

  template(v-if="cta.action === 'map'")
    TagInputLocations(ref="tag_input_locations" @add="addLocationHandler")
    div.mt-8(v-if="cta.uri.length > 0")
      span.color-gray-800 Map locations:
      div.location-row(v-for="(location, index) in cta.uri" :key="index")
        div.flex-grow.overflow-ellipsis {{location.address}}
        i.nexd-icon-32-close.fs-16.color-danger.cursor-pointer(aria-hidden="true" @click="removeLocation(index)")
    div.mt-8.flex.flex-align-center.column-gap-16
      span.color-gray-800 Focus on the closest:
      Toggle(v-model="cta.focus_closest" @input="update")
</template>

<script>
import { validateURL, validatePhone } from '@helpers/Global';

import Input from '@master/UI/Input/Input.vue';
import SelectDateRange from '@master/UI/SearchSelect/SelectDateRange.vue';
import TagInputLocations from '@master/UI/TagInputLocations.vue';
import TextareaWithCounter from '@master/UI/TextareaWithCounter.vue';
import Toggle from '@master/UI/Toggle.vue';

import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput.vue';

export default {
  name: 'CTAInputs',

  components: {
    Input,
    SelectDateRange,
    TagInputLocations,
    TextareaInput,
    TextareaWithCounter,
    Toggle,
  },

  props: {
    value: {
      type: Object,
      required: true,
      validator(value) {
        return 'action' in value;
      },
    },
  },

  computed: {
    cta_output_placeholder() {
      switch (this.cta.action) {
        case 'url':
          return 'Insert click-out URL (landing page URL, click-tracker or click tag)';
        case 'lightbox':
          return 'Insert page URL';
        case 'call':
        case 'sms':
          return '+155512345';
        case 'calendar':
          return 'Insert title';
        case 'mailto':
          return 'Insert email address';
        default:
          return '';
      }
    },
  },

  data() {
    return {
      cta: this.value,
      sms_body_placeholder: 'Insert predefined SMS body here. Max 140 characters',
    };
  },

  methods: {
    update() {
      this.$emit('update', this.value);
    },

    change() {
      if (['url', 'lightbox'].includes(this.cta.action) && this.cta.uri !== '') {
        this.cta.uri = validateURL(this.cta.uri);
      }

      if (['call', 'sms'].includes(this.cta.action) && this.cta.uri !== '') {
        this.cta.uri = validatePhone(this.cta.uri);
      }

      this.update();
    },

    addLocationHandler(location) {
      if (!Array.isArray(this.cta.uri)) {
        this.$set(this.cta, 'uri', []);
      }
      this.cta.uri.push(location);
      this.$refs['tag_input_locations'].focusInput();
      this.update();
    },

    removeLocation(index) {
      this.cta.uri.splice(index, 1);
      this.update();
    },

    calendarChangeHandler({ start, end, timezone }) {
      this.$set(this.cta, 'start', start);
      this.$set(this.cta, 'end', end);
      this.$set(this.cta, 'timezone', timezone);
      this.update();
    },
  },

  watch: {
    value: {
      handler() {
        this.cta = this.value;
      },
      deep: true,
    },
  },
};
</script>
