import Slot from './Slot';
import AdditionalHandler from './AdditionalHandler';

export default class MainHandler {
  #background;
  #creative;

  // used for additional feed assets
  #first_feed_asset = null;

  /**
   * @param {import('./Background')} background
   */
  constructor(background) {
    this.#background = background;
    this.#creative = this.#background.getCreative();

    /**
     * @type {Object.<string, Slot[]>}
     */
    this.slots = {
      Background: [],
      Assets: [],
      Overlay: [],
    };

    this.additional = new AdditionalHandler(this);
  }

  getCreative() {
    return this.#creative;
  }

  /**
   * @returns {import('./Background')}
   */
  getBackground() {
    return this.#background;
  }

  setFirstFeedAsset(first_feed_asset) {
    // only once
    if (this.#first_feed_asset) return;
    this.#first_feed_asset = first_feed_asset;
  }

  getFirstFeedAsset() {
    return this.#first_feed_asset;
  }
}
