<template lang="pug">
div(:class="[styles.tabs, { [styles.disabled]: disabled, [styles.borderless]: value == null }]")
  template(v-for="(label, tab, index) in tabs")
    i.nexd-icon-16-arrow-right-small.color-gray-800.cursor-default(v-if="steps && index > 0")
    div(:class="[styles.tab, { [styles.active]: value === tab }]" @click="changeTab(tab)") {{ label }}
</template>

<script>
import styles from './Tabs.module.scss';

export default {
  name: 'Tabs',

  props: {
    value: {
      type: [String, Number],
      default: null,
    },

    tabs: {
      type: Object,
      default: () => ({}),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    stateless: {
      type: Boolean,
      default: false,
    },

    steps: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      styles,
    };
  },

  methods: {
    changeTab(tab) {
      if (this.disabled) {
        return;
      }

      if (this.stateless && this.value === tab) {
        this.$emit('input', null);
      } else {
        this.$emit('input', tab);
      }
    },
  },
};
</script>
