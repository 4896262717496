<template lang="pug">
div
  CampaignCreativeNotFound(v-if="flight_access_denied" type="access")
  CampaignCreativeNotFound(v-else-if="flight_not_found" type="flight")
  DCOSelection(v-else-if="flight_new")
  Loading(v-else-if="flight == null")

  template(v-else)
    FlightNavbar(@export="show_overlay = true")

    div(v-content)
      Breadcrumbs.mb-16(:items="breadcrumbs")

      div(v-if="is_dco && isExported()" :class="styles.content")
        DCOPriorityChart

      div(:class="styles.content")
        Card#flight.pt-16.pb-24.relative(v-if="flight != null" :main="true")
          FlightHeader

          CardDivider.mt-32

          template(v-if="is_dco")
            DCOOptimization

          section.mt-32.px-24(v-else)
            div.mb-32.flex.flex-justify-between
              div
                div.fw-500.mb-8 Conditions
                div.fs-14.color-gray-800
                  div.mb-8 These settings will determine which of your creatives will be shown.
                  a(href="https://support.nexd.com/en/articles/7959007-release-notes-flights-june-2023" target="_blank") How to set up the rules
              Button(type="primary" label="Import flight data" @click="openImportModal")

            FlightStatements

          div(v-if="saving || isImportInProgress() || show_overlay" :class="styles.overlay")
            span(v-if="isImportInProgress()") Importing the flight is still in progress
            span(v-else-if="saving") Please wait, this flight is being saved
            template(v-else-if="show_overlay")
              span
                | Use caution when editing published items! Don't forget to click the
                | Update Live button, or download new tags if needed, to push any changes live.
              Button(type="primary" label="Click to edit" @click="show_overlay = false")
        CreativeLibrary(v-sticky-content :disabled="show_overlay")

  FlightImportModal(v-if="show_import_modal" @close="closeImportModal")
</template>

<script>
import styles from './Flight.module.scss';

import FlightService from '@master/Services/FlightService';
import SaveService from '@master/Services/SaveService';
import FlightTraits from '@master/Traits/FlightTraits.vue';
import { FLIGHTS } from '@master/constants';

import Breadcrumbs from '@master/UI/Breadcrumbs/Breadcrumbs.vue';
import Card from '@cm/Views/Flights/Components/Card.vue';
import CreativeDisabledOverlay from '@cm/Views/Creatives/Creative/CreativeDisabledOverlay.vue';
import CreativeLibrary from '@cm/Views/Flights/Components/CreativeLibrary/CreativeLibrary.vue';
import DCOOptimization from '@cm/Views/Flights/Components/DCOOptimization.vue';
import DCOPriorityChart from '@cm/Views/Flights/Components/DCOPriorityChart.vue';
import DCOSelection from '@cm/Views/Flights/Components/DCOSelection.vue';
import FlightNavbar from '@cm/Views/Flights/Components/FlightNavbar.vue';
import FlightHeader from '@cm/Views/Flights/Components/FlightHeader/FlightHeader.vue';
import FlightImportModal from '@cm/Views/Flights/Components/FlightImportModal.vue';
import FlightStatements from '@cm/Views/Flights/Components/FlightStatements/FlightStatements.vue';

import Button from '@master/UI/Buttons/Button.vue';
import CampaignCreativeNotFound from '@master/UI/CampaignCreativeNotFound.vue';
import CardDivider from '@master/UI/Divider/CardDivider.vue';
import Loading from '@master/UI/Loading.vue';

export default {
  name: 'Flight',
  mixins: [FlightTraits],

  components: {
    Breadcrumbs,
    Button,
    CampaignCreativeNotFound,
    Card,
    CardDivider,
    CreativeDisabledOverlay,
    CreativeLibrary,
    DCOOptimization,
    DCOSelection,
    FlightHeader,
    FlightImportModal,
    FlightNavbar,
    FlightStatements,
    Loading,
    DCOPriorityChart,
  },

  computed: {
    has_creatives() {
      if (this.flight?.statements == null) {
        return false;
      }

      const getCreatives = statements => {
        if (!statements?.length) return [];

        for (const statement of statements) {
          if (statement?.creatives?.length > 0) return null;
          return getCreatives(statement?.statements ?? []);
        }

        return [];
      };

      return getCreatives(this.flight?.statements ?? []) == null;
    },

    is_dco() {
      return this.flight?.type === FLIGHTS.TYPE.TYPE_DCO;
    },
  },

  data() {
    return {
      styles,

      breadcrumbs: undefined,
      flight: null,
      flight_not_found: false,
      flight_access_denied: false,
      flight_new: false,

      saving: false,
      unsaved_changes_warning: 'You have unsaved changes. Are you sure you wish to leave?',
      show_import_modal: false,
      show_overlay: false,
    };
  },

  created() {
    SaveService.flight.subscribe(({ saving }) => {
      this.saving = saving;
    }, this);

    this.loadFlight();
    FlightService.subscribe(data => {
      this.breadcrumbs = data?.breadcrumbs;
      this.flight = data?.flight;
      this.flight_not_found = data?.not_found ?? false;
      this.flight_access_denied = data?.access_denied ?? false;
      this.flight_new = data?.new ?? false;

      this.setupSidebar();
    }, this);

    window.addEventListener('beforeunload', this.beforeunload);
  },

  destroyed() {
    window.removeEventListener('beforeunload', this.beforeunload);
  },

  methods: {
    async loadFlight() {
      await FlightService.load();

      if (this.flight && this.isExported()) {
        this.show_overlay = true;
      }
    },

    async setupSidebar() {
      await this.$nextTick();

      if (!this.$sidebar) return;

      const campaign_id = this.$route?.params?.campaign_id;
      const flight_id = this.$route?.params?.flight_id;

      if (this.breadcrumbs && this.breadcrumbs.length > 1) {
        const breadcrumb = this.breadcrumbs[this.breadcrumbs.length - 2];
        this.$sidebar.setBack(breadcrumb.label, `/c/${campaign_id}`);
      } else {
        this.$sidebar.setBack('Campaigns list', '/');
      }

      this.$sidebar.setCreatives(null, `/c/${campaign_id}`);
      this.$sidebar.setFlight({
        visible: true,
        url: `/c/${campaign_id}/flights/${flight_id}`,
      });

      if (this.flight?.analytics_url != null) {
        this.$sidebar.setAnalytics(this.flight?.analytics_url);
      } else if (this.isPublished() && flight_id != null) {
        this.$sidebar.setAnalytics(`/analytics/f/${flight_id}`);
      }
    },

    openImportModal() {
      this.show_import_modal = true;
    },

    closeImportModal() {
      this.show_import_modal = false;
    },

    beforeunload(e) {
      if (this.flight?.updated_on > SaveService.flight.getUnix()) {
        e.returnValue = this.unsaved_changes_warning;
        return this.unsaved_changes_warning;
      }
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (this.flight?.updated_on > SaveService.flight.getUnix()) {
      if (await this.$confirm(this.unsaved_changes_warning)) {
        next();
      }
    } else {
      next();
    }
  },
};
</script>
