<template lang="pug">
div.relative(
  @dragenter.prevent.stop="onDragEnter"
  @dragover.prevent.stop
  @dragleave.prevent.stop="onDragLeave"
  @drop.prevent.stop="onDrop"
)
  div.asset-upload-wrapper.custom-upload-wrapper(ref="gesture")
    Thumbnail(:asset="{image_uri: thumbnail}" :variant="thumbnail != null ? 'nobgimage' : null" :small="true" bg="light")
      template(v-if="active_original_file && original_files.length > 1 && editable")
        div.dropdown-indicator(@click="openFileList")
          i.nexd-icon-16-arrow-down
        div.dropdown(v-if="file_list_opened" v-on-clickaway="closeFileList")
          Thumbnail(
            v-for="(file, index) of original_files"
            :key="index"
            :asset="{image_uri: file.blob}"
            :variant="file.blob != null ? 'nobgimage' : null"
            bg="light"
            :small="true"
            :dropdown="true"
            @click="selectFileFromList(file)"
          )
    div.asset-controls.flex-grow.text-right(v-if="editable")
      i.nexd-icon-16-upload(:class="{'disabled': disabled}" @click="selectFileHandler" v-tooltip="{value: `Upload your own image (${accepted_input_extensions})`}")
      i.nexd-icon-16-edit(v-if="show_edit" :class="{'disabled': disabled || !thumbnail}" @click="editCustomAsset" v-tooltip="{value: 'Edit'}")
      i.nexd-icon-16-counter-clockwise(v-if="has_collection" :class="{'disabled': disabled || !can_reset}" v-tooltip="{value: disabled ? null : 'Take back'}" @click="resetCustomAsset")
    input(ref="custom-file-input" type="file" :accept="accepted_input_extensions" @change="inputFileChangeHandler")
    div.disabled-overlay(v-if="disabled")
  div(v-if="loading != null || dropbox_visible" :class="styles.overlay_content" :data-content="loading ?? overlay_text")
</template>

<script>
import styles from '@cm/Views/Creatives/Cards/Components/Asset/Slot/Slot.module.scss';

import CustomAssetService from '@master/Services/Cache/CustomAssetService';
import AssetHelper from '@helpers/Asset';
import Upload from '@helpers/Upload';

import AssetsLibraryService from '@master/Services/AssetsLibraryService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import AssetTraits from '@cm/Views/Creatives/Traits/AssetTraits.vue';
import Thumbnail from '@master/UI/Thumbnail/Thumbnail.vue';

import { mixin as clickaway } from 'vue-clickaway';
import { SIZE, CUSTOM_ASSET } from '@master/constants';

export default {
  name: 'CustomAsset',

  mixins: [CreativeTraits, clickaway, AssetTraits],

  components: {
    Thumbnail,
  },

  props: {
    creative: Object,
    currently_saved_asset_id: {
      type: String,
      default: null,
    },
    asset_id: String,
    disabled: Boolean,

    // if edit, reset etc buttons are visible
    editable: {
      type: Boolean,
      default: true,
    },

    // if cropper is disabled, image will be auto cropped without any resize
    cropper_enabled: {
      type: Boolean,
      default: true,
    },

    // when auto crop ratio matches, asset will be resized to this size
    auto_crop_size: Number,

    has_collection: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    // custom asset exist if its uploaded
    custom_asset() {
      if (this.creative?.additional_assets?.[this.asset_id] != null) {
        return this.creative.additional_assets[this.asset_id];
      }
      if (this.creative?.assets?.[this.asset_id] != null) {
        return this.creative.assets[this.asset_id];
      }
      if (this.currently_saved_asset_id != null && this.creative?.additional_assets?.[this.currently_saved_asset_id] != null) {
        return this.creative.additional_assets[this.currently_saved_asset_id];
      }
      return null;
    },

    is_layout_gesture() {
      return this.asset_id === 'custom_gesture_mobile' || this.asset_id === 'custom_gesture_desktop';
    },

    // can reset only if theres any change
    can_reset() {
      return this.custom_asset != null;
    },

    // thumbnail can be the original custom file or uploaded file
    thumbnail() {
      const uploaded_asset = this.custom_asset;
      if (uploaded_asset != null) {
        return uploaded_asset.image_uri;
      } else if (this.active_original_file != null) {
        return this.active_original_file.blob;
      }
      return null;
    },

    effects_block() {
      return AssetHelper.isCustomEffect(this.asset_id);
    },

    show_edit() {
      // some assets are not editable, but are allowed to have new assets to dragg n dropped
      const assets_not_editable = [CUSTOM_ASSET.BRUSH, CUSTOM_ASSET.LIGHTBOX_CLOSE];
      return !this.effects_block && !assets_not_editable.includes(this.asset_id);
    },

    is_splitscreen_carousel() {
      if (!this.creative?.template?.template_base) return false;
      return this.creative.template.template_base.startsWith('splitCarouselVideo');
    },

    assets_library() {
      return this.$store.get('active_assets_library');
    },

    overlay_text() {
      return 'Drop your asset here';
    },
  },

  data() {
    const accepted_mimes = AssetHelper.getImageMimes().join(', ');
    const accepted_input_extensions = AssetHelper.mimeToExtension(accepted_mimes);
    return {
      Cropper: this.$cropper(),
      styles,

      accepted_mimes,
      accepted_input_extensions,
      original_files: null,
      active_original_file: null,
      file_list_opened: false,

      // on custom file select, cropper UI will be skipped once
      skip_cropping: false,

      dropbox_visible: false,
      library_upload_data: null,

      loading: null,

      initial_edit_on_default: false,
    };
  },

  created() {
    if (!this.hasLORT()) return;

    let path;
    if (this.creative.layout_id != null) {
      path = `v2/layouts/${this.creative.layout_id}`;
    } else if (this.creative.template_id != null) {
      path = `templates/${this.creative.template_id}`;
    } else {
      return;
    }

    path += `/files/${this.asset_id}`;

    // by default all custom assets are globally shared and the same
    // so we can just use cached info by the id
    let cache_key = this.asset_id;
    // templates and layouts have different keys so use unique identifier
    // actually not in use right now, but for future purposes
    if (AssetHelper.isCustomGesture(this.asset_id)) {
      cache_key = path;
    }

    if (AssetHelper.isCustomHotspotPin(this.asset_id) || AssetHelper.isCustomHotspotClose(this.asset_id)) {
      // remove the slot ID from the asset ID to make it generic and reusable via cache
      const parts = this.asset_id.split('_');
      parts.splice(2, 1);
      cache_key = parts.join('_');
    }

    const collection = CustomAssetService.get(cache_key);
    this.original_files = collection.get();
    this.active_original_file = collection.first();

    // files were cached
    if (this.original_files.length > 0 || !this.has_collection) return;

    // load files to collection
    this.$http.get(path, { notification: false }).then(response => {
      let source = response;
      if (this.is_layout_gesture) {
        if (this.asset_id === 'custom_gesture_mobile') {
          source = source.mobile;
        } else {
          source = source.desktop;
        }
        source = source.concat(response.additional);
      }

      for (const item of source) {
        collection.add(item);
      }

      this.original_files = collection.get();
      this.active_original_file = collection.first();
    });
  },

  methods: {
    onDragEnter() {
      if (this.disabled) return;
      this.dropbox_visible = true;
    },

    onDrop(e) {
      if (this.disabled) return;

      const original_data = e.dataTransfer.getData('application/original-asset');
      if (original_data != null && original_data !== '') {
        this.library_upload_data = JSON.parse(original_data);
        if (AssetHelper.isAcceptedMime(this.accepted_mimes, this.library_upload_data.mime)) {
          this.fromAssetLibrary();
        } else {
          this.showAssetError();
        }
      } else if (this.$refs['custom-file-input'] != null) {
        // asset dropped
        const file = e.dataTransfer.files[0];
        const file_list = new DataTransfer();
        for (let file_item of e.dataTransfer.files) {
          file_list.items.add(file_item);
        }

        if (file != null) {
          if (AssetHelper.isAcceptedMime(this.accepted_mimes, file.type, file.name)) {
            this.$refs['custom-file-input'].files = file_list.files;
            this.$refs['custom-file-input'].dispatchEvent(new Event('change'));
          } else {
            this.showAssetError();
          }
        } else {
          this.showAssetError();
        }
      }
      this.dropbox_visible = false;
    },

    onDragLeave(event) {
      if (this.disabled) return;

      if (!event.currentTarget.contains(event.relatedTarget)) {
        this.dropbox_visible = false;
      }
    },

    selectFileHandler() {
      this.$refs['custom-file-input'].click();
    },

    fromAssetLibrary() {
      this.initCroppingTool();
      this.Cropper.newFromURL(this.library_upload_data.uri);

      this.Cropper.onsave = settings => {
        const obj = {
          original_asset_id: this.library_upload_data.original_asset_id,
          crop: true,
          settings,
        };
        this.upload(obj, { type: this.library_upload_data.mime, width: this.library_upload_data.width, height: this.library_upload_data.height });
      };
    },

    editCustomAsset(options = null) {
      if (!options) {
        options = {};
      }

      if (options.overwrite == null) {
        options.overwrite = false;
      }

      if (this.custom_asset != null && !options.overwrite) {
        this.initCroppingTool(true);
        this.Cropper.onsave = settings => {
          const path = `creatives/${this.creative.creative_id}/assets/${this.currently_saved_asset_id ?? this.asset_id}`;
          this.$http.put(path, { settings }).then(response => {
            this.$set(this.creative.additional_assets, this.currently_saved_asset_id ?? this.asset_id, response);
            this.$emit('change');
          });
        };
        this.Cropper.editAsset(this.custom_asset.original_url, this.custom_asset.settings);
      } else if (this.active_original_file?.file != null) {
        this.initial_edit_on_default = true;
        this.inputFileChangeHandler({
          target: {
            files: [this.active_original_file.file],
          },
        });
      }
    },

    resetCustomAsset() {
      this.$delete(this.creative.additional_assets, this.asset_id);
      this.loading = 'Resetting';

      let path;
      if (this.creative.layout_id != null) {
        path = `v2/creative/${this.creative.creative_id}/assets/${this.asset_id}/reset`;
      } else if (this.creative.template_id != null) {
        path = `creatives/${this.creative.creative_id}/assets/${this.currently_saved_asset_id ?? this.asset_id}/reset`;
      }

      this.$http
        .put(path, {})
        .then(response => {
          // set active as the 1st after reset
          this.active_original_file = this.original_files[0];
          this.$emit('reset', response);
        })
        .finally(() => {
          this.loading = null;
        });
    },

    inputFileChangeHandler(e) {
      this.initCroppingTool();

      this.Cropper.onsave = settings => {
        const file = this.Cropper.getFile();
        this.saveFile(file, settings);
      };
      this.Cropper.inputChangeHandler(e);
    },

    saveFile(file, settings = null) {
      this.loading = 'Uploading';
      const handler = new Upload(file);
      handler.upload(({ done, upload_id, error }) => {
        if (done) {
          const obj = {
            upload_id: upload_id,
            crop: true,
            hidden: this.initial_edit_on_default,
            settings,
          };
          this.upload(obj);
        }

        if (error?.message != null) {
          this.loading = null;
          this.$alert(error.message);
        }
      });
    },

    upload(obj) {
      let options = {
        notification: true,
      };

      // disable upload notification when uploading library asset
      if (this.library_upload_data != null) {
        options.notification = false;
      }

      this.loading = 'Optimizing';
      const path = `creatives/${this.creative.creative_id}/assets/${this.asset_id}`;
      this.$http
        .post(path, obj, options)
        .then(response => {
          this.$set(this.creative.additional_assets, this.asset_id, response);
          // send info if we should reload assets library or not
          // if we drag from library its not needed, any other upload the file should appear in lib
          this.$emit('change', this.library_upload_data == null);

          if (this.creative.assets_library_id != null) {
            AssetsLibraryService.load();
          }
        })
        .finally(_ => {
          this.loading = null;
          this.library_upload_data = null;
          this.initial_edit_on_default = false;
        });
    },

    initCroppingTool(user_editing = false) {
      const sizes = AssetHelper.getSize(this.creative);
      const offset = { x: 0, y: 0 };

      if (AssetHelper.isCustomArrow(this.asset_id) && this.creative.$arno) {
        // splitscreen carousel arrows are related to 1st asset
        const { y, h } = this.creative.$arno.getSize('1');
        sizes.dimensions.height = h;
        offset.y = y;
      }

      const cropper_options = {
        cropSize: sizes.dimensions,
        achor: 'center',
        position: 'absolute',
        grid: 16,
        scale: 2, // most custom assets are x2 svg-s
        clip: {
          horizontal: true,
          vertical: true,
        },
        placement: {
          size: sizes.total,
          adsize: sizes.total,
          offset,
        },
        wrapper: {
          size: sizes.wrapper,
        },
      };

      // gesture svgs are not x2
      if (AssetHelper.isCustomGesture(this.asset_id)) {
        cropper_options.scale = 1;
      }
      if (this.isMobile()) {
        cropper_options.scale *= SIZE.FW.INTERSTITIAL.WIDTH / this.creative.width;
      }

      // MVP hardcode reset positions for assets
      if (AssetHelper.isCustomClose(this.asset_id) || AssetHelper.isCustomHotspotClose(this.asset_id)) {
        cropper_options.reset_behaviour = ['top', 'right'];
      } else if (AssetHelper.isCustomArrowLeft(this.asset_id)) {
        cropper_options.reset_behaviour = ['left'];
      } else if (AssetHelper.isCustomArrowRight(this.asset_id)) {
        cropper_options.reset_behaviour = ['right'];
      }

      if (this.auto_crop_size) {
        cropper_options.cropSize.width = this.auto_crop_size;
        cropper_options.cropSize.height = this.auto_crop_size;
      }

      this.Cropper.init(cropper_options);
      this.Cropper.beforeShow = null;

      // if its dragged in from library or file input, its a new asset and not an original edit
      const new_asset = !this.initial_edit_on_default;

      // if new asset is being opened, check asp  ect ratio of it and scale it to match custom gesture size
      this.Cropper.beforeShow = (done, cropper, crop) => {
        if ((!new_asset && !this.skip_cropping) || user_editing) {
          return this.customAssetBackgroundHandler(done, this.asset_id);
        }
        // skip cropping with default position and size
        if (this.skip_cropping) {
          this.skip_cropping = false;

          if (!this.custom_asset) {
            // reset custom position and size, if no current assets has been uploade
            cropper.editor.reset();
          } else {
            // import current asset settings to reposition asset
            cropper.import(this.custom_asset.settings);
          }
          return crop();
        }

        // if cropper is not enabled, auto crop with original sizes
        // if custom file is selected from list, cropping will be skipped
        if (!this.cropper_enabled || this.skip_cropping) {
          const source_layer = cropper.editor.SourceLayer.size;
          const crop_layer = cropper.editor.CropLayer.size;

          if (this.effects_block || source_layer.width > crop_layer.width || source_layer.height > crop_layer.height) {
            // if added asset is bigger than the placement, fit the asset to the croparea, keeping the asset form being cut into half
            cropper.editor.fit();
          } else {
            // note backend crops overlays as x2, so we use half size of the original
            cropper.editor.InteractionLayer.resize(source_layer.width * 0.5, source_layer.height * 0.5);
            // beauty standards, keep it center 0,0 after resize
            cropper.editor.centerLayers();
          }
          return crop();
        }

        if (this.auto_crop_size) {
          cropper.editor.cover();
          return crop();
        }

        return this.customAssetBackgroundHandler(done, this.asset_id);
      };
    },

    customAssetBackgroundHandler(done, active_asset_id, vnode = this) {
      if (this.isQuantum() || this.singleLayerAsset(active_asset_id) || !this.has_collection) {
        return done();
      }

      this.Cropper.createCropperBackground(
        vnode,
        layers => {
          this.Cropper.addCropperBackgroundLayers(layers, this.asset);
          done();
        },
        active_asset_id,
      );
    },

    showAssetError() {
      this.$alert('Please check the list of permitted file types and try again.', 'Sorry, that file type is not accepted');
    },

    openFileList() {
      this.file_list_opened = true;
    },

    closeFileList() {
      this.file_list_opened = false;
    },

    selectFileFromList(file) {
      this.active_original_file = file;
      this.closeFileList();
      this.skip_cropping = true;

      // call edit as it would be new asset to be uploaded
      this.editCustomAsset({ overwrite: true });
    },
  },
};
</script>
