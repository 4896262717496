<template lang="pug">
div(
  :class="class_names"
  :draggable="draggable"
  @dragstart="onDragStart"
  @dragend="onDragEnd"
  @click="click"
)
  div
    div.relative(
      :class="styles.content"
      @dragenter.prevent.stop="onDragEnter"
      @dragover.prevent.stop
      @dragleave.prevent.stop="onDragLeave"
      @drop.prevent.stop="drop"
    )
      Loading.small(v-if="loading" :drop="true" :fill="true" :text="loading_text ?? 'Swapping'")
      div(v-else-if="show_overlay" :class="overlay_class_names" :data-content="overlay_text")

      section(:class="styles.input")
        slot(name="input")

      section(:class="styles.thumbnail")
        slot(name="thumbnail")

      section(:class="[styles.first_row, additional ? styles.additional : '']")
        slot(name="first_row")

      section(:class="styles.icon")
        slot(name="icon")

      section(:class="styles.second_row")
        slot(name="second_row")

      section(:class="styles.footer")
        div(:class="styles.third_row")
          slot(name="third_row")

        div(:class="styles.fourth_row")
          slot(name="fourth_row")

        div(:class="styles.icons" v-if="!empty")
          slot(name="icons")

  slot(name="settings")
</template>

<script>
import styles from './Slot.module.scss';

import { ASSET } from '@master/constants';

import Loading from '@master/UI/Loading.vue';

export default {
  name: 'SlotContainer',

  components: {
    Loading,
  },

  props: {
    asset: {
      type: Object,
      required: true,
    },

    draggable: {
      type: Boolean,
      default: false,
    },

    empty: {
      type: Boolean,
      default: false,
    },

    additional: {
      type: Boolean,
      default: false,
    },

    loading_text: {
      type: String,
      default: null,
    },

    show_swap_overlay: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    class_names() {
      const class_names = [styles.slot];

      if (this.empty) class_names.push(styles.empty);
      if (this.show_overlay) class_names.push(styles.overlay);

      return class_names;
    },

    overlay_class_names() {
      const class_names = [styles.overlay_content];
      if (this.is_swap_target) class_names.push(styles.drag_over);
      return class_names;
    },

    doing_swapping_request() {
      return this.$store.get('swapping_asset_ids').includes(this.asset?.asset_id);
    },

    swapping_asset() {
      return this.$store.get('swapping_asset');
    },

    swapping_asset_in_progress() {
      return this.$store.get('swapping_asset_in_progress');
    },

    is_swapping_self() {
      return this.swapping_asset?.asset_id === this.asset?.asset_id;
    },

    show_overlay() {
      if (this.swapping_asset_in_progress && !this.show_swap_overlay) {
        return false;
      }

      if (this.is_swapping_self || this.doing_swapping_request) {
        return false;
      }

      return this.overlay || this.swapping_asset_in_progress;
    },

    overlay_text() {
      if (this.swapping_asset_in_progress) {
        return 'Drop your asset here to swap';
      }

      return 'Drop your asset here';
    },

    loading() {
      return this.loading_text != null || this.doing_swapping_request;
    },
  },

  data() {
    return {
      styles,
      is_swap_target: false,
      overlay: false,
    };
  },

  methods: {
    click(e) {
      if (this.overlay) {
        e.stopPropagation();
        e.preventDefault();
      }
    },

    drop(event) {
      if (!this.loading) {
        this.$emit('drop', event);
      }
      this.overlay = false;
      this.is_swap_target = false;
    },

    onDragEnter() {
      if (this.loading) return;

      if (!this.overlay) {
        this.overlay = true;
      }

      this.is_swap_target = true;
    },

    onDragLeave(event) {
      if (this.loading) return;

      if (!event.currentTarget.contains(event.relatedTarget)) {
        this.overlay = false;
      }
      this.is_swap_target = false;
    },

    onDragStart(e) {
      if (!this.draggable) return;

      this.$store.set('swapping_asset_in_progress', true);

      e.dataTransfer.setData('action', 'slot-swap');
      e.dataTransfer.setData('application/original-asset', JSON.stringify(this.asset));

      this.$store.set('swapping_asset', {
        type: this.asset?.type ?? this.asset?.custom_type ?? ASSET.NORMAL,
        width: this.asset?.width,
        height: this.asset?.height,
        asset_id: this.asset?.asset_id,
      });
    },

    onDragEnd() {
      this.$store.reset('swapping_asset_in_progress');
      this.$store.reset('swapping_asset');
      this.is_swap_target = false;
    },
  },
};
</script>
