import { getLanguages } from '@helpers/DataSet';
import Queue from '@libs/Queue';
import Service from '@libs/Service';
import { FLIGHT } from '@master/constants';
import DateRangeFilter from '@master/Filters/_filters/DateRangeFilter';

class FlightAnalyticsService extends Service {
  #queue = new Queue();
  #rulesets = {};
  router = null;

  async getOptions() {
    return this.#makeRequest()
      .then(response => {
        return response?.options;
      })
      .catch(() => {
        return null;
      });
  }

  async getActiveRulesets(ruleset_id) {
    this.#rulesets = {};

    return this.#makeRequest()
      .then(async response => {
        await this.#parseRulesets(response?.rulesets);

        if (!(ruleset_id in this.#rulesets)) {
          return this.#rulesets;
        }

        return {
          [ruleset_id]: this.#rulesets[ruleset_id],
        };
      })
      .catch(() => {
        return this.#rulesets;
      });
  }

  getSentence(condition, languages) {
    switch (condition?.key) {
      case FLIGHT.TYPES.LOCATION:
        return `Location: ${condition.value.map(location => location.filter(item => item != null).join(', ')).join(', ')}`;
      case FLIGHT.TYPES.LANGUAGE:
        return `Language: ${condition.value.map(language => languages[language]).join(', ')}`;
      case FLIGHT.TYPES.DATE:
        return `Date range: ${DateRangeFilter(condition.value[0], condition.value[1], { utc: true })} - ${condition.value[2]}`;
      case FLIGHT.TYPES.TIME:
        return `Time range: ${(condition.value[0] / 60 / 60).toString().padStart(2, 0)}:00 - ${(condition.value[1] / 60 / 60).toString().padStart(2, 0)}:00 - ${condition.value[2]}`;
      case FLIGHT.TYPES.ZIP:
        return `Postalcodes: ${condition.value.join(', ')}`;
      default:
        return null;
    }
  }

  async #makeRequest() {
    const path = this.#getPath();

    if (path != null) {
      return await this.#queue.get(path);
    }
  }

  #getPath() {
    const params = this.#getQueryParams();

    if (params != null) {
      return `/v2/flights/${params.flight_id}/analytics/${params.live_id}/rulesets`;
    }
  }

  #getQueryParams() {
    const params = this.router?.history?.current?.params ?? {};

    const flight_id = params.flight_id;
    const live_id = params.live_id ?? params.public_live_id;

    if (flight_id != null && live_id != null) {
      return { flight_id, live_id };
    }
  }

  async #parseRulesets(rulesets = {}) {
    const languages = await getLanguages();

    for (const ruleset_id in rulesets) {
      const ruleset = rulesets[ruleset_id];
      const sentences = [];

      for (const condition of ruleset.conditions) {
        sentences.push(
          getSentence(
            {
              key: condition[0],
              value: condition[2],
            },
            languages,
          ),
        );
      }

      this.#rulesets[ruleset_id] = { ...ruleset, sentences };
    }
  }
}

export default new FlightAnalyticsService();
