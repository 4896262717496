<template lang="pug">
div.options-filters(v-if="has_visible_settings")
  UIGroup(:title="title" :subtitle="true")
    div.option-items
      template(v-for="(setting, name) of allowed_settings")
        template(v-if="!setting.custom")
          OptionsBlock(
            :key="name"
            :name="name"
            :creative="creative"
            :settings="setting.parent"
            :item="setting.item"
            :disabled="disabled_settings[name]"
            :type="type"
            @change="change($event)")
        template(v-else)
          CustomOptionsBlock(
            :key="name"
            :name="name"
            :creative="creative"
            :item="setting.item"
            :disabled="disabled_settings[name]"
            @change="change($event)")
</template>

<script>
import UIGroup from '@master/UI/UIGroup.vue';
import OptionsBlock from './OptionsBlock.vue';
import CustomOptionsBlock from './CustomOptionsBlock.vue';

import TemplatesSettingsService from '@master/Services/TemplatesSettingsService';

import { TYPE } from '@master/constants';

export default {
  name: 'OptionsFilters',
  components: {
    OptionsBlock,
    CustomOptionsBlock,
    UIGroup,
  },
  props: {
    creative: Object,
    title: {
      type: String,
      default: null,
    },
    keys: Array,

    // if exists, settings are taken from creative.settigs.<type>
    type: {
      type: String,
      default: null,
    },

    // additional prop used with type, to get extra settings from root creative.settings
    extra: {
      type: Array,
      default: null,
    },
  },

  computed: {
    has_visible_settings() {
      if (!this.settings) return false;

      for (const key in this.allowed_settings) {
        const item = this.allowed_settings[key].item;
        const visible = this.admin_toggle || item.visibility === 'all' || (item.visibility === 'feature' && this.$user.hasGroupFeature(key));
        if (visible) return true;
      }
      return false;
    },

    disabled_settings() {
      let obj = {};

      if (this.creative.settings == null) return obj;

      let settings = this.creative.settings;

      for (const key of this.keys) {
        if (key === 'loop_animate') {
          // disable loop animate if the auto rotate is off
          if (settings.auto_rotate != null && !settings.auto_rotate) {
            obj.loop_animate = true;
          }
        } else if (key === 'anim_gesture_img') {
          // disable icon animation if the gesture is off
          if (settings.gesture != null && !settings.gesture) {
            obj.anim_gesture_img = true;
          }
        } else if (key === 'turn_pause') {
          // disable slide duration if auto rotate off
          if (settings.auto_rotate != null && !settings.auto_rotate) {
            obj.turn_pause = true;
          }
        } else if (key === 'skip_ad') {
          obj.skipoffset = !settings.vast.skip_ad;
        } else if (key === 'auto_animate_type') {
          if (settings.auto_animate === false) {
            obj.auto_animate_type = true;
          }
        } else if (key === 'spin_speed') {
          if (settings.start_spin === false) {
            obj.spin_speed = true;
          }
        }
      }

      return obj;
    },
  },

  data() {
    return {
      admin_toggle: false,
      allowed_settings: {},

      show_block: false,
      allowed_empty_value: ['anchor', 'paragraphpush'],

      settings: null, // when null, loading
    };
  },

  created() {
    this.$user.subscribe(() => {
      this.admin_toggle = this.$user.adminMode();
    }, this);

    TemplatesSettingsService.subscribe(settings => {
      this.settings = settings;

      if (this.type != null) {
        this.handleSpecific();
      }
      this.handleDefaults();
      this.handleCustom();
    }, this);
  },

  methods: {
    handleDefaults() {
      const SETTINGS = this.settings.DEFAULT;

      if (this.creative.type === TYPE.VAST && SETTINGS.template_speed != null) {
        delete SETTINGS.template_speed;
      }

      let keys = this.keys;

      if (this.extra) {
        keys = keys.concat(this.extra);
      }

      for (const key of keys) {
        if (key === 'replay') {
          this.handleEndcardReplay(key);
          continue;
        }

        // setting must exist in the configuration
        if (!SETTINGS[key]) continue;

        // setting must exist in creative
        if (this.creative.settings[key] == null) continue;

        this.allowed_settings[key] = {
          parent: this.creative.settings,
          item: SETTINGS[key],
          custom: false,
        };
      }
    },

    handleCustom() {
      const SETTINGS = this.settings.CUSTOM;

      for (const key of this.keys) {
        // setting must exist in the configuration
        // all custom settings have requirements before shown
        if (!SETTINGS[key]?.required) continue;

        const required = SETTINGS[key].required;
        let required_matches = Object.keys(required).length;

        for (const k in required) {
          if (this.creative[k] === required[k] || (Array.isArray(required[k]) && required[k].includes(this.creative[k]))) {
            required_matches--;
          }
        }

        if (required_matches > 0) continue;

        this.allowed_settings[key] = {
          parent: null,
          item: SETTINGS[key],
          custom: true,
        };
      }
    },

    handleSpecific() {
      const category = this.type.toUpperCase();

      const SETTINGS = this.settings?.[category];
      if (!SETTINGS) return;

      for (const key of this.keys) {
        // setting must exist in the configuration
        if (!SETTINGS[key]) continue;

        // setting must exist in creative under settings.type.key
        if (this.creative.settings?.[this.type]?.[key] == null) continue;

        this.allowed_settings[key] = {
          parent: this.creative.settings[this.type],
          item: SETTINGS[key],
          custom: false,
        };
      }
    },

    handleEndcardReplay(key) {
      if (this.creative.template == null) {
        return;
      }

      const template_asset = Object.values(this.creative.template.assets).find(asset => asset.options_tab);

      if (template_asset == null || this.creative.assets[template_asset.asset_id] == null) {
        return;
      }

      this.allowed_settings[key] = {
        item: {
          ...this.creative.assets[template_asset.asset_id],
          visibility: 'all',
          title: 'Replay Ad button',
        },
        custom: true,
      };
    },

    change(reload_assets_library = false) {
      this.$emit('change', reload_assets_library);
    },
  },
};
</script>
