import { awaitLibrary } from '@helpers/Global';
import { alert } from '@libs/alerts';

import AssetHelper from './Asset';

export default class ArnoHelper {
  // cache same asset size calculations
  #cache = new Map();
  constructor() {
    this.arno = undefined;
  }

  /**
   * @param {object} options
   * @returns {ArnoHelper}
   */
  async create(options) {

    if (this.arno) {
      return Promise.resolve(this);
    }
    try {
      // assuming you will use awaitLibrary from global helpers
      await awaitLibrary('Arno', 'Failed to initialize layout, reloading page...<br>If this keeps happening, please contact support', async () => {
        this.arno = await new window.Arno(options);
      });
      return this;
    } catch (e) {
      await alert(undefined, e.message);
      window.location.reload();
    }
  }

  getSize(filename) {
    if (!this.#cache.has(filename)) {
      const value = this.arno.api.asset.getInfo(filename);
      if (!value) {
        throw new Error(`Arno error getting values for ${filename}`);
      }
      this.#cache.set(filename, value);
    }
    return this.#cache.get(filename);
  }

  modify(creative) {
    if (!this.arno) return;

    for (const asset_id in creative.template.assets) {
      const template_asset = creative.template.assets[asset_id];

      // no splash modifications
      if (AssetHelper.isCustomSplash(asset_id)) continue;
      // no overlay modifications
      if (AssetHelper.isOverlay(template_asset)) continue;

      const filename = this.getFrameworkkAssetFilename(template_asset);
      const { x, y, w, h, sc } = this.getSize(filename);

      template_asset.x = x;
      template_asset.y = y;

      template_asset.width = '=' + w * sc.x;
      template_asset.height = '=' + h * sc.y;
    }
  }

  getFrameworkkAssetFilename(template_asset) {
    // when no assets its probably crop for the extra asset like
    // arrorws or gesture, thus we take the settings/area from the 1st carousel image
    if (!template_asset || !template_asset.filename) {
      return '1';
    }

    let filename = template_asset.filename.split('.').shift();
    if ((!isNaN(parseInt(filename)) && parseInt(filename) > 3) || filename.startsWith('feed_')) {
      return '1';
    } else if (filename === 'endcard') {
      return 'top';
    } else if (template_asset.asset_id && template_asset.asset_id.startsWith('custom_')) {
      return 'cta';
    }
    return filename;
  }

  destroy() {
    if (this.arno?.close) {
      this.arno.close();
    }
    this.arno = undefined;
    this.#cache = new Map();
  }
}
