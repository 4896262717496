import moment from 'moment';

export function getUpdatedCTA(cta) {
  switch (cta.action) {
    case 'url':
    case 'call':
    case 'mailto':
    case 'lightbox':
    default:
      return {
        action: cta.action,
        uri: '',
      };

    case 'sms':
      return {
        action: cta.action,
        uri: '',
        sms_body: '',
      };

    case 'calendar':
      const m = moment.utc().add(14, 'days').set('minutes', 0);
      return {
        action: cta.action,
        title: '',
        location: '',
        description: '',
        start: m.set('hour', 12).unix(),
        end: m.set('hour', 13).unix(),
        timezone: 'UTC',
      };

    case 'map':
      return {
        action: cta.action,
        uri: [],
        focus_closest: true,
      };
  }
}

export function payloadForSeparate(trackers, options) {
  const creatives = {};

  for (const creative_id in trackers) {
    const creative = trackers[creative_id];

    if (options.parameter === 'cta') {
      const cta = { ...creative.cta };
      delete cta.impressiontracker;
      creatives[creative_id] = { cta };
      continue;
    }

    if (options.is_video_option && !creative.has_video) {
      continue;
    }

    creatives[creative_id] = {
      cta: {
        impressiontracker: creative.cta.impressiontracker,
      },
    };
  }

  const payload = {
    creatives,
  };

  if (options.parameter === 'tracking') {
    payload.append = options.append;
  }

  return payload;
}
