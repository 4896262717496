<script>
import { ASSET } from '@master/constants';

export default {
  methods: {
    isMain(type = null) {
      if (type == null) {
        type = this.asset.type;
      }
      return type === ASSET.NORMAL;
    },

    isBackground(type = null) {
      if (type == null) {
        type = this.asset.type;
      }
      return type === ASSET.BACKGROUND;
    },

    isOverlay(type = null) {
      if (type == null) {
        type = this.asset.type;
      }
      return type === ASSET.OVERLAY;
    },

    isResponsivePanoramaOverlay(asset_id) {
      if (asset_id == null) {
        asset_id = this.asset.asset_id;
      }
      return asset_id === 'Db6cLSimfzZT';
    },

    isRequired(required = null) {
      if (required == null) {
        required = this.asset.required;
      }
      return required === 1;
    },

    isAdditional(required = null) {
      if (required == null) {
        required = this.asset.required;
      }
      return required === 2;
    },

    isOptional(required = null) {
      if (required == null) {
        required = this.asset.required;
      }
      return required === 0;
    },

    isEndcard(asset = null) {
      if (asset == null) {
        asset = this.asset;
      }
      return this.isMain(asset.type) && asset.filename.indexOf('endcard') !== -1;
    },

    isSplash(type = null) {
      if (type == null) {
        type = this.asset.type;
      }
      return type === ASSET.SPLASH;
    },

    singleLayerAsset(asset_id = null) {
      if (asset_id == null) {
        asset_id = this.asset.asset_id;
      }

      const social_profile = ['social.wn9E9YFSAss9.image.7aualm4gnb62'];
      const map_headers = ['IvnZD9RkuJaI', 'Oc9WfZXc9at4', 'T0ufBNbi8ka0', 'biKtKVamFgc1', 'oZhOULbWEbAr'];

      // scroll hints are overlays now, i think
      // commented out, if needed can put back
      // const map_scroll_hints = ['tSZMCbMp8AMJ'];

      const single_layer_assets = [
        ...social_profile,
        ...map_headers,
        // ...map_scroll_hints,
        // caraousel splitscreen arrows by Linno
        'mdfiersdofr3',
        'kkggi938kd94',
        'zxwee324fdsd',
        'kif930kf9kdh',
      ];
      return single_layer_assets.indexOf(asset_id) !== -1;
    },
  },
};
</script>
