<template lang="pug">
div#app-wrapper
  div#profile-wrapper(v-content)
    Loading(v-if="user == null")
    div.flex.flex-direction-column.row-gap-32(v-else)

      ProfileCard
        template(v-slot:image)
          UserImage(:illustration="true" :fill="true" :name="user.name" :img="user.img" :loading="uploading")
          center
            div.upload-box(:class="{'disabled': uploading}" @click="selectFile")
              i.nexd-icon-32-upload(aria-hidden="true")
              span Upload new image
              input(ref="asset-input" type="file" accept="image/jpg, image/jpeg, image/png" @change="upload")
            span.upload-box(v-if="user.img != null" @click="deleteImage")
              i.nexd-icon-32-delete(aria-hidden="true")
              span Delete image

        section.flex.flex-direction-column.row-gap-16
          h2.fs-24 My Profile
          div.flex.flex-align-center.flex-justify-between
            h4.fs-16 Basic information
            Button(type="primary" label="Edit" :animate="true" @click="edit_user_modal = true")
              template(v-slot:prefix)
                IconEdit

          div.row.row-gap-16
            ProfileCardInfoColumn(label="First name" :value="user.first_name")
            ProfileCardInfoColumn(label="Last name" :value="user.last_name")
            ProfileCardInfoColumn(label="Job position" :value="user.job_title")
            ProfileCardInfoColumn(label="Country" :value="countries?.[user.country] ?? user.country")
            ProfileCardInfoColumn(label="Email" :value="user.email" :filter="'email'")

          div.card.card-rounded
            div.card-body.dark-body.medium-body.flex.flex-align-end.column-gap-16
              div
                label.mb-4 Password
                input.form-control(type="password" value="********" disabled)
              Button(type="primary" label="Change" :animate="true" @click="edit_password_modal = true")

        section.flex.flex-direction-column.row-gap-16
          h2.fs-24 Two-Factor Authentication
          span.fs-14.color-gray-800 To add an extra layer of security by linking your account to an authenticator app.&nbsp;
            a(href="https://support.nexd.com/en/articles/9969151-two-factor-authentication-2fa" target="_blank") Learn More
          div.flex.flex-align-center.column-gap-24
            span Two-Factor Authentication
            Toggle(v-model="mfa_toggle" @change="toggleMfa")

        section.flex.flex-direction-column.row-gap-24
          h2.fs-24 Connected accounts

          div.flex.flex-direction-column.row-gap-8
            h4.fs-16 Google

            div.flex.flex-align-center.gap-32(v-if="has_google_integration")
              div.flex.gap-8
                IconGoogle
                span Connected to {{ user.google_email | SensitivityFilter('email', google_revealed) }}
                Reveal(v-model="google_revealed")
              Button(type="primary" label="Remove" :animate="true" @click="handleGoogleRemove")
            div(v-else)
              Button(
                type="login-google"
                :outline="true"
                label="Link with Google"
                @click="handleGoogleAdd"
              )
                template(v-slot:prefix)
                  IconGoogle

          div.flex.flex-direction-column.row-gap-8
            h4.fs-16 Microsoft

            div.flex.flex-align-center.gap-32(v-if="has_microsoft_integration")
              div.flex.gap-8
                IconMicrosoft
                span Connected to {{ user.microsoft_email | SensitivityFilter('email', microsoft_revealed) }}
                Reveal(v-model="microsoft_revealed")
              Button(type="primary" label="Remove" :animate="true" @click="handleMicrosoftRemove")

            div(v-else)
              Button(
                type="login-microsoft"
                :outline="true"
                label="Link with Microsoft"
                @click="handleMicrosoftAdd"
              )
                template(v-slot:prefix)
                  IconMicrosoft

        section.flex.flex-direction-column.row-gap-8(v-if="user.group")
          h2.fs-24 Membership
          div.card.card-rounded
            div.card-body.dark-body.medium-body
              div.card-title.flex.flex-align-center
                span.fw-500.flex-grow {{user.group.name}}
                img( :src="$cdn + 'dist/assets/cm/org-group.svg'" alt="")
            div.card-body.medium-body
              div.row.flex-align-center
                div.col-6
                  div.fs-12.color-gray-800 ROLE
                  div.fs-14.mt-4 {{user.group.role.name}}
                div.col-6
                  div.fs-12.color-gray-800 MEMBER SINCE
                  div.fs-14.mt-4 {{user.created_on | DateFilter}}
                div.col.flex-grow
                  div.fs-12.color-gray-800 ACCOUNT MANAGER
                  div.fs-14.mt-4(v-if="user.group.manager") {{user.group.manager.name}}
                  div.fs-14.mt-4(v-else) No account manager
                div.col.text-right( v-if="user.group.manager")
                  Button(type="primary" :outline="true" :href="`mailto:${user.group.manager.email}`" label="Contact Admin")
          small.text-center.color-gray-800 In case of any question regarding your organization and usage rights please contact your organization administrator.

        ApiKeyCard(v-if="group_admin && api_token_feature || admin_toggle")

  ChangeBasicInfoModal(v-if="edit_user_modal" :user="user" @close="edit_user_modal = false")
  ChangePasswordModal(v-if="edit_password_modal" :user="user" @close="edit_password_modal = false")
  Enable2FAModal(v-if="show_enable_2fa_modal" @close="closeMFA")
  Disable2FAModal(v-if="show_disable_2fa_modal" @close="closeMFA")
</template>

<script>
import Upload from '@helpers/Upload';
import { getCountries } from '@helpers/DataSet';

import Toggle from '@master/UI/Toggle.vue';
import Button from '@master/UI/Buttons/Button.vue';
import IconEdit from '@master/UI/Buttons/Icons/IconEdit.vue';
import IconGoogle from '@master/UI/Buttons/Icons/IconGoogle.vue';
import IconMicrosoft from '@master/UI/Buttons/Icons/IconMicrosoft.vue';
import Loading from '@master/UI/Loading.vue';
import UserImage from '@master/UI/UserImage/User.vue';

import ChangeBasicInfoModal from '@cm/Views/Profile/Modals/ChangeBasicInfoModal.vue';
import ChangePasswordModal from '@cm/Views/Profile/Modals/ChangePasswordModal.vue';
import ProfileCard from '@cm/Views/Profile/Blocks/Components/ProfileCard/ProfileCard.vue';
import ProfileCardInfoColumn from '@cm/Views/Profile/Blocks/Components/ProfileCard/ProfileCardInfoColumn.vue';
import ApiKeyCard from '@cm/Views/Profile/Blocks/Components/ApiKeyCard.vue';
import Reveal from '@cm/Views/Profile/Blocks/Components/Reveal.vue';
import Enable2FAModal from '@cm/Views/Profile/Modals/2FA/Enable2FAModal.vue';
import Disable2FAModal from '@cm/Views/Profile/Modals/2FA/Disable2FAModal.vue';

import { USER_FLAGS } from '@master/constants';

export default {
  name: 'Profile',

  components: {
    ApiKeyCard,
    Button,
    ChangeBasicInfoModal,
    ChangePasswordModal,
    IconEdit,
    IconGoogle,
    IconMicrosoft,
    Loading,
    ProfileCard,
    ProfileCardInfoColumn,
    Reveal,
    UserImage,
    Toggle,
    Enable2FAModal,
    Disable2FAModal,
  },

  data() {
    return {
      user: null,
      Cropper: this.$cropper(),
      admin_toggle: false,
      api_token_feature: false,
      group_admin: false,
      uploading: false,
      countries: null,

      edit_user_modal: false,
      edit_password_modal: false,

      has_google_integration: false,
      has_microsoft_integration: false,
      google_revealed: false,
      microsoft_revealed: false,

      mfa_toggle: false,
      show_enable_2fa_modal: false,
      show_disable_2fa_modal: false,
    };
  },

  async created() {
    this.$user.subscribe(user => {
      this.user = user;
      this.has_google_integration = this.$user.hasGoogleIntegration();
      this.has_microsoft_integration = this.$user.hasMicrosoftIntegration();
      this.admin_toggle = this.$user.adminMode();
      this.api_token_feature = this.$user.hasGroupFeature('api_token');
      this.group_admin = this.$user.isGroupAdmin();
      this.mfa_toggle = this.user.mfa_enabled;
    }, this);

    this.countries = await getCountries();
  },

  methods: {
    initCropper() {
      this.Cropper.init({
        cropSize: {
          width: 200,
          height: 200,
        },
      });

      this.Cropper.onsave = settings => {
        const file = this.Cropper.getFile();
        this.imageChangeHandler(file, settings);
      };
    },

    selectFile() {
      this.$refs['asset-input'].click();
    },

    upload(e) {
      this.initCropper();
      this.Cropper.inputChangeHandler(e, true);
    },

    imageChangeHandler(file, settings) {
      if (file == null) return;

      this.uploading = true;
      const handler = new Upload(file);
      handler.upload(({ done, upload_id, error }) => {
        if (done) {
          const obj = {
            upload_id,
            settings,
          };

          this.$http
            .post('auth/me', obj)
            .then(response => {
              this.$user.set({ img: response.image });
            })
            .finally(_ => {
              this.uploading = false;
            });
        } else if (error) {
          this.uploading = false;
        }
      });
    },

    async deleteImage() {
      if (this.user.img == null) return;
      if (!(await this.$confirm('Are you sure you want to delete your profile image?'))) return;
      this.$user.set({ img: null });
      this.$http.delete('auth/me').catch(() => {
        /** suppress errors */
      });
    },

    handleGoogleAdd() {
      let path = this.$api + 'v2/user/google';
      if (process.env.NODE_ENV === 'development') {
        path += `?env=${process.env.NODE_ENV}`;
      }
      window.location.href = path;
    },

    handleMicrosoftAdd() {
      let path = this.$api + 'v2/user/microsoft';
      if (process.env.NODE_ENV === 'development') {
        path += `?env=${process.env.NODE_ENV}`;
      }
      window.location.href = path;
    },

    // not in use yet, need to handle password adding before unlinking from google would be possible
    handleGoogleRemove() {
      this.$http
        .delete('v2/user/google')
        .then(_ => {
          this.$user.removeFlag(USER_FLAGS.GOOGLE_INTEGRATION);
        })
        .catch(_ => {
          /** error handled by the notifications */
        });
    },

    // not in use yet, need to handle password adding before unlinking from google would be possible
    handleMicrosoftRemove() {
      this.$http
        .delete('v2/user/microsoft')
        .then(_ => {
          this.$user.removeFlag(USER_FLAGS.MICROSOFT_INTEGRATION);
        })
        .catch(_ => {
          /** error handled by the notifications */
        });
    },

    toggleMfa(value) {
      if (value) {
        this.show_enable_2fa_modal = true;
      } else {
        this.show_disable_2fa_modal = true;
      }
    },

    closeMFA() {
      this.show_enable_2fa_modal = false;
      this.show_disable_2fa_modal = false;
      this.mfa_toggle = this.user.mfa_enabled;
    },
  },
};
</script>
