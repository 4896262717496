import AssetHelper from '@helpers/Asset';
import { clone } from '@helpers/Global';
import { alert } from '@libs/alerts';
import { SIZE } from '@master/constants';
import $http from '@master/Services/HttpService';

import CropperModal from '@master/Modals/Cropper.vue';
import Background from '@helpers/CropperBackground/Background';

class CropperHelper {
  constructor(route, VueInstance) {
    // file position settings
    this.route = route;
    this.VueInstance = VueInstance;
    this.cropperSettings = {};

    // croptool options
    this.cropperOptions = null;

    this.cropper = null;
    this.cropperInstance = null;
    this.isVideo = false;

    // when manual is true, auto crop will be ignored
    this.manual = false;
    this.new_media = false;
    this.onsave = null;
    this.oncancel = null;
    this.type = null;
    this.device = null;

    // wheter this cropping cycle did end with auto crop
    this.auto_crop = false;

    // fn which is called before showing cropper, if its not null
    this.beforeShow = null;
  }

  init(options, creative = null) {
    this.cropperOptions = {
      width: '100%',
      height: 480,
      cropSize: {
        width: 100,
        height: 100,
      },
      clip: null,
      grid: 9,
      fixedRatio: true,
      videoButtonsController: null,
      videoTrimmerController: null,
      getVideoTrimmerImage: null,
      globalWindow: null,
      module: null,
      // TODO - move this analytics key to the cropper instance props instead
      asset_name: null,
    };

    for (let key in options) {
      // remove any object reference by cloning the keys
      this.cropperOptions[key] = clone(options[key]);
    }

    if (this.cropperOptions.placement != null) {
      if (this.cropperOptions.placement.adsize.height >= SIZE.INTERSTITIAL.HEIGHT_HIGH_ASPECT) {
        // high aspect ratio device will have 2 safe areas shown
        this.cropperOptions.safeAreaSize = [
          {
            label: 'Safe area for high aspect ratio',
            top: 120,
            bottom: 120,
          },
          {
            label: 'Safe area for low aspect ratio',
            top: 213,
            bottom: 213,
          },
        ];
      } else if (this.cropperOptions.placement.adsize.height >= SIZE.INTERSTITIAL.HEIGHT) {
        this.cropperOptions.safeAreaSize = [
          {
            label: 'Safe area for low aspect ratio',
            top: 143,
            bottom: 143,
          },
        ];
      }
    }

    this.type = creative?.type ?? null;
    this.device = creative?.device ?? null;
  }

  close() {
    if (this.#inDocument(this.cropperInstance?.$el)) {
      document.body.removeChild(this.cropperInstance.$el);
    }

    this.cropperInstance = null;

    if (this.cropper != null) {
      this.cropper.unload();
      this.cropper = null;
    }

    this.onsave = null;
    this.oncancel = null;
  }

  async instance(callback) {
    if (window.Cropper == null) {
      throw new Error(`Cropper library not included, please add ${window.__nexd.cdn}dist/Cropper/cropper.min.js to your index.\nMake sure Cropper has been loaded before you try to initialize it`);
    }

    this.auto_crop = false;

    const CropperComponent = this.VueInstance.extend(CropperModal);
    this.cropperInstance = new CropperComponent({
      propsData: {
        isVideo: this.isVideo,
        settings: this.cropperSettings,
        type: this.type,
        device: this.device,
        options: this.cropperOptions,
        route: this.route,
      },
    });
    this.cropperInstance.$mount();
    this.#initCropper();

    this.cropperInstance.$on('cropperSave', () => {
      this.onsave?.(this.cropperSettings, this.auto_crop);
      this.close();
    });

    this.cropperInstance.$on('cropperClose', () => {
      this.oncancel?.();
      this.close();
    });

    this.cropperInstance.$on('change', settings => {
      this.cropperSettings = settings;
    });

    // add references to video layers
    this.cropperOptions.videoButtonsController = this.cropperInstance.$refs.videoButtonsController ?? null;
    this.cropperOptions.videoTrimmerController = this.cropperInstance.$refs.videoTrimmerController ?? null;

    // create cropper library
    this.cropper = new Cropper(this.cropperInstance.$refs.cropper, this.cropperOptions);

    // call instance ready to send input to cropper
    callback();

    // when cropper is ready, do the rest
    this.cropper.onCropperReady = () => {
      this.afterCropperReady?.(this.cropper);

      // check if initial asset is exact match +- few px, to handle some aspect ratio cases etc
      const allowed_offset = 2;

      let asset_exact_match =
        !this.manual &&
        this.cropperSettings.CropLayer.size.height >= this.cropperSettings.SourceLayer.size.height - allowed_offset &&
        this.cropperSettings.CropLayer.size.height <= this.cropperSettings.SourceLayer.size.height + allowed_offset &&
        this.cropperSettings.CropLayer.size.width >= this.cropperSettings.SourceLayer.size.width - allowed_offset &&
        this.cropperSettings.CropLayer.size.width <= this.cropperSettings.SourceLayer.size.width + allowed_offset;

      if ((asset_exact_match && this.new_media) || this.cropperOptions.auto_crop) {
        this.auto_crop = true;

        if (this.cropperInstance != null) {
          this.cropperInstance.$emit('cropperSave');
        }
      } else {
        // show cropper modal only if not auto crop
        this.#showCropper();
      }
    };
  }

  newFromURL(url, lazyload = null) {
    this.cropperSettings = {};
    this.new_media = true;
    this.isVideo = AssetHelper.isVideo(url);

    if (this.isVideo) {
      this.cropperOptions.getVideoTrimmerImage = (el, done) => {
        this.#getTrimmerBackgroundImage(url, el, done);
      };
    }

    this.instance(() => {
      this.cropperInstance.init(this.cropper);
      this.cropper.openFromURL(url, null, lazyload);
    });
  }

  editAsset(url, settings = null, lazyload = null) {
    this.new_media = false;
    this.isVideo = AssetHelper.isVideo(url);

    if (this.isVideo) {
      this.cropperOptions.getVideoTrimmerImage = (el, done) => {
        this.#getTrimmerBackgroundImage(url, el, done);
      };
    }

    this.cropperSettings = clone(settings);

    this.instance(() => {
      this.cropperInstance.init(this.cropper);
      this.cropper.openFromURL(url, this.cropperSettings, lazyload);
    });
  }

  editModule(module = null, module_options = {}) {
    this.cropperSettings = clone(module);

    this.instance(() => {
      this.cropperInstance.init(this.cropper);
      this.cropper.openModule(this.cropperSettings, module_options);
    });
  }

  // when manual: true - cropper never auto crops
  async inputChangeHandler(e, manual = false) {
    const file = e.target.files[0];

    // if cancel is pressed, file is undefined
    if (file != null) {
      this.isVideo = AssetHelper.isVideo(file.type);

      if (this.isVideo) {
        const duration = await AssetHelper.getVideoDuration(file);

        if (duration < 1) {
          alert(
            undefined,
            'You uploaded an video that is too short (under 1 second). This could create flickering for the ad viewer and we cannot allow that. Please upload a longer video (or use a still image instead).',
            'Uploaded video is too short',
          );
          return false;
        }
      }

      this.cropperSettings = {};
      this.manual = manual;
      this.new_media = true;

      this.instance(() => {
        this.cropperInstance.init(this.cropper);
        this.cropper.openFromFile(file);
      }, true);
    }

    e.target.value = '';
    return true;
  }

  getFile() {
    if (this.cropper) {
      return this.cropper.getFile();
    }
  }

  getFileDimensions() {
    let node = {
      width: 0,
      height: 0,
    };

    if (this.cropper != null) {
      node = this.cropper.getImage() ?? this.cropper.getVideo();
    }

    return {
      width: node.width ?? node.videoWidth ?? 0,
      height: node.height ?? node.videoHeight ?? 0,
    };
  }

  #initCropper() {
    this.cropperInstance.$el.style.opacity = 0;
    this.cropperInstance.$el.style.zIndex = -2147483647;
    window.document.body.appendChild(this.cropperInstance.$el);
  }

  #showCropper() {
    if (this.beforeShow != null && typeof this.beforeShow === 'function') {
      return this.beforeShow(
        () => this.#makeCropperVisible(),
        this.cropper,
        () => this.cropperInstance.$emit('cropperSave'),
      );
    }

    this.#makeCropperVisible();
  }

  #makeCropperVisible() {
    this.cropperInstance.$el.style.opacity = 1;
    /** same as all modals, look for _variables.scss */
    this.cropperInstance.$el.style.zIndex = 99997;
  }

  #getTrimmerBackgroundImage(url, el, done) {
    const path = 'assets/trimmerbg';
    $http.post(path, { url }, { notification: false }).then(result => {
      el.style.backgroundImage = `url('${result.url}')`;
      el.style.backgroundSize = 'cover';

      // removes loading
      done();
    });
  }

  #inDocument(node) {
    return node !== document.body && document.body.contains(node);
  }

  /**
   * Helper function that will create background images used by cropper
   * Will create 3 different layers of images
   * Background - the most bottom layer for cropper, can be transparent if empty, dark overlay will cover the images
   * Overlay    - the most top layer for cropper,    can be transparent if emtpy, dark overlay will not cover the images
   * Assets     - the common assets layer, will be between backround and overlay, will be gray asset color of no assets found, dark overlay will cover the image
   */
  createCropperBackground = async (slot_vnode, callback, active_asset_id) => {
    const creative = slot_vnode.creative;
    const background = new Background(creative);
    background.setActiveAssetId(active_asset_id);
    background.onDraw(callback);

    const handler = background.getAssetHandler();

    handler.handleAssets();
    handler.additional.handleAssets();

    // make list of drawables in correct order
    const drawables = background.createDrawables(creative.settings?.has_arrows);

    await handler.additional.assignLeftovers(drawables);

    return background.generate();
  };

  addCropperBackgroundLayers = (layers, asset) => {
    if (this.cropper == null) return;

    for (const layer of Object.values(layers)) {
      let zIndex = layer.zIndex;

      // by default the cropp/soure layers are in the middle
      // but when cropping a background asset, make sure everything is above
      if (AssetHelper.isBackground(asset)) {
        zIndex += 1000;
      }

      const image = this.cropper.addBackgroundImage(layer.getImage(), {
        zIndex: zIndex,
        name: layer.name,
      });
      if (image != null) {
        image.getElement().style.filter = 'brightness(0.7)';
      }
    }
  };
}

export default CropperHelper;
