import { TIMELINE } from '@master/constants';

export default class Animation {
  /**
   * @type {Timeline}
   */
  #timeline;

  constructor(timeline, obj = null) {
    const { ANIMATION, EASING } = TIMELINE;

    this.#timeline = timeline;

    this.delay = obj?.delay ?? 1000;
    this.duration = obj?.duration ?? 500;
    this.ease = obj?.ease ?? EASING.LINEAR;
    this.side = obj?.side ?? null;
    this.amplitude = obj?.amplitude ?? null;
    this.axis = obj?.axis ?? null;
    this.from = obj?.from ?? 0;
    this.to = obj?.to ?? 1;

    this.type = obj?.type ?? ANIMATION.FADEIN;

    // will set proper values depending on animation type
    this.#validate(this);

    // always collapsed when creating
    this.collapsed = true;
  }

  /**
   * @return {Timeline} enabled
   */
  getTimeline() {
    return this.#timeline;
  }

  changeType(type) {
    this.type = type;
    this.#validate(this);
    this.#timeline.update();
  }

  #validate(obj) {
    const { ANIMATION, PROPERTY, SIDE } = TIMELINE;

    if (this.type === ANIMATION.FADEIN) {
      this.property = PROPERTY.ALPHA;
      this.from = 0;
      this.to = 1;
    } else if (this.type === ANIMATION.FADEOUT) {
      this.property = PROPERTY.ALPHA;
      this.from = 1;
      this.to = 0;
    } else if (this.type === ANIMATION.MOVEIN || this.type === ANIMATION.MOVEOUT) {
      this.property = PROPERTY.POSITION;

      this.amplitude = obj?.amplitude ?? 1;
      this.side = obj?.side ?? SIDE.LEFT;
      this.axis = obj?.axis ?? null;

      if (this.side === SIDE.LEFT || this.side === SIDE.RIGHT) {
        this.axis = 'x';
      } else if (this.side === SIDE.TOP || this.side === SIDE.BOTTOM) {
        this.axis = 'y';
      }
    } else if (this.type === ANIMATION.SCALE) {
      this.property = PROPERTY.SCALE;
      this.axis = 'xy';
      this.from = obj.from ?? 0;
      this.to = obj.to ?? 1;
    }
  }

  toggle() {
    this.collapsed = !this.collapsed;
  }

  toJson() {
    return {
      type: this.type,
      property: this.property,
      axis: this.axis,
      delay: this.delay,
      duration: this.duration,
      ease: this.ease,
      from: this.from,
      to: this.to,
      amplitude: this.amplitude,
      side: this.side,
    };
  }
}
