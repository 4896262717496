<template lang="pug">
UIGroup(:title="options.title" :header="options.show_header")
  AssetSlot(
    v-for="(asset, index) of visible_assets"
    :key="asset.asset_id + index"
    ref="additional-asset"
    :template_asset="asset"
    :creative="creative"
    :additional="true"
    :timeline="timeline"
    @change="reload"
    @reload="reload"
  )

  ButtonWithDescription(
    v-if="options.max_assets > visible_assets?.length"
    :class="{'mt-16': visible_assets?.length > 0}"
    :label="options.button_label"
    :disabled="loading || saving_creative"
    :description="options.more_description"
    @click="addAsset"
  )
</template>

<script>
import { ASSET } from '@master/constants';
import { clone } from '@helpers/Global';
import AssetHelper from '@helpers/Asset';

import SaveService from '@master/Services/SaveService';

import ButtonWithDescription from '@master/UI/Buttons/ButtonWithDescription.vue';
import UIGroup from '@master/UI/UIGroup.vue';

import AssetSlot from '@cm/Views/Creatives/Cards/Components/Asset/Slot/Slot.vue';
import Timeline from '@cm/Views/Creatives/Cards/Components/Asset/Animations/Timeline';

export default {
  name: 'AssetsTabAdditional',

  components: {
    ButtonWithDescription,
    UIGroup,
    AssetSlot,
  },

  props: {
    creative: Object,

    type: {
      type: Number,
      default: ASSET.NORMAL,
    },

    timeline: {
      type: Timeline,
      default: null,
    },
  },

  computed: {
    options() {
      const assets = this.creative.template?.assets;
      let max_assets = 0;

      switch (this.type) {
        case ASSET.OVERLAY:
          max_assets = 35;
          return {
            title: 'Additional overlay elements',
            name: 'Overlay element',
            button_label: 'Add overlay element',
            max_assets,
            payload_type: 'overlay',
            more_description: `You can add ${max_assets - this.visible_assets.length} more overlay elements`,
            show_header: false,
          };
        case ASSET.HOTSPOT:
          max_assets = 6;
          return {
            title: 'Global hotspots',
            name: 'Hotspot media',
            button_label: 'Add hotspot media',
            max_assets,
            payload_type: 'hotspot',
            more_description: `You can add ${max_assets - this.visible_assets.length} more hotspot media`,
            show_header: true,
          };
        default:
          max_assets =
            35 -
            Object.values(assets ?? {}).reduce((acc, asset) => {
              if (asset.required === 1) {
                acc++;
              }
              return acc;
            }, 0);
          return {
            title: 'Added assets',
            name: 'Additional media',
            button_label: 'Add additional media',
            max_assets,
            payload_type: 'feed',
            more_description: `You can add ${max_assets - this.visible_assets.length} more additional media`,
            show_header: this.visible_assets?.length > 0,
          };
      }
    },

    first_template_feed_asset() {
      if (this.creative?.template?.assets) {
        for (const asset_id in this.creative.template.assets) {
          const asset = this.creative.template.assets[asset_id];
          if (asset.required === 1) {
            return asset;
          }
        }
      }
      return null;
    },

    visible_assets() {
      return (
        Object.values(this.creative?.additional_assets ?? {})?.reduce((acc, asset) => {
          if (!asset.custom && asset.custom_type === this.type) {
            let cloned_asset = clone(asset);

            if (this.type === ASSET.OVERLAY) {
              // if global overlay has video support, add video support to the additional overlays as well
              const logo_or_cta = this.creative.template?.assets?.logo || this.creative.template?.assets?.cta;
              if (logo_or_cta?.filename?.includes('mp4')) {
                // add video to the overlay etension list
                cloned_asset.filename += ',.mp4';
              }
            }

            if (this.type === ASSET.NORMAL) {
              // because the additional is the asset object, not tempalte asset, mock the template asset
              // add/replace some of the template asset params
              if (this.first_template_feed_asset) {
                const additional_feed_asset_filename = cloned_asset.filename.split('.')[0];
                const first_template_asset_filename = this.first_template_feed_asset.filename.split('.')[0];

                // allow all same extension files
                cloned_asset.filename = this.first_template_feed_asset.filename.replace(new RegExp(first_template_asset_filename + '\\.', 'gi'), additional_feed_asset_filename + '.');

                cloned_asset.width = this.first_template_feed_asset.width;
                cloned_asset.height = this.first_template_feed_asset.height;
                cloned_asset.type = this.first_template_feed_asset.type;
                cloned_asset.position = this.first_template_feed_asset.position;
                cloned_asset.x = this.first_template_feed_asset.x;
                cloned_asset.y = this.first_template_feed_asset.y;
                cloned_asset.transparency_allowed = this.first_template_feed_asset.transparency_allowed;
              }
            }

            if (AssetHelper.isCustomHotspotMain(cloned_asset.asset_id)) {
              cloned_asset.filename += ',.mp4';
            }

            acc.push(cloned_asset);
          }
          return acc;
        }, []) ?? []
      );
    },
  },

  data() {
    return {
      loading: false,
      saving_creative: false,
    };
  },

  created() {
    SaveService.creative.subscribe(({ saving }) => {
      this.saving_creative = saving;
    }, this);
  },

  methods: {
    addAsset() {
      if (this.loading || this.creative?.creative_id == null) return;

      const payload = {
        name: `${this.options.name} ${this.visible_assets.length + 1}`,
        [this.options.payload_type]: true,
      };

      this.loading = true;

      this.$http
        .post(`creatives/${this.creative.creative_id}/assets`, payload)
        .then(async response => {
          this.$set(this.creative.additional_assets, response.asset.asset_id, response.asset);
          this.focusInput();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reload() {
      this.$emit('reload');
    },

    async focusInput() {
      await this.$nextTick();

      const additional_assets_vnode = this.$refs['additional-asset'];

      if (additional_assets_vnode == null || additional_assets_vnode?.length === 0) {
        return;
      }

      const additional_asset_vnode = additional_assets_vnode[additional_assets_vnode.length - 1];

      if (additional_assets_vnode != null) {
        const input = additional_asset_vnode.$refs.nameInput;

        if (input != null) {
          input.focus();
          input.select();
        }
      }
    },
  },
};
</script>
