import Animation from './Animation';
// eslint-disable-next-line
import Timeline from './Timeline';

export default class Animations {
  /**
   * @type {Timeline}
   */
  #timeline;

  constructor(timeline, obj) {
    this.#timeline = timeline;
    this.enabled = obj?.enabled ?? false;

    /** @type {Animation[]} */
    this.animations = [];
    this.#setupAnimations(obj?.animations);
  }

  /**
   * @return {Timeline} enabled
   */
  getTimeline() {
    return this.#timeline;
  }

  #setupAnimations(animations) {
    if (!animations) return;
    for (const animation of animations) {
      this.animations.push(new Animation(this.#timeline, animation));
    }
  }

  /**
   * @param {Boolean} enabled
   */
  setStatus(enabled) {
    this.enabled = enabled;
    this.#timeline.checkStatus();

    // when enabling without any items, add 1st
    if (this.animations.length === 0) {
      this.add();
    }
  }

  add() {
    const animation = new Animation(this.#timeline);
    this.animations.push(animation);
    // always open new
    animation.collapsed = false;
  }

  /**
   * @param {Animation} animation
   */
  remove(animation) {
    const index = this.animations.indexOf(animation);
    if (index !== -1) {
      this.animations.splice(this.animations.indexOf(animation), 1);
    }
  }

  toJson() {
    return {
      enabled: this.enabled,
      animations: this.animations.map(item => item.toJson()),
    };
  }
}
