<template lang="pug">
SidebarContainer(v-if="!is_sdk_user")

  //- tests
  template(v-if="$route?.meta?.view === VIEW.TEST")
    SidebarItem(
      label="UI Tests"
      :stateless="true"
    )
    SidebarSubitems(:grow="true")
      SidebarItem.text-capitalize(
        v-if="state?.tests?.length > 0"
        v-for="(test, index) of state.tests"
        :key="index"
        :spacer="index === 0"
        :label="test.label.replace(/-/g, ' ')"
        :path="`/components/${test.label}`"
        :active="$route.name === test.url"
        :usertab="true"
      )

  //- dashboard
  template(v-if="$route?.meta?.view === VIEW.DASHBOARD")
    SidebarItem(
      label="Dashboard"
      :stateless="true"
    )

  //- profile sidebar
  template(v-if="$route?.meta?.view === VIEW.PROFILE || $route?.meta?.view?.includes(VIEW.BILLING)")

    SidebarItem(
      data-intercom-target="sidebar-to-my-campaigns"
      icon="nexd-icon-32-arrow-right-small active"
      label="My Campaigns"
      :path="'/'"
    )

    SidebarItem(
      :spacer="true"
      label="Profile Details"
      :path="VIEW.PROFILE"
      :active="$route.name === 'profile'"
    )
    SidebarItem(
      v-if="can_read_group_details"
      :spacer="true"
      label="Organization Details"
      :path="VIEW.ORGANIZATION"
      :active="$route.name === 'organization'"
    )
    SidebarItem(
      v-if="can_read_group_billing"
      :spacer="true"
      label="Subscription Plan & Billing"
      :path="`/${VIEW.BILLING}`"
      :active="$route?.meta?.view.includes(VIEW.BILLING)"
    )

  //- CM sidebar
  SidebarItem(
    v-if="state.back != null && state.back.label != null"
    data-intercom-target="sidebar-to-my-campaigns"
    :stateless="!state.back.url"
    :icon="state.back.url ? 'nexd-icon-32-arrow-right-small active' : null"
    :label="state.back.label"
    :path="state.back.url"
  )
  template(v-if="state.campaign_analytics != null")
    SidebarItem(
      :spacer="$route?.params?.public_id == null"
      :active="$route.name === 'analytics-campaign' || $route.name === 'analytics-flight'"
      icon="nexd-icon-32-analytics"
      :label="state.campaign_analytics.label || 'Campaign Analytics'"
      :path="state.campaign_analytics.url"
      :query="filters"
    )
    SidebarSubitems(v-if="have_campaign_analytics_subitems")
      template(v-for="(item, index) of state.campaign_analytics_subitems")
        SidebarItem(
          :active="item.live_id === $route.params?.live_id"
          :subitem="true"
          :tooltip="{value: item.label, position: 'right'}"
          :label="item.label"
          :path="item.url"
          :query="filters"
        )
        template(v-if="item.quantums?.length > 0")
          SidebarItem(
            v-for="(quantum, quantum_index) of item.quantums"
            :key="`${quantum_index}_${quantum.live_id}`"
            :active="quantum.live_id === $route.params?.live_id"
            :subchild="true"
            :tooltip="{value: quantum.live_name, position: 'right'}"
            :label="quantum.alt_name"
            :path="quantum.analytics_url"
            :query="filters"
          )
  template(v-if="state.creatives != null")
    SidebarItem(
      :spacer="true"
      icon="nexd-icon-32-creative"
      :label="state.creatives.label || 'Creatives'"
      :path="state.creatives.url"
    )
    SidebarSubitems(v-if="have_creatives_subitems")
      SidebarItem(
        v-if="state.folders?.length > 0"
        v-for="folder of state.folders"
        :key="folder.url"
        :subitem="true"
        :tooltip="{value: folder.label, position: 'right'}"
        :label="folder.label"
        icon="nexd-icon-16-folder mr-8"
        :path="folder.url"
      )
      SidebarItem(
        v-for="(creative, index) of state.creatives_subitems"
        :key="index"
        :subitem="true"
        :tooltip="{value: creative.label, position: 'right'}"
        :label="creative.label"
        :path="creative.url"
      )
  SidebarItem(
    v-if="state.flight?.visible"
    :spacer="true"
    :active="state.flight?.url === $route.path"
    :disabled="upserting_flight"
    :loading="upserting_flight"
    icon="nexd-icon-32-flights"
    label="DCO Tools"
    @open="openFlights"
  )
  SidebarItem(
    v-if="state.analytics != null"
    :spacer="true"
    :disabled="!state.analytics || !plan_has_analytics_access"
    :tooltip="!plan_has_analytics_access ? {template: 'upgrade.analytics', class: 'upgrade-tooltip', position: 'right'} : {value: analytics_tooltip, position: 'right'}"
    icon="nexd-icon-32-analytics"
    label="Analytics"
    @open="openAnalytics"
    v-tutorial="'sidebar-analytics'"
  )
  SidebarItem(
    v-if="state.preview != null"
    :spacer="$route?.params?.public_live_id == null"
    :disabled="!state.preview"
    icon="nexd-icon-32-preview"
    label="Preview Site"
    :loading="creating_preview_link"
    @open="openPreview"
  )
  SidebarItem(
    v-if="state.edit"
    :spacer="true",
    icon="nexd-icon-32-edit"
    :label="state.edit.label"
    @open="state.edit.callback"
  )

  UserDashboard(v-if="can_read_group_content && is_root_campaigns_list")
</template>

<script>
import Vue from 'vue';
import Service from './Service';
import { filters } from '@master/Services/AnalyticsAPPService';
import FlightService from '@master/Services/FlightService';

import { getURLForEnv } from '@helpers/Global';
import { VIEW } from '@master/constants';

import PermissionMixin from '@master/Services/mixins/PermissionMixin.vue';

import SidebarContainer from '@master/UI/Sidebar/SidebarContainer.vue';
import SidebarItem from '@master/UI/Sidebar/SidebarItem.vue';
import SidebarSubitems from '@master/UI/Sidebar/SidebarSubitems.vue';
import UserDashboard from '@master/UI/UserDashboard.vue';

export default {
  name: 'Sidebar',

  mixins: [PermissionMixin],

  components: {
    UserDashboard,

    SidebarContainer,
    SidebarItem,
    SidebarSubitems,
  },

  computed: {
    is_root_campaigns_list() {
      return [VIEW.CAMPAIGNS, VIEW.CAMPAIGNS_USER].includes(this.$route.name);
    },

    have_creatives_subitems() {
      return this.state?.creatives_subitems?.length > 0 || this.state?.folders?.length > 0;
    },

    have_campaign_analytics_subitems() {
      return this.state?.campaign_analytics_subitems?.length > 0;
    },

    analytics_tooltip() {
      if (!this.state.analytics) return 'No analytics to show yet, you have no published creatives';
      if (this.$route?.params?.creative_id != null) return "Creative's analytics";
      return 'View campaign analytics';
    },
  },

  data() {
    return {
      VIEW,
      filters,

      plan_has_analytics_access: false,
      is_sdk_user: false,
      creating_preview_link: false,
      upserting_flight: false,

      // updated by the service
      state: Service.getState(),
    };
  },

  beforeCreate() {
    Service._init(this);
    Vue.prototype.$sidebar = Service;
  },

  created() {
    this.$user.subscribe(_ => {
      this.plan_has_analytics_access = this.$user.hasAnalyticsAccess();
      this.is_sdk_user = this.$user.isSDKUser();
    }, this);
  },

  methods: {
    stateUpdate(state) {
      this.state = state;
    },

    openPublishingItem(item) {
      if (item.url) {
        this.$router.push({ path: item.url }).catch(_ => {
          /** supress duplicate route error */
        });
      }
    },

    openAnalytics() {
      if (this.state.analytics) {
        window.open(getURLForEnv(this.state.analytics), '_blank');
      }
    },

    async openPreview() {
      if (this.state.preview == null) {
        return;
      }

      if (typeof this.state.preview === 'function') {
        this.creating_preview_link = true;
        await this.state.preview();
        this.creating_preview_link = false;
        return;
      }

      window.open(this.state.preview, '_blank');
    },

    async openFlights() {
      if (this.state.flight?.url === this.$route.path) {
        return;
      }

      this.upserting_flight = true;
      await FlightService.upsert();
      this.upserting_flight = false;
    },
  },
};
</script>
