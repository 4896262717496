<template lang="pug">
TestLayout(title="Tabs")

  TestSection(subtitle="Default")
    Tabs(v-model="tab_default" :tabs="tabs")
    pre {{ tab_default }}

  TestSection(subtitle="Stateless")
    Tabs(v-model="tab_stateless" :tabs="tabs" stateless)
    pre {{ tab_stateless ?? 'null' }}

  TestSection(subtitle="Stepsless")
    Tabs(v-model="tab_stepsless" :tabs="tabs" stateless :steps="false")
    pre {{ tab_stepsless ?? 'null' }}

  TestSection(subtitle="Disabled")
    Tabs(v-model="tab_disabled" :tabs="tabs" disabled)
    pre {{ tab_disabled }}
</template>

<script>
import TestLayout from '@root/src/apps/components/Global/TestLayout.vue';
import TestSection from '@root/src/apps/components/Global/TestSection.vue';

import Tabs from '@cm/UI/Global/Tabs/Tabs.vue';

export default {
  name: 'TabsTest',

  components: {
    TestLayout,
    TestSection,

    Tabs,
  },

  data() {
    return {
      tabs: {
        foo: 'Foo',
        bar: 'Bar',
        baz: 'Baz',
        qux: 'Qux',
      },

      tab_default: 'foo',
      tab_stateless: null,
      tab_stepsless: null,
      tab_disabled: 'foo',
    };
  },
};
</script>
