<template lang="pug">
Modal(size="modal-sm" title="Edit organization information")
  template(v-slot:body)
    form.flex.flex-direction-column.row-gap-16
      div
        label.mb-4 Company&nbsp;
          sup.color-danger *
        Input(variant="empty" v-model="input.name" placeholder="Enter company name")
      div
        label.mb-4 Billing email&nbsp;
          sup.color-danger *
        Input(variant="empty" v-model="input.email" placeholder="Enter billing email")
      div
        label.mb-4 Reg. number
        Input(v-model="input.reg_nr" placeholder="Enter registration number")
      div
        label.mb-4 VAT code (EU)
        Input(v-model="input.vat_code" placeholder="Enter VAT code")

      div.row.row-gap-16
        div.col-12
          label.mb-4 Billing address&nbsp;
            sup.color-danger *
          Input(ref="input" variant="empty" v-model="address_input" placeholder="Enter billing address" @focus="focusHandler" @blur="blurHandler")
        div.col-12
          label.mb-4 Postal code&nbsp;
            sup.color-danger *
          Input(variant="empty" v-model="input.zip_code" placeholder="Enter postal code")
        div.col-12
          label.mb-4 City&nbsp;
            sup.color-danger *
          Input(variant="empty" v-model="input.city" placeholder="Enter city")
        div.col-12
          label.mb-4 Country&nbsp;
            sup.color-danger *
          SearchSelect(:options="countries" v-model="input.country" :first="false" placeholder="Select Country")

      div.row.row-gap-16
        div.col-12
          label.mb-4.flex.flex-align-center Analytics timezone&nbsp;
            i.nexd-icon-16-help(
              aria-hidden="true"
              v-tooltip="{value: 'Set your default timezone for all analytics data. This timezone will be applied to all campaigns within your organization unless overridden at the campaign level.'}"
            )
          SearchSelect(:options="timezones" v-model="input.analytics_timezone" :first="false" placeholder="Select timezone")
        div.col-12
          label.mb-4.flex.flex-align-center Date format&nbsp;
            i.nexd-icon-16-help(
              aria-hidden="true"
              v-tooltip="{value: 'Set your organization date format.'}"
            )
          SearchSelect(:options="date_formats" v-model="input.date_format" :first="false" placeholder="Select format")

  template(v-slot:footer)
    Buttons
      Button(type="link-primary" label="Cancel" @click="close")
      Button(type="primary" :animate="true" label="Save" :loading="saving" :disabled="!is_valid" @click="saveGroup")
</template>

<script>
import Modal from '@master/Modals/Modal.vue';

import { awaitLibrary, loadGooglePlaces, getDateFormats } from '@helpers/Global';
import { getCountries, getTimezones } from '@helpers/DataSet';

import Payment from '@master/Services/PaymentService';
import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Input from '@master/UI/Input/Input.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import TagInputLocations from '@master/UI/TagInputLocations.vue';
import GroupBillingInfo from '@master/Classes/GroupBillingInfo';

export default {
  name: 'ChangeGroupDetailsModal',

  components: {
    Button,
    Buttons,
    Input,
    SearchSelect,
    TagInputLocations,
    Modal,
  },

  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  computed: {
    payload() {
      return this.input;
    },

    is_valid() {
      return this.input.valid();
    },
  },

  data() {
    const billing_info = new GroupBillingInfo(this.group.billing_info ?? null);
    return {
      input: billing_info,

      // set initial value for the google input
      address_input: billing_info.business_address,

      saving: false,
      countries: null,
      timezones: null,
      date_formats: getDateFormats(),
    };
  },

  async created() {
    this.countries = await getCountries();
    this.timezones = await getTimezones();
    loadGooglePlaces();
  },

  methods: {
    focusHandler() {
      // make sure lib is loaded
      awaitLibrary('google', 'Google maps API not defined', () => {
        const input = this.$refs?.input?.$refs?.input;

        if (window?.google?.maps?.places == null || input == null) {
          return;
        }

        const autocomplete = new google.maps.places.Autocomplete(input, {
          fields: ['address_components', 'geometry'],
          types: ['address'],
        });

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace()?.address_components;

          const route = place?.find(item => item?.types?.includes('route'))?.short_name ?? null;
          const street_number = place?.find(item => item?.types?.includes('street_number'))?.short_name ?? null;

          let business_address = '';

          if (route != null) {
            business_address += route;

            if (street_number != null) {
              business_address += ` ${street_number}`;
            }
          }

          const city = place?.find(item => item?.types?.includes('locality'))?.short_name ?? null;
          const country = place?.find(item => item?.types?.includes('country'))?.short_name ?? null;
          const postal_code = place?.find(item => item?.types?.includes('postal_code'))?.short_name ?? null;

          this.addLocationHandler({
            city,
            country,
            postal_code,
            business_address,
          });
        });
      });
    },

    blurHandler() {
      this.input.business_address = this.address_input;
    },

    saveGroup(close = true) {
      if (this.input.equals(this.group?.billing_info)) {
        return this.close();
      }

      if (!this.is_valid) {
        return;
      }

      this.saving = true;
      this.$http
        .put(`groups/${this.group.group_id}`, this.input)
        .then(response => {
          for (const key in response) {
            this.$set(this.group, key, response[key]);
          }
        })
        .catch(_ => {
          /** supress errors */
        })
        .finally(_ => {
          // when next step is to add card, we keep it opened and in saving/loading state
          if (close) {
            this.saving = true;
            this.close();
          }
        });
    },

    addCard() {
      this.saveGroup(false);
      const payment = new Payment();
      payment.addCard();
    },

    close() {
      this.$emit('close');
    },

    addLocationHandler({ city, country, postal_code, business_address }) {
      if (city) {
        this.input.city = city;
      }

      if (country) {
        this.input.country = country;
      }

      if (postal_code) {
        this.input.zip_code = postal_code;
      }

      this.input.business_address = business_address;
    },
  },
};
</script>
