import { clone } from '@helpers/Global';
import AssetHelper from '@helpers/Asset';

const AssetHelperLayouts = {
  getSize: (creative, slot, options = {}) => {
    const layout = creative.layout;
    const asset_object = slot != null ? clone(slot) : {};
    return AssetHelper.getSizeDimensions(creative, options, layout, asset_object);
  },
};

export default AssetHelperLayouts;
