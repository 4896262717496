import { SIZE, TYPE } from '@master/constants';
import AssetHelper from '@helpers/Asset';
import CustomAssetService from '@master/Services/Cache/CustomAssetService';

import BackgroundLayer from './BackgroundLayer';
import TemplateHandler from './TemplateHandler';
import Drawable from './Drawable';
import LayoutHandler from './LayoutHandler';
import Slot from './Slot';
import ArrowsHelper from './ArrowsHelper';

const DEFAULT_HS_CLOSE_SIZE = 62;
const DEFAULT_HS_PIN_SIZE = 62;

export default class Background {
  #creative;

  /** @type {import('./MainHandler')} */
  #handler;
  // when enabled, will append generated images to document.body
  #debug = false;
  #onDrawCallback = null;

  #active_asset_id;

  constructor(creative) {
    this.#creative = creative;

    const dimensions = this.#getDimensions();
    this.width = dimensions.width;
    this.height = dimensions.height;

    this.layers = {
      // bottom layer
      Background: new BackgroundLayer('Background', this.width, this.height, 400),
      // below active asset
      Assets: new BackgroundLayer('Assets', this.width, this.height, 550),
      // above active asset
      Overlay: new BackgroundLayer('Overlay', this.width, this.height, 1200),
    };
  }

  getCreative() {
    return this.#creative;
  }

  setActiveAssetId(active_asset_id) {
    this.#active_asset_id = active_asset_id;
  }

  getActiveAssetId() {
    return this.#active_asset_id;
  }

  isActiveHotspot() {
    return AssetHelper.isCustomHotspot(this.getActiveAssetId()) || AssetHelper.isCustomHotspotVideoAsset(this.getActiveAssetId());
  }

  isActiveHotspotMain() {
    return AssetHelper.isCustomHotspotMain(this.getActiveAssetId());
  }

  isActiveHotspotClose() {
    return AssetHelper.isCustomHotspotClose(this.getActiveAssetId());
  }

  isActiveHotspotPin() {
    return AssetHelper.isCustomHotspotPin(this.getActiveAssetId());
  }

  isActiveVideoControl() {
    return AssetHelper.isCustomVideoControlAsset(this.getActiveAssetId());
  }

  isActiveGesture() {
    return AssetHelper.isCustomGesture(this.getActiveAssetId());
  }

  onDraw(callback) {
    this.#onDrawCallback = callback;
  }

  async generate() {
    const promises = [];
    for (const layer of Object.values(this.layers)) {
      promises.push(layer.drawAssets());
    }

    await Promise.all(promises);

    if (this.#debug) {
      for (const layer of Object.values(this.layers)) {
        const img = document.createElement('img');
        img.alt = layer.name;
        img.src = layer.getImage();
        document.body.appendChild(img);
      }
    }

    if (this.#onDrawCallback) {
      this.#onDrawCallback(this.layers);
    }
  }

  /**
   * @param {Drawable[]} drawables
   */
  #addPaginationArrows(drawables) {
    const helper = new ArrowsHelper(this, this.#handler);
    helper.modify(drawables);
  }

  /**
   * Hotspot close added/shown only when the main hotspot media is active
   * @param {Drawable[]} drawables
   */
  async #addHotspotClose(drawables) {
    const active_asset_id = this.getActiveAssetId();

    const close_asset_id = active_asset_id.replace('_main', '_close');

    let close = this.#handler.additional.slots.Hotspot.find(slot => slot.asset_id === close_asset_id);

    if (!close) {
      const collection = CustomAssetService.get('custom_hs_close');
      if (collection.empty()) {
        await collection.load();
      }
      if (collection.empty()) {
        return;
      }

      close = new Slot(close_asset_id, collection.first());
      close.customSettings({ x: this.#creative.width * 0.5 - DEFAULT_HS_CLOSE_SIZE * 0.5, y: -this.#creative.height * 0.5 + DEFAULT_HS_CLOSE_SIZE * 0.5, width: DEFAULT_HS_CLOSE_SIZE, height: DEFAULT_HS_CLOSE_SIZE });
    } else {
      // remove the close from the stack
      this.#handler.additional.slots.Hotspot = this.#handler.additional.slots.Hotspot.filter(slot => slot.asset_id !== close_asset_id);
    }

    // if bg main is active, hotspot close is shown above
    const close_drawable = this.layers.Overlay.addAsset(new Drawable(close));
    if (close_drawable) {
      drawables.push(close_drawable);
    }
  }

  /**
   * Hotspot pin added/shown only when the main media or close is active
   * @param {Drawable[]} drawables
   */
  async #addHotspotPin(drawables) {
    const active_asset_id = this.getActiveAssetId();

    const pin_asset_id = active_asset_id.replace(/_(main|close)/i, '_pin');

    let pin = this.#handler.additional.slots.Hotspot.find(slot => slot.asset_id === pin_asset_id);

    if (!pin) {
      const collection = CustomAssetService.get('custom_hs_pin');
      if (collection.empty()) {
        await collection.load();
      }
      if (collection.empty()) {
        return;
      }
      pin = new Slot(pin_asset_id, collection.first());
      pin.customSettings({ x: 0, y: 0, width: DEFAULT_HS_PIN_SIZE, height: DEFAULT_HS_PIN_SIZE });
    } else {
      // remove the close from the stack
      this.#handler.additional.slots.Hotspot = this.#handler.additional.slots.Hotspot.filter(slot => slot.asset_id !== pin_asset_id);
    }

    // if bg main is active, hotspot pin is shown above
    const pin_drawable = this.layers.Assets.addAsset(new Drawable(pin), false);
    if (pin_drawable) {
      drawables.push(pin_drawable);
    }
  }

  /**
   * @returns {Drawable[]}
   */
  createDrawables(pagination = false) {
    const drawables = [];
    this.#createBackgroundDrawables(drawables);
    this.#createAssetDrawables(drawables);

    // handle pagination before overlays
    if (pagination) {
      this.#addPaginationArrows(drawables);
    }

    this.#createOverlayDrawables(drawables);

    this.#createHotspotDrawables(drawables);

    return drawables;
  }

  /**
   * @returns {import('./Layer')}
   */
  getAssetHandler() {
    if (this.#creative.layout_id != null) {
      return this.#getLayoutHandler();
    }
    return this.#getTemplateHandler();
  }

  /**
   * @returns {import('./Layer')}
   */
  #getTemplateHandler() {
    this.#handler = new TemplateHandler(this);
    return this.#handler;
  }

  /**
   * @returns {import('./Layer')}
   */
  #getLayoutHandler() {
    this.#handler = new LayoutHandler(this);
    return this.#handler;
  }

  #getDimensions() {
    let width = this.#creative.width;
    let height = this.#creative.height;

    // fullscreen mobile placement
    if (this.#creative.type === TYPE.INTERSCROLLER) {
      // interscrollers placement size does not match the real ad / template size, which is high aspect ratio interstitial
      width = SIZE.INTERSTITIAL.WIDTH;
      height = SIZE.INTERSTITIAL.HEIGHT_HIGH_ASPECT;
    }

    return { width, height };
  }

  getOverlayIndexes() {
    const handler = this.#handler;
    const overlay_indexes = handler.slots.Overlay.map(slot => slot.asset_id).concat(handler.additional.slots.Overlay.map(slot => slot.asset_id).reverse());
    const active_overlay_index = overlay_indexes.indexOf(this.getActiveAssetId());
    return { overlay_indexes, active_overlay_index };
  }

  /**
   * @param {Drawable[]} drawables
   */
  #createBackgroundDrawables(drawables) {
    const handler = this.#handler;
    for (const slot of handler.slots.Background) {
      if (this.getActiveAssetId() === slot.asset_id) {
        continue;
      }

      const drawable = this.layers.Background.addAsset(new Drawable(slot));
      if (drawable) {
        drawables.push(drawable);
      }
    }
  }

  /**
   * @param {Slot[]} slots
   * @param {Drawable[]} drawables
   */
  #reserveMainAssetSlot(slots, drawables) {
    let active_asset_id = this.getActiveAssetId();
    const is_video_control = this.isActiveVideoControl();
    if (is_video_control) {
      // add video control parent asset first to reserve the slot
      active_asset_id = active_asset_id.replace(/custom_video_(play|mute)_/, '');
    }

    for (const slot of slots) {
      if (active_asset_id === slot.asset_id) {
        if (is_video_control) {
          // add video control parent to draw stack
          const drawable = this.layers.Assets.addAsset(new Drawable(slot));
          if (drawable) {
            drawables.push(drawable);
          }
        } else {
          // reserve the active asset position so it woul be empty
          this.layers.Assets.reserveAssetPosition(slot.getX(), slot.getY());
        }
        break;
      }
    }
  }

  /**
   * @param {Drawable[]} drawables
   */
  #createAssetDrawables(drawables) {
    const slots = this.#handler.slots.Assets.concat(this.#handler.additional.slots.Assets);

    this.#reserveMainAssetSlot(slots, drawables);

    for (const slot of slots) {
      if (slot.ignoreDraw(this.getActiveAssetId())) {
        continue;
      }

      const drawable = this.layers.Assets.addAsset(new Drawable(slot));
      if (drawable) {
        drawables.push(drawable);
      }
    }
  }

  /**
   * @param {Drawable[]} drawables
   */
  #createOverlayDrawables(drawables) {
    const handler = this.#handler;

    // draw regards active asset index being below or above
    let { overlay_indexes, active_overlay_index } = this.getOverlayIndexes();

    // when active is video control, check the draw layer index/order by the parent asset instead
    // making sure the parent asset is drawn under the active asset
    if (this.isActiveVideoControl()) {
      const main_asset_id = this.getActiveAssetId().replace(/custom_video_(play|mute)_/, '');
      active_overlay_index = overlay_indexes.indexOf(main_asset_id);
    }

    let default_additional_overlay_layer = this.layers.Overlay;

    // if active is part of hotspot, everything is actually drawn below overlay layer
    if (this.isActiveHotspot() || this.isActiveGesture()) {
      default_additional_overlay_layer = this.layers.Assets;
    }

    // in reverse order
    for (const slot of handler.additional.slots.Overlay.concat(handler.slots.Overlay.reverse())) {
      if (slot.ignoreDraw(this.getActiveAssetId())) {
        continue;
      }

      let layer = default_additional_overlay_layer;
      const slot_index = overlay_indexes.indexOf(slot.asset_id);

      if (active_overlay_index !== -1 && slot_index >= active_overlay_index) {
        // lower layers are always below
        layer = this.layers.Assets;
      }

      const drawable = layer.addAsset(new Drawable(slot), false);
      if (drawable) {
        drawables.push(drawable);
      }
    }
  }

  #createHotspotDrawables(drawables) {
    // dont draw any hotspot assets when hotspot is not active
    if (!this.isActiveHotspot()) {
      return;
    }

    // dont draw anything if pin is active
    if (this.isActiveHotspotPin()) {
      return;
    }

    this.#addHotspotPin(drawables);

    // draws uploaded assets as well main
    const handler = this.#handler;
    for (const slot of handler.additional.slots.Hotspot) {
      // only handle main asset here
      if (!AssetHelper.isCustomHotspotMain(slot.asset_id)) {
        continue;
      }

      if (this.getActiveAssetId() === slot.asset_id) {
        continue;
      }

      const drawable = this.layers.Assets.addAsset(new Drawable(slot), false);
      if (drawable) {
        drawables.push(drawable);
      }
    }

    // check close finally
    if (this.isActiveHotspotMain()) {
      this.#addHotspotClose(drawables);
    }
  }
}
