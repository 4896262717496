<template lang="pug">
div.options-block(v-if="is_visible")

  template(v-if="name === 'custom_close' || name === 'custom_close_lightbox'")
    OptionsBlockRow(:label="'Close icon'" :tooltip="close_icon_tooltip")
      CustomAsset(:asset_id="name" :creative="creative" :auto_crop_size="auto_crop_size" @change="customAssetChangeHandler")

  template(v-else-if="name === 'custom_gesture_lightbox'")
    OptionsBlockRow(label="Gesture" tooltip="You can enable or disable the lightbox gesture. The gesture helps to indicate that the ad can be interacted with.")
      Toggle(:value="creative.settings.custom_gesture_lightbox ?? false" @input="customSettingChangeHandler($event, 'custom_gesture_lightbox')")

    OptionsBlockRow(label="Gesture icon" tooltip="Scale and reposition the existing gesture icon or upload a custom gesture icon")
      CustomAsset(:asset_id="name" :creative="creative" @change="customAssetChangeHandler")

  template(v-else-if="name === 'replay'")
    OptionsBlockRow(:label="item.title")
      CustomAsset(:creative="creative" :asset_id="item.asset_id" :has_collection="false")

  template(v-else)
    OptionsBlockRow(:label="item.title" :tooltip="item.tooltip")
      Toggle(:value="creative.settings[name] ?? item.default ?? false" @input="customSettingChangeHandler($event, name)")
</template>

<script>
import { CUSTOM_ASSET } from '@master/constants';

import Thumbnail from '@master/UI/Thumbnail/Thumbnail.vue';
import Toggle from '@master/UI/Toggle.vue';

import CustomAsset from './CustomAsset.vue';
import OptionsBlockRow from './OptionsBlockRow.vue';

export default {
  name: 'CustomOptionsBlock',

  components: {
    CustomAsset,
    OptionsBlockRow,
    Thumbnail,
    Toggle,
  },

  props: {
    creative: Object,
    name: String,
    item: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    close_icon_tooltip() {
      if (this.name === CUSTOM_ASSET.LIGHTBOX_CLOSE) {
        return `You can upload a custom close icon. Bear in mind, that the close icon should be in 1:1 (square) dimensions,
          otherwise our system will crop the image to square itself. The minimum recommended size is 60x60 px`;
      }
      return 'Upload or drag a custom close button icon from Asset library';
    },

    is_visible() {
      return this.item != null && (this.admin_toggle || this.item.visibility === 'all' || (this.item.visibility === 'feature' && this.$user.hasGroupFeature(this.name)));
    },

    auto_crop_size() {
      if (this.name === CUSTOM_ASSET.LIGHTBOX_CLOSE) {
        return 64;
      }
      return null;
    },
  },

  data() {
    return {
      admin_toggle: false,
    };
  },

  created() {
    this.$user.subscribe(() => {
      this.admin_toggle = this.$user.adminMode();
    }, this);
  },

  methods: {
    customAssetChangeHandler(reload_assets_library = false) {
      this.$emit('change', reload_assets_library);
    },

    customSettingChangeHandler(value, key) {
      this.$set(this.creative.settings, key, value);

      this.$http.put(`creative/${this.creative.creative_id}/settings/${key}`, { value }).then(() => {
        this.$emit('change');
        this.$forceUpdate();
      });
    },
  },
};
</script>
