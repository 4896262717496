import ContentService from '@master/Services/ContentService';
import UserService from '@master/Services/UserService';
import FolderService from '@master/Services/FolderService';
import { FOLDER } from '@master/constants';

class CampaignService extends ContentService {
  load() {
    super.load(this.#getPath());
  }

  loadMore() {
    super.loadMore(this.#getPath());
  }

  updateSorting(sort, reverse) {
    super.updateSorting(sort, this.#getPath(), reverse);
  }

  createCampaign(folder_id = null, name = null, set_active = true, add_item = true) {
    const user = UserService.get();
    const campaign_id = `new-${Date.now()}`;

    const obj = {
      campaign_id,
      path: '/',
      item_id: campaign_id,
      item_type: 'campaign',
      name: name !== null ? name : 'New Campaign',
      folder_id: folder_id,
      keywords: null,
      collaborators: [],
      status: 0,
      owner: {
        user_id: user.user_id,
        name: user.name,
      },
      creative_info: {
        totals: {
          total: 0,
          drafts: 0,
        },
      },
    };

    if (add_item) this.addItem(obj);
    if (set_active) this.active.set(obj);

    return obj;
  }

  createFolder(folder_parent_id = null, name = null, set_active = true, add_item = true) {
    const user = UserService.get();
    const folder_id = `new-${Date.now()}`;

    const obj = {
      folder_id,
      path: '/',
      item_id: folder_id,
      item_type: 'folder',
      name: name !== null ? name : 'New Folder',
      parent_id: folder_parent_id,
      status: 0,
      collaborators: [],
      type: FOLDER.CAMPAIGN,
      owner: {
        user_id: user.user_id,
        name: user.name,
      },
      creative_info: {
        totals: {
          total: 0,
          drafts: 0,
        },
      },
    };

    if (add_item) this.addItem(obj);
    if (set_active) FolderService.active.set(obj);

    return obj;
  }

  #getPath() {
    const active_user_id = this.getId('active_item_id');

    if (active_user_id != null) {
      return `v2/user/${active_user_id}/campaigns/view`;
    }

    const folder_id = this.getId('folder_id');

    if (folder_id != null) {
      return `v2/folder/${folder_id}/campaigns/view`;
    }

    return 'v2/campaigns/view';
  }

  columns = [
    null,
    { name: 'Name', key: 'name' },
    null,
    { name: 'Last edit', key: 'updated_on' },
    { name: 'Creatives', key: 'meta_counts.creatives' },
    { name: 'Impressions', slug: 'Imp.', key: 'meta_analytics.impressions' },
    { name: 'Engagement', slug: 'Eng.', key: 'meta_analytics.engagement' },
    { name: 'CTR', key: 'meta_analytics.ctr' },
    null,
  ];
}

export default new CampaignService();
