<template lang="pug">
div(ref="modal")
  div.modal.copy-move
    div.backdrop
    div.modal-content.py-32
      div.px-32.fs-22
        span.fw-500(v-if="!duplicate_creative")
          span.color-primary.text-capitalize {{ copy ? 'copy' : 'move' }}&nbsp;
          | selection to:
        span.fw-500(v-else)
          span.text-capitalize Choose destination for {{$route.params.creative_id}}
        section.pt-16.pb-16.flex.flex-align-center
          label.flex.flex-align-center(v-if="moving_creatives && !duplicate_creative" v-tooltip="{value: checkbox_tooltip}")
            input(type="checkbox" v-model="copy" :disabled="selection.has_folders_selection || (has_live_items && !in_current_campaign)")
            span.color-gray-800 Copy selected items and keep them in original location
          Buttons.flex-grow
            SearchSelectCustom(
              variant="new_campaign_folder"
              :search="false"
              :handler="newOption"
              @select="openCreateOption"
            )
              template(v-slot:placeholder)
                Button(
                  type="primary"
                  label="Create new"
                  :animate="true"
                  :disabled="in_user_list"
                  v-tooltip="in_user_list ? {value: 'You are currently in another campaign list. You can only create new folders under “My Campaigns”'} : null"
                  v-google-analytics="{ key: 'click', element_name: 'create new campaign / folder' }"
                )
                  template(v-slot:prefix)
                    IconAdd
      section.px-32.py-8.bg-header.flex.flex-align-center.flex-justify-between.column-gap-16
        span.overflow-ellipsis.fw-500(:class="{'cursor-pointer': !cannot_go_back && navigator?.history?.length > 1}" @click="goBack")
          i(:class="back_button_icon" aria-hidden="true" v-tooltip="cannot_go_back ? {value: 'Live creatives cannot be moved out of the campaign.'} : null")
          | {{ navigator?.current?.type === BREADCRUMB.ROOT ? 'My Campaigns' : navigator?.current?.label }}
        div.flex-grow
        SearchInput(v-model="query" @input="searchItems")

      section.py-8.px-32.bg-gray-100.flex.flex-direction-column.row-gap-8(style="height:50vh;overflow-y:auto;")
        Loading(v-if="loading_items")
        center.mt-32.fs-14.color-gray-800(v-else-if="!items?.length") No items to display <br />{{ copy && navigator?.current.type === BREADCRUMB.FOLDER ? 'Create campaign to copy here' : '' }}
        template(v-else v-for="(item, index) of items")
          div.copymove-list-item(
            :value="item.item_id"
            :key="index"
            :class="{active: item.item_id === selected_item_id}"
            @click="select(item)"
            @dblclick="goToDir(item)"
          )
            div.list-item-column.list-item-name.pl-8
              FolderThumbnail(v-if="item.item_type === LISTITEM.FOLDER")
              CampaignThumbnail(v-else)
              div.pr-8.list-item-name-container
                span(:class="{'clamp': !item.location}") {{ item.name }}
                span.list-item-location(v-if="item.location") &nbsp; in {{ item.location }}
            div.list-item-column
              span.color-gray-800.fs-12(v-if="!duplicate_creative")
                | {{ item?.meta?.campaigns?.total ?? item?.meta?.creatives?.total }} {{ $route.meta.view }}
              span.color-gray-800.fs-12(v-else)
                | {{ item?.meta?.creatives?.total }} creatives
            div.list-item-column.fs-12
              span.color-gray-800 Last edit: {{ item.updated_on | DateFilter }}
            div.list-item-column
              span.copymove-action-btn
                Button(
                  type="primary"
                  :label="move_button_label"
                  :animate="true"
                  :disabled="!canMoveTo(item)"
                  :tooltip="!canMoveTo(item) ? {value: tooltip_value(item)} : null"
                  @click="move(item)"
                )
            div.list-item-column
              i.nexd-icon-32-arrow-right.pr-8.hover-primary(@click="goToDir(item)")

        template(v-if="can_load_more_items")
          Loading.mt-16(v-if="loading_more_items" style="height:max-content;")
          Buttons(v-else align="left")
            Button(type="link" label="Load more items" @click="loadMoreItems")

      section.px-32.mt-32.flex.flex-align-center
        Breadcrumbs(:items="component_breadcrumbs")
        Buttons.flex-grow
          Button(type="link-primary" label="Close" @click="close")
          Button(
            type="primary"
            :label="move_button_label"
            :animate="true"
            :disabled="!canMoveTo(selected_item)"
            :tooltip="!canMoveTo(selected_item) ? {value: tooltip_value(selected_item)} : null"
            @click="move(selected_item)"
          )

      div(v-if="processing_request")
        Loading(
          :fill="true"
          :text="copy ? 'Copying Item(s)' : 'Moving Item(s)'"
          :subtext="`Please wait while we ${ copy ? 'copy' : 'move' } the selected item(s) and files to the destination. This may take some time`"
        )
    CreateNewModal(v-if="open_create" :title="open_create_title" @close="open_create = !open_create" @save="saveOption")
</template>

<script>
import { BREADCRUMB, LISTITEM, VIEW, FOLDER } from '@master/constants';
import { getFoldersDepth } from '@helpers/Global';

import CampaignService from '@master/Services/CampaignService';
import CopyMoveService from '@master/Services/CopyMoveService';
import CreativesService from '@master/Services/CreativesService';

import Navigator from '@libs/Navigator';
import Selection from '@libs/Selection';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Loading from '@master/UI/Loading.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';
import Modal from '@master/UI/Modal.vue';
import CreateNewModal from '@cm/UI/Global/Modals/CreateNewModal.vue';
import SearchInput from '@master/UI/Input/SearchInput.vue';

import IconAdd from '@master/UI/Buttons/Icons/IconAdd.vue';
import IconFolder from '@master/UI/Buttons/Icons/IconFolder.vue';
import FolderThumbnail from '@master/UI/Thumbnail/FolderThumbnail.vue';
import CampaignThumbnail from '@master/UI/Thumbnail/CampaignThumbnail.vue';
import Breadcrumbs from '@master/UI/Breadcrumbs/Breadcrumbs.vue';
import SearchSelectCustom from '@master/UI/SearchSelect/SearchSelectCustom.vue';

export default {
  name: 'CopyMoveModal',
  mixins: [Modal],

  components: {
    Button,
    Buttons,
    FolderThumbnail,
    CampaignThumbnail,
    IconAdd,
    IconFolder,
    Loading,
    LoadingModal,
    SearchInput,
    Breadcrumbs,
    SearchSelectCustom,
    CreateNewModal,
  },

  props: {
    selection: {
      type: Selection,
      required: true,
    },

    breadcrumbs: {
      type: Array,
      required: true,
      default: () => null,
    },

    has_live_items: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    back_button_icon() {
      const history = this.navigator?.history;

      return {
        'nexd-icon-32-campaign': history.length === 1,
        'nexd-icon-32-arrow-right rotate hover-primary fs-32': history.length > 1,
        disabled: this.cannot_go_back,
      };
    },

    cannot_go_back() {
      return this.has_live_items && !this.copy && this.navigator?.current?.item_id === this.$route.params.campaign_id;
    },

    checkbox_tooltip() {
      if (this.selection.has_folders_selection) {
        return 'You can copy if only creatives are selected from list.';
      }

      if (this.has_live_items && !this.in_current_campaign) {
        return 'Live creatives cannot be moved out of the the campaign.';
      }

      return null;
    },

    duplicate_creative() {
      return this.$route?.name === VIEW.DUPLICATE;
    },

    move_button_label() {
      return `${this.copy ? 'Copy' : 'Move'} Here`;
    },

    moving_creatives() {
      return this.duplicate_creative || this.$route.params.campaign_id != null;
    },

    campaign_id() {
      for (const nav of this.navigator?.history ?? []) {
        if (nav?.type === BREADCRUMB.CAMPAIGN) {
          return nav.item_id;
        }
      }

      return null;
    },

    in_campaign() {
      return this.campaign_id != null;
    },

    in_current_campaign() {
      return this.campaign_id === this.$route.params.campaign_id;
    },

    path() {
      if (this.navigator?.current?.type === BREADCRUMB.FOLDER) {
        if (this.in_campaign) {
          return `v2/folder/${this.navigator?.current?.item_id}/creatives/view`;
        }

        return `v2/folder/${this.navigator?.current?.item_id}/campaigns/view`;
      }

      if (this.navigator?.current?.type === BREADCRUMB.CAMPAIGN) {
        return `v2/campaigns/${this.navigator?.current?.item_id}/creatives/view`;
      }

      return 'v2/campaigns/view';
    },

    selected_item() {
      return this.items.find(i => i.item_id === this.selected_item_id);
    },

    folders_depth() {
      return getFoldersDepth(this.navigator?.history, this.in_campaign ? FOLDER.CREATIVE : FOLDER.CAMPAIGN);
    },

    in_user_list() {
      return this.$route.name === VIEW.CAMPAIGNS_USER;
    },

    component_breadcrumbs() {
      return this.navigator?.history.map(item => {
        const root = item.type === BREADCRUMB.ROOT;
        const is_folder = item.type === BREADCRUMB.FOLDER;
        const is_campaign_folder = item.meta?.folder_type === FOLDER.CAMPAIGN;
        const item_out_of_campaign = root || (is_folder && is_campaign_folder);
        return {
          ...item,
          disabled: this.has_live_items && !this.copy && item_out_of_campaign,
        };
      });
    },

    creative_campaign_id() {
      return this.$route?.params?.campaign_id;
    },
  },

  data() {
    return {
      BREADCRUMB,
      LISTITEM,

      navigator: null,
      service: null,

      items: [],
      loading_items: false,
      loading_more_items: false,
      can_load_more_items: false,

      query: '',
      copy: this.$route?.name === VIEW.DUPLICATE,
      selected_item_id: null,
      start_location: null,
      processing_request: false,
      disabled_campaign: false,
      open_create: false,
      open_create_title: '',
    };
  },

  created() {
    this.service = new CopyMoveService();
    this.service.init(this, this.$router);
    this.service.subscribe(data => {
      this.items = this.filterItems(data?.items);
      this.can_load_more_items = data?.can_load_more ?? false;
    }, this);

    this.createNavigator();
    this.start_location = this.navigator?.current;
    this.loadItems();
  },

  methods: {
    newOption() {
      const options = [
        {
          icon: 'nexd-icon-16-campaign',
          label: 'Campaign',
          disabled: this.disabled_campaign || this.in_campaign,
        },
        {
          icon: 'nexd-icon-16-folder',
          label: 'Folder',
          disabled: this.folders_depth >= 5,
        },
      ];

      return Promise.resolve(options);
    },

    openCreateOption(option, close) {
      const title = option.label === 'Campaign' ? 'campaign' : 'folder';

      this.open_create_title = `Create new ${title}`;
      this.open_create = !this.open_create;

      close();
    },

    saveOption(name) {
      this.open_create = false;

      if (this.open_create_title.includes('campaign')) {
        this.createCampaign(name);
      } else {
        this.createFolder(name);
      }
    },

    select(item) {
      this.selected_item_id = item.item_id;
    },

    createCampaign(campaign_name) {
      let add_item = false;

      if (this.$route?.params.campaign_id === this.navigator?.current.item_id) {
        add_item = true;
      }

      const new_campaign = CampaignService.createCampaign(this.navigator?.current.item_id ?? null, campaign_name, false, add_item);
      this.$http.post('v2/campaigns', new_campaign).then(campaign => {
        CampaignService.updateCreatedItem(new_campaign, campaign);
        this.service.addItem(campaign);
        this.selected_item_id = campaign.campaign_id;
      });
    },

    createFolder(folder_name) {
      let add_item = false;
      const service = this.in_campaign ? CreativesService : CampaignService;

      if (this.$route?.params.folder_id === this.navigator?.current.item_id) {
        add_item = true;
      }

      const new_folder = service.createFolder(this.navigator?.current.item_id ?? null, folder_name, false, add_item);
      this.$http.post('v2/folder', new_folder).then(folder => {
        service.updateCreatedItem(new_folder, folder);
        this.service.addItem(folder);
        this.selected_item_id = folder.folder_id;
      });
    },

    close() {
      this.$emit('close');

      if (this.duplicate_creative) {
        this.$router.push({ name: VIEW.CAMPAIGNS }).catch(_ => {
          /** suppress errors */
        });
      }
    },

    createNavigator() {
      const bcs = [];

      function loopBreadcrumb(breadcrumb, onClick) {
        if (breadcrumb.type === BREADCRUMB.BETWEEN) {
          for (const bc of breadcrumb.breadcrumbs) {
            loopBreadcrumb(bc, onClick);
          }
        } else {
          bcs.push({
            label: breadcrumb.label,
            item_id: breadcrumb.item_id,
            type: breadcrumb.type,
            meta: breadcrumb.meta,
            onClick: () => onClick(breadcrumb),
          });
        }
      }

      for (const bc of this.breadcrumbs) {
        loopBreadcrumb(bc, this.openBreadcrumb);
      }

      this.navigator = new Navigator([...bcs]);
    },

    goBack() {
      if (this.cannot_go_back) {
        return;
      }
      this.navigator.goBack();
      this.loadItems();
    },

    goToRoot() {
      if (this.cannot_go_back) {
        return;
      }
      this.navigator.goToRoot();
      this.loadItems();
    },

    goToDir(item) {
      const directory = {
        label: item.name,
        item_id: item.item_id,
        type: item.campaign_id === item.item_id ? BREADCRUMB.CAMPAIGN : BREADCRUMB.FOLDER,
      };

      directory.onClick = () => this.openBreadcrumb(directory);
      this.openBreadcrumb(directory);
    },

    openBreadcrumb(dir) {
      this.navigator.history.forEach(breadcrumb => {
        if (breadcrumb.item_id !== this.selected_item_id) {
          breadcrumb.active = false;
        }
      });

      this.navigator.goToDir(dir);
      this.loadItems();
    },

    async loadItems() {
      if (this.loading_items) {
        return;
      }

      this.selected_item_id = null;

      this.loading_items = true;
      await this.service.load(this.path);
      this.loading_items = false;
    },

    async loadMoreItems() {
      if (this.loading_more_items) {
        return;
      }

      this.loading_more_items = true;
      await this.service.loadMore(this.path);
      this.loading_more_items = false;
    },

    async searchItems() {
      if (this.loading_items) {
        return;
      }

      if (!this.query.length) {
        return this.loadItems();
      }

      this.selected_item_id = null;
      this.navigator.goToRoot();

      this.loading_items = true;
      await this.service.updateSearch(this.query, 'search');
      this.loading_items = false;
    },

    filterItems(items) {
      return (items ?? []).filter(item => {
        if (this.selection.selected.includes(item.item_id)) {
          if (item.item_type === LISTITEM.CAMPAIGN) this.disabled_campaign = true;
          return false;
        }

        // creatives can be moved to campaigns and folders
        if (this.moving_creatives) {
          this.disabled_campaign = false;
          return item.item_type !== LISTITEM.CREATIVE;
        }

        // folders and campaigns can be moved to other folders
        this.disabled_campaign = true;
        return item.item_type === LISTITEM.FOLDER;
      });
    },

    canMoveTo(item) {
      item = item ?? this.navigator?.current;

      // cannot move items to same location as they already are unless it's duplication mode
      if (this.start_location?.item_id === item.item_id && !this.duplicate_creative) {
        return false;
      }

      // cannot move creatives/creative-folders to campaign-folder or in current place
      if (this.moving_creatives) {
        if ((item?.campaign_id != null && item?.campaign_id !== this.$route.params.campaign_id) || item.item_id === this.creative_campaign_id) {
          return true;
        }

        return this.in_campaign;
      }

      // cannot move live items outside of campaign
      if (this.has_live_items && !this.copy && !this.in_campaign) {
        return false;
      }

      return true;
    },

    tooltip_value(item) {
      item = item ?? this.navigator?.current;

      if (this.start_location?.item_id === item.item_id && !this.duplicate_creative) {
        return 'Cannot move items to the same location.';
      }

      if (this.moving_creatives) {
        if (!this.in_campaign) {
          return 'Creatives cannot be moved out of the campaign.';
        }
      }
    },

    move(item = null) {
      if (this.processing_request) {
        return;
      }

      this.processing_request = true;

      // by default we are moving items to current view
      let path = this.pathFromNavigator();
      let key = this.navigator?.current?.type === BREADCRUMB.FOLDER ? 'folder_id' : 'campaign_id';
      let id = this.navigator?.current?.item_id;

      // if user selects item from list, then move to selected item
      if (this.selected_item != null) {
        path = this.selected_item?.path;
        key = this.selected_item?.item_type === LISTITEM.FOLDER ? 'folder_id' : 'campaign_id';
        id = this.selected_item?.item_id;
      } else if (this.selected_item == null && item != null) {
        path = item.path;
        key = item.item_type === LISTITEM.FOLDER ? 'folder_id' : 'campaign_id';
        id = item.item_id;
      }

      const payload = {
        folders: this.selection.selected_folders,
      };

      if (this.moving_creatives) {
        payload.creatives = this.selection.selected_items;
      } else {
        payload.campaigns = this.selection.selected_items;
      }

      let request;

      // if we are moving items to root, we can just remove items from folder
      if (id == null) {
        path = '/';
        request = this.$http.delete(`v2/folder/${this.$route.params.folder_id}/items`, payload);
      } else {
        payload[key] = id;
        request = this.$http.post(this.copy ? 'creatives/copy' : 'v2/move', payload);
      }

      request
        .then(success => {
          if (success) {
            this.askPermissionToNavigateToDestination(path);
          }
        })
        .finally(() => {
          this.processing_request = false;
        });
    },

    async askPermissionToNavigateToDestination(path) {
      this.close();
      this.updateServices();

      if (!path) {
        return;
      }

      const action = this.copy ? 'copied' : 'moved';

      const result = await this.$confirm(`Items ${action}`, `Selected item(s) have been successfully ${action} to the chosen destination.`, {
        buttons: [
          { type: 'link-primary', label: 'Close', action: false },
          { type: 'primary', label: 'To Destination', action: true },
        ],
      });

      if (result) {
        this.$router.push({ path }).catch(() => {
          /** catch error when navigating to the same page */
        });
      }
    },

    updateServices() {
      if (!this.copy) {
        if (this.moving_creatives) {
          CreativesService.removeItems(this.selection.selected);
        } else {
          CampaignService.removeItems(this.selection.selected);
        }
      }

      this.selection.unselectAll();
    },

    pathFromNavigator() {
      try {
        return new URL(this.navigator.current?.preview_url)?.pathname;
      } catch {
        const id = this.navigator.current?.item_id;

        switch (this.navigator?.current?.type) {
          case BREADCRUMB.FOLDER:
            const campaign = this.navigator.history?.find(item => item.type === BREADCRUMB.CAMPAIGN);

            if (campaign != null) {
              return `/c/${campaign.item_id}/f/${id}`;
            }

            return `/f/${id}`;
          case BREADCRUMB.CAMPAIGN:
            return `/c/${id}`;
          default:
            return '/';
        }
      }
    },
  },
};
</script>
