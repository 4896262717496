import { VIEW } from '@master/constants';

import Components from '@components/Components.vue';
import views from '@root/src/router/component_views';

const meta = {
  app_name: 'Components',
  app_key: 'components',
  requires_auth: true,
  show_navbar: true,
  validate_adblock: false,
  show_sidebar: true,
  intercom: false,
  view: VIEW.TEST,
  sdk_client: false,
};

export default [
  {
    path: '/components',
    name: VIEW.TEST,
    component: Components,
    meta,
    children: views.map(({ label, path, component }) => ({
      path: label,
      name: path,
      component,
      meta,
    })),
    redirect: { name: views[0].path },
  },
];
