<template lang="pug">
div#app-wrapper
  div.card(v-if="$route.name !== 'components-tests'")
    div.card-body
      <router-view></router-view>
</template>

<script>
import SidebarService from '@root/src/global/sidebar/global/Service';
import views from '@root/src/router/component_views';

export default {
  name: 'Components',

  data() {
    return {
      views,
    };
  },

  mounted() {
    SidebarService.setTests(views);
  },
};
</script>
