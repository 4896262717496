<template lang="pug">
Modal.creatives-bulk-action(title="Bulk Edit Creatives" :loading="loading" :class="{'modal-unset-overflow': !['tracking', 'cta'].includes(parameter)}")
  template(v-slot:body)
    div
      div.mb-16.fs-14.color-gray-800 Choose the parameter you wish to edit for the selected creatives.
      SearchSelect(
        v-model="parameter"
        :options="parameters"
        :search="false"
        width="200px"
      )
      template(v-if="parameter !== 'null'")
        hr

        //- EDIT NAME
        section.table.name-inputs(v-if="parameter === 'name'")
          SearchSelect(
            v-model="name_option"
            :options="NAME_OPTIONS"
            :search="false"
            width="200px"
          )
          template(v-if="name_option === 'add'")
            div.form-group.mb-0
              input.form-control(
                placeholder="Insert text"
                v-model="add_text"
              )
            SearchSelect(
              v-model="add_text_option"
              :options="ADD_TEXT_OPTIONS"
              :search="false"
            )
          div.table.full-width-row(v-else-if="name_option === 'replace'")
            section.table.full-width-row
              span.lh-32 Find text:
              div.form-group.mb-0
                input.form-control(
                  placeholder="Insert text you want to find"
                  v-model="find_text"
                )
            section.table.full-width-row
              span.lh-32 Replace with:
              div.form-group.mb-0
                input.form-control(
                  placeholder="Insert text you want to replace text with"
                  v-model="replace_text"
                )
          div.full-width-row(v-if="example_creative")
            span.color-gray-800 Example:&nbsp;
            | {{ example_text }}

        //- EDIT CTA
        section(v-if="parameter === 'cta'")
          div.table
            span.lh-24 Edit click actions separately&nbsp;
              i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{value: edit_cta_separately_tooltip}")
            Toggle(v-model="edit_cta_separately" @change="loadCreativesSettings")
          div.mt-16.mb-24.flex.flex-align-center.column-gap-16(v-if="edit_cta_separately" style="grid-column: span 2;")
            span.fs-14 Type:
            SearchSelect(v-model="global_cta_type" :options="CTA_OPTIONS" :search="false" :disabled="loading_settings" width="200px" @change="updateCreativeCtaTypes")
          div.table.mt-16.mb-8(v-else)
            span.fs-14.full-width-row Default click action&nbsp;
              i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{template: 'cm.ctaGlobal'}")
          template(v-if="edit_cta_separately")
            Loading(v-if="loading_settings")
            template(v-else)
              div.table.px-4
                span.color-gray-800 Creative:
                span.color-gray-800 Output:
              hr.my-4
              section.table.row-gap-0
                div.full-width-row.table.tracking.row-gap-8(v-for="(creative, creative_id) in creatives_trackers")
                  span.lh-32.overflow-ellipsis(v-tooltip="{value: creative.name}") {{ creative.name }}
                  CTAInputs(:value="creative.cta" @update="updateCreativeCta(creative_id, $event)")
          template(v-else)
            div.table.mt-12.mb-4
              span.color-gray-800 Type:
              span.color-gray-800 Output:
            div.table
              SearchSelect.input-prefix(v-model="cta.action" :options="CTA_OPTIONS" :search="false" @input="CTAactionChangeHandler(cta)")
              CTAInputs(:value="cta" @update="cta = $event")

        //- EDIT TRACKING
        section(v-if="parameter === 'tracking'")
          div.table
            span.lh-24 Edit trackers separately&nbsp;
              i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{value: `When enabled, you can modify each selected creatives' trackers individually. Turn off to apply the same changes to all trackers at once.`}")
            Toggle(v-model="edit_trackers_separately" @change="loadCreativesSettings")
          div.mt-16.mb-24.flex.flex-align-center.column-gap-16(v-if="edit_trackers_separately" style="grid-column: span 2;")
            span.fs-14 Event:
            SearchSelect(v-model="global_tracker" :options="global_tracking_options" :search="false" :disabled="loading_settings" width="200px" @change="updateCreativeTrackers")
          div.table.mt-16.mb-8(v-else)
            span Creative tracking&nbsp;
              i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{template: 'cm.trackingGlobal'}")
          template(v-if="edit_trackers_separately")
            Loading(v-if="loading_settings")
            template(v-else)
              div.table.px-4
                span.color-gray-800 Creative:
                span.color-gray-800 Tracker:
              hr.my-4
              section.table.row-gap-0
                div.full-width-row.table.tracking.row-gap-8(v-for="(creative, creative_id) in creatives_trackers")
                  span.lh-32.overflow-ellipsis(v-tooltip="{value: creative.name}") {{ creative.name }}
                  div.flex.flex-direction-column.row-gap-4
                    div.flex.flex-align-center.column-gap-16(
                      v-for="(tracker, index) of creative.cta.impressiontracker"
                      :key="index"
                    )
                      div.form-group.mb-0.flex-grow.with-remove-icon
                        TextareaInput(
                          placeholder="Insert tracking pixel URL (incl. macro)"
                          v-model="tracker.uri"
                          :disabled="is_global_video_tracking_option && !creative.has_video"
                          :url="true"
                          v-tooltip="{value: is_global_video_tracking_option && !creative.has_video ? 'Chosen event is not avaliable for this creative.' : null}"
                          @change="parseCreativeTrackersExcelAndChange(creative_id, index)"
                        )
                        RemoveIcon(v-if="!is_global_video_tracking_option || creative.has_video" @click="removeCreativeImpressionTracker(creative_id, index)")
                      i.nexd-icon-16-plus.add(v-if="!is_global_video_tracking_option || creative.has_video" aria-hidden="true" @click="addCreativeImpressionTracker(creative_id)")
                      div.ml-16(v-else)
          template(v-else)
            div.table.mb-4
              span.color-gray-800 Event:
              span.color-gray-800 Tracker:
            div.table.row-gap-4
              template(v-for="(tracker, index) of impressiontrackers")
                SearchSelect.input-prefix(
                  v-model="tracker.action"
                  :options="global_tracking_options"
                  :search="false"
                  width="100%"
                )
                div.flex.flex-align-center.column-gap-16
                  div.form-group.mb-0.flex-grow.with-remove-icon
                    TextareaInput(
                      placeholder="Insert tracking pixel URL (incl. macro)"
                      v-model="tracker.uri"
                      :url="true"
                      @change="parseTrackersExcelAndChange(index)"
                    )
                    RemoveIcon(@click="removeImpressionTracker(index)")
                  i.nexd-icon-16-plus.add(aria-hidden="true" @click="addImpressionTracker")

        //- EDIT DSP
        section.table.custom(v-if="parameter === 'dsp'")
          span.lh-32 Export as tag for:
          SearchSelect(v-model="dsp" :options="dsp_options")

        //- EDIT FW VERSION
        section.full-width-row.mb-8(v-if="parameter === 'framework_version'")
          span.fs-14 Update framework version

      template(v-if="parameter === 'tracking'")
        hr
        div.mb-16.fs-14.color-gray-800 Choose if you want to append or replace the impression trackers.
        div.fs-14 Choose action:
        label.mt-4.flex.flex-align-center.column-gap-4
          input(type="radio" v-model="append_trackers" :value="false" @change="append_trackers = false")
          span Replace already existing trackers
        label.mt-4.flex.flex-align-center.column-gap-4
          input(type="radio" v-model="append_trackers" :value="true" @change="append_trackers = true")
          span Add trackers to already existing trackers
        template(v-if="has_expandable_creatives")
          hr
          div.mb-16.fs-14.color-gray-800 Selection includes some expandable creative(s). Choose for which state the tracker will be added.
          div.fs-14 Choose state:
          label.mt-4.flex.flex-align-center.column-gap-4
            input(type="radio" v-model="state" :value="1" @change="state = 1")
            span First State
          label.mt-4.flex.flex-align-center.column-gap-4
            input(type="radio" v-model="state" :value="2" @change="state = 2")
            span Second State

  template(v-slot:footer)
    Buttons
      Button(type="link-primary" label="Cancel" :disabled="loading" @click="close")
      Button(type="primary" :label="action_button_label" :animate="true" :disabled="loading || parameter === 'null'" @click="save")
</template>

<script>
import { CTA_OPTIONS } from '@master/constants';
import { parseTrackerInput } from '@helpers/Global';

import { NAME_OPTIONS, ADD_TEXT_OPTIONS, TRACKING_OPTIONS, VIDEO_TRACKING_OPTIONS, PARAMETERS, DEFAULT_CTA_ACTION } from '@cm/Views/Creatives/Modals/BulkAction/options';
import { getUpdatedCTA, payloadForSeparate } from '@cm/Views/Creatives/Modals/BulkAction/helpers';

import DSPService from '@master/Services/Cache/DSPService';
import CreativesService from '@master/Services/CreativesService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';

import Buttons from '@master/UI/Buttons/Buttons.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Loading from '@master/UI/Loading.vue';
import Modal from '@master/Modals/Modal.vue';
import RemoveIcon from '@master/UI/RemoveIcon.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import Toggle from '@master/UI/Toggle.vue';

import CTAInputs from '@cm/Views/Creatives/Modals/BulkAction/CTAInputs.vue';
import TextareaInput from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TextareaInput.vue';

export default {
  name: 'CreativesBulkActionModal',

  mixins: [CreativeTraits],

  components: {
    Button,
    Buttons,
    CTAInputs,
    Loading,
    Modal,
    RemoveIcon,
    SearchSelect,
    TextareaInput,
    Toggle,
  },

  props: {
    creative_ids: {
      type: Array,
      default: () => null,
    },

    creatives: Array,
  },

  computed: {
    parameters() {
      if (!this.admin_toggle) {
        return PARAMETERS;
      }

      return {
        ...PARAMETERS,
        framework_version: 'Framework version',
      };
    },

    action_button_label() {
      if (this.parameter === 'framework_version') {
        return 'Update fw version';
      }
      return 'Apply';
    },

    global_tracking_options() {
      if (!this.edit_trackers_separately || !Object.values(this.creatives_trackers).some(cre => cre.has_video)) {
        return TRACKING_OPTIONS;
      }

      return {
        ...TRACKING_OPTIONS,
        ...VIDEO_TRACKING_OPTIONS,
      };
    },

    is_global_video_tracking_option() {
      return Object.keys(VIDEO_TRACKING_OPTIONS).includes(this.global_tracker);
    },

    example_creative() {
      return this.creatives?.[0] ?? null;
    },

    example_text() {
      const creative = this.example_creative;

      if (!creative) {
        return '';
      }

      const name = creative.name;

      // add text before or after
      if (this.name_option === 'add') {
        if (this.add_text_option === 'before') {
          return `${this.add_text}${name}`;
        }
        return `${name}${this.add_text}`;
      }

      // replace
      if (this.name_option === 'replace' && this.find_text !== '' && this.replace_text !== '') {
        return name.replace(this.find_text, this.replace_text);
      }

      // fallback
      return name;
    },

    has_expandable_creatives() {
      return this.creatives?.some(creative => this.isBinded(creative.type));
    },
  },

  data() {
    return {
      CTA_OPTIONS,
      NAME_OPTIONS,
      ADD_TEXT_OPTIONS,

      parameter: 'name',
      state: 1,
      append_trackers: false,
      edit_trackers_separately: false,
      edit_cta_separately: false,
      global_tracker: 'impression',
      global_cta_type: DEFAULT_CTA_ACTION,
      settings_loaded: false,
      loading_settings: false,

      name_option: 'add',
      add_text: '',
      add_text_option: 'before',
      find_text: '',
      replace_text: '',

      dsp: '',
      dsp_options: [],

      admin_toggle: true,

      cta: { action: DEFAULT_CTA_ACTION, uri: '' },
      impressiontrackers: [{ action: 'impression', uri: '' }],
      creatives_trackers: {},

      loading: false,

      edit_cta_separately_tooltip: "When enabled, you can modify each selected creatives' click actions individually. Turn off to apply the same changes to all click actions at once.",
    };
  },

  created() {
    DSPService.subscribe(dsps => {
      if (dsps?.length > 0) {
        for (const dsp of dsps) {
          this.dsp_options.push({ value: dsp.slug, label: dsp.title });
        }
      }
    }, this);

    this.$user.subscribe(user => {
      if (user != null) {
        this.admin_toggle = this.$user.adminMode();
      }
    }, this);
  },

  methods: {
    CTAactionChangeHandler(cta) {
      cta = cta ?? this.cta;
      const updated_cta = getUpdatedCTA(cta);

      for (const key in updated_cta) {
        this.$set(cta, key, updated_cta[key]);
      }
    },

    addImpressionTracker() {
      this.addTrackers(this.impressiontrackers);
    },

    removeImpressionTracker(index) {
      this.removeTrackers(this.impressiontrackers, index);
    },

    removeTrackers(trackers, index) {
      if (trackers.length > 1) {
        trackers.splice(index, 1);
      } else {
        this.$set(trackers[index], 'uri', '');
      }
    },

    addTrackers(trackers) {
      trackers.push({ action: 'impression', uri: '' });
    },

    parseTrackers(trackers, index) {
      const uris = parseTrackerInput(trackers?.[index]?.uri);

      if (uris.length === 0) {
        return;
      }

      if (uris.length === 1) {
        trackers[index].uri = uris[0];
        return;
      }

      // remove current value
      trackers.splice(index, 1);

      // push new items from parser
      for (const uri of uris) {
        trackers.push({
          action: 'impression',
          uri,
        });
      }
    },

    parseTrackersExcelAndChange(index) {
      this.parseTrackers(this.impressiontrackers, index);
    },

    generatePayload() {
      let data = {
        creative_ids: this.creative_ids,
      };

      if (this.parameter === 'name') {
        if (this.name_option === 'add') {
          data['name'] = {
            replace: false,
            text: this.add_text,
            before: this.add_text_option === 'before',
          };
        } else if (this.name_option === 'replace') {
          data['name'] = {
            replace: true,
            text: this.find_text,
            replace_text: this.replace_text,
          };
        }
      }

      if (this.parameter === 'cta') {
        data['settings'] = {
          cta: this.cta,
        };
      }

      if (this.parameter === 'tracking') {
        data['settings'] = {
          cta: {
            impressiontracker: this.impressiontrackers,
          },
        };

        data['options'] = {
          state: this.state,
        };

        if (this.append_trackers) {
          data['options']['append'] = this.append_trackers;
        }
      }

      if (this.parameter === 'dsp') {
        data['dsp'] = this.dsp;
      }

      if (this.parameter === 'framework_version') {
        data['framework_version'] = 'latest';
      }

      return data;
    },

    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (['cta', 'tracking'].includes(this.parameter) && (this.edit_cta_separately || this.edit_trackers_separately)) {
        const payload = payloadForSeparate(this.creatives_trackers, {
          parameter: this.parameter,
          append: this.append_trackers,
          is_video_option: this.is_global_video_tracking_option,
        });

        return this.$http
          .put(`campaigns/${this.$route.params.campaign_id}/creatives/bulk/${this.parameter}`, payload)
          .then(() => this.updateExportNotificationHandler())
          .finally(() => this.finishSave());
      }

      this.$http
        .put(`campaigns/${this.$route.params.campaign_id}/creatives/bulk`, this.generatePayload())
        .then(_ => {
          // name and dsp option requires to load new data
          if (['name', 'dsp'].includes(this.parameter)) {
            CreativesService.load();
          }

          this.updateExportNotificationHandler();
        })
        .finally(() => this.finishSave());
    },

    finishSave() {
      this.loading = false;
      this.close();
    },

    close() {
      this.$emit('close');
    },

    updateExportNotificationHandler() {
      if (['dsp', 'cta', 'tracking'].includes(this.parameter)) {
        for (const creative of this.creatives) {
          CreativesService.export.requestTagUpdate(creative);
        }
      }
    },

    updateCreativeTrackers() {
      for (const creative_id in this.creatives_trackers) {
        for (const tracker of this.creatives_trackers[creative_id].cta.impressiontracker) {
          this.$set(tracker, 'action', this.global_tracker);
        }
      }
    },

    addCreativeImpressionTracker(creative_id) {
      this.addTrackers(this.creatives_trackers[creative_id].cta.impressiontracker);
    },

    removeCreativeImpressionTracker(creative_id, index) {
      this.removeTrackers(this.creatives_trackers[creative_id].cta.impressiontracker, index);
    },

    parseCreativeTrackersExcelAndChange(creative_id, index) {
      this.parseTrackers(this.creatives_trackers[creative_id].cta.impressiontracker, index);
    },

    updateCreativeCtaTypes() {
      for (const creative_id in this.creatives_trackers) {
        const cta = this.creatives_trackers[creative_id].cta;
        this.$set(cta, 'action', this.global_cta_type);
        this.CTAactionChangeHandler(cta);
      }
    },

    updateCreativeCta(creative_id, cta) {
      for (const cre_id in this.creatives_trackers) {
        if (creative_id === cre_id) {
          this.$set(this.creatives_trackers[creative_id].cta, 'action', cta.action);
          this.$set(this.creatives_trackers[creative_id].cta, 'uri', cta.uri);
          break;
        }
      }
    },

    resetCtaAction() {
      this.cta.action = DEFAULT_CTA_ACTION;
      this.global_cta_type = DEFAULT_CTA_ACTION;
      this.updateCreativeCtaTypes();
    },

    loadCreativesSettings() {
      if (this.parameter === 'tracking' && !this.edit_trackers_separately) {
        return;
      }

      if (this.parameter === 'cta') {
        this.resetCtaAction();

        if (!this.edit_cta_separately) {
          return;
        }

        return this.createCreativesCTA();
      }

      if (this.settings_loaded || this.loading_settings) {
        return;
      }

      this.loading_settings = true;

      this.$http
        .post(`campaigns/${this.$route.params.campaign_id}/creatives/bulk/has_video`, {
          creative_ids: this.creatives?.map(cre => cre.creative_id),
        })
        .then(settings => {
          this.createCreativesCTA(settings);
          this.settings_loaded = true;
        })
        .finally(() => {
          this.loading_settings = false;
        });
    },

    createCreativesCTA(settings = null) {
      for (const creative of this.creatives) {
        this.$set(this.creatives_trackers, creative.creative_id, {
          name: creative.name,
          has_video: settings?.[creative.creative_id] ?? false,
          cta: {
            action: DEFAULT_CTA_ACTION,
            uri: '',
            impressiontracker: [
              {
                action: this.global_tracker,
                uri: '',
              },
            ],
          },
        });
      }
    },
  },
};
</script>
