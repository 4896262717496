import ContentService from '@master/Services/ContentService';
import CreativesExportService from '@master/Services/CreativesExportService';
import UserService from '@master/Services/UserService';
import FolderService from '@master/Services/FolderService';
import { FOLDER } from '@master/constants';

class CreativesService extends ContentService {
  export = new CreativesExportService();

  init(Vue, router) {
    this.export.init(Vue, router);
    super.init(Vue, router);
  }

  load(path = this.#getPath()) {
    if (path != null) {
      super.load(path);
    }
  }

  loadMore(path = this.#getPath()) {
    if (path != null) {
      super.loadMore(path);
    }
  }

  updateSorting(sort, reverse) {
    const path = this.#getPath();

    if (path != null) {
      super.updateSorting(sort, path, reverse);
    }
  }

  createFolder(parent_id = null, name = null, set_active = true, add_item = true) {
    const user = UserService.get();
    const folder_id = `new-${Date.now()}`;

    const obj = {
      folder_id,
      name: name !== null ? name : 'New Folder',
      parent_id: parent_id,
      status: 0,
      type: FOLDER.CREATIVE,
      owner: {
        user_id: user.user_id,
        name: user.name,
      },
    };

    if (add_item) this.addItem(obj);
    if (set_active) FolderService.active.set(obj);

    return obj;
  }

  #getPath() {
    const folder_id = this.getId('folder_id');
    const campaign_id = this.getId('campaign_id');

    if (folder_id != null) {
      return `v2/folder/${folder_id}/creatives/view`;
    }

    if (campaign_id != null) {
      return `v2/campaigns/${campaign_id}/creatives/view`;
    }

    return null;
  }

  columns = [
    null,
    null,
    { name: 'Name', key: 'name' },
    { name: 'Type' },
    { name: 'Layout', key: 'layout_name' },
    { name: 'Size', key: 'width,height,type' },
    { name: 'Platform', key: 'supported_channels.dsp_title' },
    { name: 'Impressions', slug: 'Imp.', key: 'meta_analytics.impressions' },
    { name: 'Engagement', slug: 'Eng.', key: 'meta_analytics.engagement' },
    { name: 'CTR', key: 'meta_analytics.ctr' },
    null,
  ];
}

export default new CreativesService();
