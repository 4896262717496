<template lang="pug">
div#app-wrapper
  div#profile-wrapper(v-content)
    Loading(v-if="group == null")
    div.flex.flex-direction-column.row-gap-32(v-else)

      ProfileCard
        template(v-slot:image)
          Illustration(img="org-profile.svg" :fill="true")

        section.flex.flex-direction-column.row-gap-16
          h2.fs-24.fw-500 {{ group.name }}

          div.flex.flex-align-center.flex-justify-between
            h4.fs-16 Organization information
            Button(type="primary" label="Edit" :animate="true" @click="editGroupModal = true")
              template(v-slot:prefix)
                IconEdit

          div.row.row-gap-16
            ProfileCardInfoColumn(label="Company" :value="group.name")
            ProfileCardInfoColumn(label="Billing email" :value="group.email" :filter="'email'")
            ProfileCardInfoColumn(label="Reg. number" :value="group.reg_nr")
            ProfileCardInfoColumn(label="VAT code (EU)" :value="group.vat_code")
            ProfileCardInfoColumn(label="Billing address" :value="group.business_address")
            ProfileCardInfoColumn(label="Postal code" :value="group.zip_code")
            ProfileCardInfoColumn(label="City" :value="group.city")
            ProfileCardInfoColumn(label="Country" :value="countries?.[group.country] ?? group.country")
            ProfileCardInfoColumn(label="Currency" :value="group.currency")
            ProfileCardInfoColumn(label="VAT" :value="group.apply_vat ? '22%' : '0%'")
            ProfileCardInfoColumn(
              label="Analytics timezone"
              :value="group.analytics_timezone"
              tooltip="Set your timezone for all analytics data. If not set, the default will be UTC. This timezone will be applied to all campaigns within your organization unless overridden at the campaign level."
            )
            ProfileCardInfoColumn(
              label="Date format"
              :value="group.date_format"
              tooltip="Set your organization date format."
            )

      ProfileCard
        template(v-slot:image)
          Illustration(img="org-members.svg")

        section.flex.flex-direction-column.row-gap-24
          h2.fs-24.fw-500 Organization Members

          div.flex.flex-align-center.flex-justify-between
            h4.fs-16 Manage the members and rights of your organization
            Button(type="primary" label="Add Members" :animate="true" v-google-analytics="{ key: 'click', element_name: 'add members' }" @click="addMembersModal = true")
              template(v-slot:prefix)
                IconAdd

          table.users-table
            thead
              tr
                th(scope="col" colspan="4") NAME
                th(scope="col" colspan="4") E-MAIL
                th(scope="col" colspan="4") ROLE

            template(v-for="list of users_lists")
              tbody(v-if="list.users != null && list.users.length > 0")
                tr(v-if="list.title")
                  td.pb-0.pt-16(scope="col" colspan="12")
                    p.color-gray-800.fs-16 {{ list.title }}

                MemberRow(
                  v-for="(user, index) in list.users"
                  :key="index"
                  :user="user"
                  :organization="group"
                  :editable="can_manage_group_details && user_hiearchy_index > ROLE_HIERARCHY.indexOf(user.role_id)"
                  :disabled="(user.role_id === USER_ROLE.ADMIN && group_has_only_one_admin) || list.disabled"
                )
                  div(v-if="can_manage_group_details")
                    i.cursor-pointer.fs-32(v-for="icon of list.icons" :class="[icon.class, {disabled: processing}]" aria-hidden="true" v-tooltip="{value: icon.tooltip}" @click="icon.action(user, index)")

  ChangeGroupDetailsModal(v-if="editGroupModal" :group="group" @close="editGroupModal = false" @save="loadGroup")
  InviteUserModal(v-if="addMembersModal" :group="group" @close="closeInviteModal")
</template>

<script>
import { ROLE_HIERARCHY, USER_ROLE, VIEW } from '@master/constants';
import { getCountries } from '@helpers/DataSet';
import PermissionMixin from '@master/Services/mixins/PermissionMixin.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import IconAdd from '@master/UI/Buttons/Icons/IconAdd.vue';
import IconEdit from '@master/UI/Buttons/Icons/IconEdit.vue';
import Loading from '@master/UI/Loading.vue';
import Illustration from '@master/UI/UserImage/Illustration.vue';

import ChangeGroupDetailsModal from '@cm/Views/Profile/Modals/ChangeGroupDetailsModal.vue';
import InviteUserModal from '@cm/Views/Profile/Modals/InviteUserModal.vue';
import MemberRow from '@cm/Views/Profile/Blocks/Components/MemberRow.vue';
import ProfileCard from '@cm/Views/Profile/Blocks/Components/ProfileCard/ProfileCard.vue';
import ProfileCardInfoColumn from '@cm/Views/Profile/Blocks/Components/ProfileCard/ProfileCardInfoColumn.vue';
import RequestCache from '@libs/RequestCache';

export default {
  name: 'Group',
  mixins: [PermissionMixin],

  components: {
    Button,
    Buttons,
    ChangeGroupDetailsModal,
    IconAdd,
    IconEdit,
    InviteUserModal,
    Loading,
    MemberRow,
    ProfileCard,
    ProfileCardInfoColumn,
    Illustration,
  },

  computed: {
    group_has_only_one_admin() {
      // basically group admins should not be able to create a situation of having a group without admins
      // also if that situation has happened (maybe we disabled users or anything like that)
      // then user with manage group permission should still be able to assign new admin
      return this.group?.users?.filter(user => user.role_id === USER_ROLE.ADMIN)?.length === 1;
    },

    users_lists() {
      return [
        {
          title: null,
          users: this.group?.users,
          icons: [
            {
              class: 'nexd-icon-32-delete color-danger',
              action: this.deactivateUser,
              tooltip: 'Deactivate user',
            },
          ],
        },
        {
          title: 'Pending invitations',
          users: this.group?.pending,
          icons: [
            {
              class: 'nexd-icon-32-resend-email hover-primary',
              action: this.resendInvite,
              tooltip: 'Resend invitation',
            },
            {
              class: 'nexd-icon-32-delete color-danger',
              action: this.cancelInvite,
              tooltip: 'Cancel invitation',
            },
          ],
          disabled: true,
        },
        {
          title: 'Deactivated users',
          users: this.group?.deactivated,
          icons: [
            {
              class: 'nexd-icon-32-reload hover-primary',
              action: this.reactivateUser,
              tooltip: 'Reactivate user',
            },
          ],
        },
      ];
    },
  },

  data() {
    return {
      ROLE_HIERARCHY,
      USER_ROLE,

      is_enterprise_plan: false,
      user_hiearchy_index: null,

      group: null,
      countries: null,

      addMembersModal: false,
      editGroupModal: false,
      processing: false,
    };
  },

  async created() {
    this.$user.subscribe(user => {
      this.is_enterprise_plan = this.$user.getSubscription().isEnterprise();

      if (user != null) {
        if (this.$user.isNexdAdmin() || this.$user.isGroupAdmin()) {
          // nexd admin and group admins can change all users and their roles
          this.user_hiearchy_index = Number.MAX_SAFE_INTEGER;
        } else {
          // normal users can only change users below them in the hiearchy
          this.user_hiearchy_index = ROLE_HIERARCHY.indexOf(user?.group?.role?.role_id);
        }
        this.loadGroup();
      }
    }, this);

    this.countries = await getCountries();
  },

  methods: {
    loadGroup() {
      this.group = null;

      let group_id = this.$user.getGroupId();

      // allow nexd admins to open billing with another group details
      if (this.$route.params?.group_id && this.$user.isNexdAdmin()) {
        group_id = this.$route.params?.group_id;
      }

      RequestCache.get(`groups/${group_id}`)
        .then(group => {
          this.group = group;
        })
        .catch(_ => {
          // probably user has no permissions to come here, redirect to profile page
          this.$router.push({ name: VIEW.PROFILE });
        });
    },

    closeInviteModal(obj = null) {
      this.addMembersModal = false;

      if (obj != null) {
        this.group.pending.push(obj);
      }
    },

    async cancelInvite(user, index) {
      if (this.processing) {
        return;
      }

      if (await this.$confirm('Remove pending user?', 'Removing will disallow the user to accept the already sent invitation')) {
        this.processing = true;

        this.$http
          .delete(`groups/${this.group.group_id}/users/invite/${user.invite_id}`)
          .then(() => {
            this.group.pending.splice(index, 1);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },

    async resendInvite(user) {
      if (this.processing) {
        return;
      }

      if (await this.$confirm('Resend invitation?', 'The user will receive a new invitation email')) {
        this.processing = true;
        this.$http.post(`groups/${this.group.group_id}/users/invite/${user.invite_id}`).finally(() => {
          this.processing = false;
        });
      }
    },

    async deactivateUser(user, index) {
      if (this.processing) {
        return;
      }

      if (
        await this.$confirm(
          'Are you sure you want to deactivate this user?',
          'Once deactivated, the user will no longer be able to log in. However, their campaigns will remain accessible in their campaign dashboard and can be viewed or moved as needed.',
        )
      ) {
        this.$http
          .put(`users/${user.user_id}/deactivate`)
          .then(() => {
            this.group.users.splice(index, 1);
            this.group.deactivated.push(user);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },

    async reactivateUser(user, index) {
      if (this.processing) {
        return;
      }

      if (await this.$confirm('Are you sure you want to reactivate this user?', 'The user will be reactivated immediately. They will be able to log in and access their campaign list.')) {
        this.processing = true;

        this.$http
          .put(`users/${user.user_id}/reactivate`)
          .then(() => {
            this.group.deactivated.splice(index, 1);
            this.group.users.push(user);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },
  },
};
</script>
